import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  ArrowSmLeftIcon,
  XIcon,
  ChatIcon,
  AtSymbolIcon
} from "@heroicons/react/outline";
import { Field } from "formik";

const Help = ({ onClose = () => {}, props, id, page, idProposta }) => {
  const [idLeadEstado, setIdLead] = useState(null);
  const [leadEstado, setLeadEstado] = useState(null);

  const ref = React.createRef();
  const dispatch = useDispatch();
  const {
    isLoading,
    leadCalculoDadoProposta,
    calculadoraProposta,
    leadV2ForEdit
  } = useSelector(
    state => ({
      isLoading: state.leads.actionsLoading,
      leadCalculoDadoProposta: state.leadsV2.leadCalculoDadoProposta,
      calculadoraProposta: state.leadsV2.calculadoraProposta,
      leadV2ForEdit: state.leadsV2.leadV2ForEdit
    }),
    shallowEqual
  );
  useEffect(() => {}, [isLoading, dispatch]);
  var contador = 1;

  const [abrirModal, setAbrirModal] = useState(false);
  const [tamanho, setTamanho] = useState("md");
  const [labelSuperior, setLabelSuperior] = useState("Ajuda");

  const fecharModal = () => {
    setAbrirModal(false);
  };

  return (
    <>
      <>
        {isLoading}

        <>
          <div className="border-b pb-2">
            <h2 className="text-gray-600 text-2xl font-bold mb-1">Ajuda</h2>
            <p className="text-sm text-gray-500 font-normal whitespace-normal">
              Entre em contato conosco por whatsapp ou e-mail.
            </p>
            {/* <span className="text-gray-400 text-base font-normal">{title}</span> */}
            {/* <a href="#" onClick={onHide}>
                <XIcon className="w-6 h-6 mb-3 at-symbol text-gray-600"/>
              </a> */}
          </div>

          <div>
            <div className="flex items-center justify-center">
              <img
                src="/assets/images/chatting.svg"
                className="w-48 mb-3 mt-3"
                alt=""
              />
            </div>
            <div>
              {/* <ChatIcon className="w-5 h-5  text-gray-600" />
               */}
              <a
                href="https://wa.me/5511981083209?text=Central+de+ajuda+da+Acesso+Energia"
                target="_blank"
                className="transition ease-in-out delay-150 flex items-center space-x-2 hover:bg-gray-100 hover:-translate-y-1 hover:scale-110 duration-300 hover:cursor-pointer rounded-md p-2"
              >
                <img
                  src="/assets/images/whatsapp-icon.png"
                  className="w-5"
                  alt=""
                />
                <span className="text-base font-medium text-gray-600">
                  WhatsApp do suporte: <strong>(11) 98108-3209</strong>
                </span>
              </a>
            </div>
            <a
              href="mailto:suporte@acessoenergia.com.br"
              target="_blank"
              className="transition ease-in-out delay-150 flex items-center space-x-2 hover:bg-gray-100 hover:-translate-y-1 hover:scale-110 duration-300 hover:cursor-pointer rounded-md p-2"
            >
              {/* <AtSymbolIcon className="w-5 h-5 text-gray-600" /> */}
              <img src="/assets/images/gmail-icon.png" className="w-4" alt="" />
              <span className="text-base font-medium text-gray-600">
                E-mail: <strong>suporte@acessoenergia.com.br</strong>
              </span>
            </a>
            <div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={onClose}
                  className="text-gray-500 bg-white hover:bg-gray-100 mt-4 border border-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-100 font-bold rounded-md text-sm px-5 py-2.5 text-center inline-flex items-center "
                >
                  <ArrowSmLeftIcon className="h-4 w-4 mr-1" />
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </>

        {/* <div className="modal-body" >
                <div className="row" >
                  <div className="col-md-12" style={{marginTop: "-1.2em"}}>
                    <div className="d-flex align-itens-center">
                      <span style={{fontSize: "1.2em"}}><i className="mdi mdi-24px mdi-whatsapp"></i> WhatsApp do suporte:</span>
                      <label style={{fontSize: "1.2em"}}>
                      <strong>(11) 98108-3209</strong>
                      </label>
                    </div>
                    <div className="d-flex align-itens-center">
                      <span style={{fontSize: "1.2em"}}><i className="mdi mdi-24px mdi-email-outline"></i> E-mail: </span>
                      <label style={{fontSize: "1.2em"}}>
                        <strong>suporte@acessoenergia.com.br</strong>
                      </label>
                    </div>
                  </div>
                </div>
          </div> */}
      </>
    </>
  );
};

export default Help;
