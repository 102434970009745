import { createSlice } from "@reduxjs/toolkit";

const initialdashboardsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: { custo: { consumo: 0, diasMedidos: 0, energiaCompensada: null, totalGD: null, totalSGD: null, economia: null, economia: null, energiaGerada: 0, numeroUCs: 0, locacaoTerra: 0, locacaoEquipamento: 0, om: 0, faturamentoUCs: 0, demandaGeracao: 0 }, consumo: [] },
  entitiesMes: undefined,
  entitiesAnual: undefined,
  processamentoFatura: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialdashboardsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // finddashboards
    dashboardFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    FaturaMensalFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesMes = entities.resultado;
    },
    FaturaProcessamentoFaturaFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.processamentoFatura = entities[0];
    },
    FaturaAnualFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesAnual = entities.resultado;
    },
  }
});
