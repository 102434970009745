import React, { useEffect, useState, Fragment } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ResponsiveBar } from "@nivo/bar";
import * as actions from "../View/_redux/dashboardInicio/dashboardActions";
import { line } from "d3-shape";
import { MoedaHtml } from "../../../_layout/_partials/controls";

import { Formik } from "formik";
import {
  getSelectCliente,
  getSelectMeses,
  getSelectFiltroDashboard
} from "../../lib/selectAPI";
import {
  CloudIcon,
  DocumentTextIcon,
  ExclamationIcon
} from "@heroicons/react/solid";

const barColor = "#0095ff";
const lineColor = "rgba(200, 30, 15, 1)";
const areaColor = "#0095ff";

export function Dashboard() {
  const {
    currentState,
    entitiesMes,
    entitiesAnual,
    processamentoFatura
  } = useSelector(
    state => ({
      currentState: state.dashboard,
      entitiesMes: state.dashboard.entitiesMes,
      entitiesAnual: state.dashboard.entitiesAnual,
      processamentoFatura: state.dashboard.processamentoFatura
    }),
    shallowEqual
  );

  const Line = ({ bars, xScale, yScale }) => {
    const lineGenerator = line()
      .x((bar, indice) => {
        if (indice < bars.length / 2) {
          return xScale(bar.data.data.Consumo) + bar.width / 2;
        }
      })
      .y((bar, indice) => {
        if (indice < bars.length / 2) {
          return yScale(bar.data.data.Total);
        }
      });
    return (
      <Fragment>
        <path
          d={lineGenerator(bars)}
          fill="none"
          stroke={lineColor}
          style={{ pointerEvents: "none" }}
        />
        {bars.map(bar => (
          <circle
            key={bar.key}
            cx={xScale(bar.data.data.Consumo) + bar.width / 2}
            cy={yScale(bar.data.data.Total)}
            r={4}
            fill="white"
            stroke={lineColor}
            style={{ pointerEvents: "none" }}
          />
        ))}
      </Fragment>
    );
  };

  const Line1 = ({ bars, xScale, yScale }) => {
    const lineGenerator = line()
      .x(bar => xScale(bar.data.index) + bar.width / 2)
      .y(bar => yScale(bar.data.data.Consumo));

    return (
      <Fragment>
        <path
          d={lineGenerator(bars)}
          fill="none"
          stroke={lineColor}
          style={{ pointerEvents: "none" }}
        />
        {bars.map(bar => (
          <circle
            key={bar.key}
            cx={xScale(bar.data.index) + bar.width / 2}
            cy={yScale(bar.data.data.Consumo)}
            r={4}
            fill="white"
            stroke={lineColor}
            style={{ pointerEvents: "none" }}
          />
        ))}
      </Fragment>
    );
  };
  const [clienteCombo, setClienteCombo] = useState([]);
  const [mesFaturaCombo, setMesFaturaCombo] = useState([]);
  const [mesList, setMesList] = useState([]);
  const [mes, setMes] = useState("");
  useEffect(() => {
    getSelectCliente(false, false).then(function(retorno) {
      setClienteCombo(retorno);
    });
    setMesFaturaCombo(getSelectFiltroDashboard());
    setMesList(getSelectMeses(false, false));
  }, []);

  const applyFilter = values => {
    dispatch(actions.ObterGestaoFaturaMensal(values.Cliente, values.MesFatura));
    dispatch(actions.ObterGestaoFaturaAnual(values.Cliente));
    dispatch(
      actions.ObterProcessamentoFatura({
        IdCliente: parseInt(values.Cliente),
        PeriodoRelatorio: null
      })
    );
  };
  useEffect(() => {
    if (entitiesAnual != undefined) {
      var listaGrafico = [];
      entitiesAnual.forEach(element => {
        listaGrafico.push({
          Consumo: element.dataRelatorio,
          ForaPonta: element.consumo_ponta * 2,
          ForaPontaColor: "hsl(337, 70%, 50%)",
          Ponta: element.consumo_ponta,
          Total: element.consumo_ponta * 2 + element.consumo_ponta,
          PontaColor: "hsl(344, 70%, 50%)"
        });
      });
      setData(listaGrafico);
    } else if (entitiesAnual == null) {
      setData([]);
    }
  }, [entitiesAnual, dispatch]);

  useEffect(() => {
    if (entitiesMes != undefined) {
      // setMes("em " + mesList.find(x => x.id == entitiesMes.mes).valor + ' ' + moment().format('YYYY'))
      setMes("Ao mês");
    }
  }, [entitiesMes, dispatch]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.ObterGestaoFaturaMensal(19, "0M"));
    dispatch(actions.ObterGestaoFaturaAnual(19));
    dispatch(
      actions.ObterProcessamentoFatura({
        IdCliente: 19,
        PeriodoRelatorio: null
      })
    );
  }, [1, dispatch]);

  const lineColor = "rgba(200, 30, 15, 1)";

  const [data, setData] = useState([]);
  return (
    <Formik
      initialValues={{
        Cliente: "19",
        MesFatura: "0M"
      }}
      onSubmit={values => {
        applyFilter(values);
      }}
    >
      {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="mx-5 xl:mx-8">
          <div className="">
            <div className="flex justify-between items-center mt-5 mb-3">
              <h4 className="text-gray-600 text-2xl font-bold">
                Gestão de faturas
              </h4>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <div>
                <div className="grid grid-cols-1 gap-5 bg-white rounded-lg mt-3 px-10  py-5 shadow-md">
                  <div>
                    <label className="text-gray-500 text-sm font-semibold">
                      Clientes
                    </label>
                    <select
                      className="w-full h-11 pl-3 pr-6 mt-2 text-sm font-regular text-gray-600 border rounded-md border-gray-300 hover:bg-gray-50 focus:outline-none focus:border-mainBlue"
                      name="Cliente"
                      placeholder="Filtrar por Cliente"
                      onChange={e => {
                        setFieldValue("Cliente", e.target.value);
                        handleSubmit();
                      }}
                      onBlur={handleBlur}
                      value={values.Cliente}
                    >
                      {clienteCombo.map(item => (
                        <option value={item.idCliente}>
                          {item.nomeCliente}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="text-gray-500 text-sm font-semibold">
                      Mês de referência
                    </label>
                    <select
                      className="w-full h-11 pl-3 pr-6 mt-2 text-sm font-regular text-gray-600 border rounded-md border-gray-300 hover:bg-gray-50 focus:outline-none focus:border-mainBlue mb-2"
                      name="MesFatura"
                      placeholder="Filtrar por Mes Fatura"
                      onChange={e => {
                        setFieldValue("MesFatura", e.target.value);
                        handleSubmit();
                      }}
                      onBlur={handleBlur}
                      value={values.MesFatura}
                    >
                      {mesFaturaCombo.map(item => (
                        <option value={item.id}>{item.valor}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="bg-white rounded-lg mt-3 px-10 py-5 shadow-md">
                  <h6 className="text-gray-500 text-base font-semibold">
                    Extrato de faturas
                  </h6>
                  <h3 className="my-2 py-1 text-gray-500 text-2xl font-bold">
                    <MoedaHtml
                      value={entitiesMes && entitiesMes.valorTotalFatura12Meses}
                    />
                  </h3>
                  <span className="text-gray-500 text-sm font-regular">
                    <MoedaHtml
                      value={entitiesMes && entitiesMes.valorTotalFatura}
                    />{" "}
                    {mes}
                  </span>
                </div>
              </div>
              <div className="bg-white rounded-lg mt-3 px-10 py-5 shadow-md">
                <h4 className="text-gray-500 text-base font-semibold">
                  Oportunidades financeiras
                </h4>
                <span className="text-gray-500 text-sm font-regular">
                  Acesso ao dados de faturas
                </span>
                <div className="py-3">
                  <div className="flex flex-wrap justify-between items-center py-2">
                    <div className="flex items-center">
                      <CloudIcon className="w-7 h-7 mr-2 text-yellow-400" />
                      <span className="text-gray-600 text-md font-regular">
                        energia reativa
                      </span>
                    </div>
                    <div>
                      <MoedaHtml
                        className="text-gray-600 font-bold text-md"
                        value={
                          entitiesMes &&
                          entitiesMes.oportEnergReativaMedia12Meses
                        }
                      />
                    </div>
                  </div>
                  <div className="flex-grow border-t border-gray-200"></div>
                  <div className="flex flex-wrap justify-between items-center py-2">
                    <div className="flex items-center">
                      <ExclamationIcon className="w-7 h-7 mr-2 text-red-500" />
                      <span className="text-gray-600 text-md font-regular">
                        ultrapassagem de demanda
                      </span>
                    </div>
                    <div>
                      <MoedaHtml
                        className="text-gray-600 font-bold text-md"
                        value={
                          entitiesMes &&
                          entitiesMes.oportUltrapDemandaMedia12Meses
                        }
                      />
                    </div>
                  </div>
                  <div className="flex-grow border-t border-gray-200"></div>
                  <div className="flex flex-wrap justify-between items-center py-2">
                    <div className="flex items-center">
                      <DocumentTextIcon className="w-7 h-7 mr-2 text-green-600" />
                      <span className="text-gray-600 text-md font-regular">
                        pagamentos faturas
                      </span>
                    </div>
                    <div>
                      <MoedaHtml
                        className="text-gray-600 font-bold text-md"
                        value={
                          entitiesMes &&
                          entitiesMes.oportMultaJurosPagamentoMedia12Meses
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="bg-green-500 rounded-lg mt-3 px-10 py-5 shadow-md">
                  <h4 className="text-white text-base font-semibold">
                    Oportunidades financeiras
                  </h4>
                  <span className="text-white text-sm font-regular">
                    Valor total
                  </span>
                  <h3 className="my-2 py-1 text-white text-2xl font-bold">
                    <MoedaHtml
                      value={
                        entitiesMes && entitiesMes.oportunidadesProjetada1Ano
                      }
                    />
                  </h3>
                  <span className="text-nowrap text-white text-sm font-regular">
                    <MoedaHtml
                      value={
                        entitiesMes &&
                        entitiesMes.oportunidadesProjetada1Ano / 12
                      }
                    />{" "}
                    ao mês
                  </span>
                </div>
                <div className="bg-blue-600 rounded-lg mt-3 px-10 py-5 shadow-md">
                  <h4 className="text-white text-base font-semibold mb-4">
                    Processamento da Faturas
                  </h4>
                  <div className="flex justify-between items-center mb-1">
                    <div>
                      <span className="inline-block w-3 h-3 mr-2 bg-yellow-400 rounded-full"></span>
                      <span className="text-white text-sm font-regular">
                        Pendentes na fila
                      </span>
                    </div>
                    <div>
                      <span className="text-white text-sm font-regular">
                        {processamentoFatura == null
                          ? 0
                          : processamentoFatura.qtdePendentesFila}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mb-1">
                    <div>
                      <span className="inline-block w-3 h-3 mr-2 bg-green-600 rounded-full"></span>
                      <span className="text-white text-sm font-regular">
                        Processadas
                      </span>
                    </div>
                    <div>
                      <span className="text-white text-sm font-regular">
                        {processamentoFatura == null
                          ? 0
                          : processamentoFatura.qtdeProcessadasFila}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mb-1">
                    <div>
                      <span className="inline-block w-3 h-3 mr-2 bg-red-500 rounded-full"></span>
                      <span className="text-white text-sm font-regular">
                        Não PDF
                      </span>
                    </div>
                    <div>
                      <span className="text-white text-sm font-regular">
                        {processamentoFatura == null
                          ? 0
                          : processamentoFatura.qtdeNaoPDFFila}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <div>
                      <span className="inline-block w-3 h-3 mr-2 bg-gray-500 rounded-full"></span>
                      <span className="text-white text-sm font-regular">
                        Layout Não mapeada
                      </span>
                    </div>
                    <div>
                      <span className="text-white text-sm font-regular">
                        {processamentoFatura == null
                          ? 0
                          : processamentoFatura.qtdeLayoutNaoMapeadaFila}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-3 gap-3 mt-2">
              <div className="col-span-2 bg-white rounded-lg mt-3 mb-12 px-10 py-5 shadow-md">
                <h4 className="text-gray-500 text-base font-semibold">
                  Gestão de faturas
                </h4>
                <div dir="ltr">
                  {/* <div id="basic-column" className="apex-charts" data-colors="#727cf5,#0acf97,#fa5c7c"></div> */}
                  <div style={{ height: "300px" }}>
                    <ResponsiveBar
                      data={data}
                      keys={["Ponta", "ForaPonta"]}
                      indexBy="Consumo"
                      margin={{ top: 30, right: 80, bottom: 70, left: 70 }}
                      padding={0.85}
                      colors={["#FF0000", "#E2E3E7", "#000000"]}
                      // rangeColors={
                      //     ["#3393FF", "#000000", "#FD6800", "#FFFF33", "#6BFF33"]
                      //     // ["#3393FF", "#FFFF33", "#6BFF33", "#FF3333"]
                      // }
                      defs={[
                        {
                          id: "dots",
                          type: "patternDots",
                          background: "inherit",
                          color: "#38bcb2",
                          size: 4,
                          padding: 1,
                          stagger: true
                        },
                        {
                          id: "lines",
                          type: "patternLines",
                          background: "inherit",
                          color: "#eed312",
                          rotation: -45,
                          lineWidth: 6,
                          spacing: 10
                        }
                      ]}
                      fill={[
                        {
                          match: {
                            id: "fries"
                          },
                          id: "dots"
                        },
                        {
                          match: {
                            id: "sandwich"
                          },
                          id: "lines"
                        }
                      ]}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", "0"]]
                      }}
                      axisTop={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Consumo",
                        legendPosition: "middle",
                        legendOffset: 32
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Custo R$",
                        legendPosition: "middle",
                        legendOffset: -65
                      }}
                      axisRight={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Energia mWh",
                        legendPosition: "middle",
                        legendOffset: 69,
                        format: value =>
                          `${Number(value).toLocaleString("pt-BR", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })}`
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={14}
                      labelTextColor={{
                        from: "color",
                        modifiers: [["darker", "1.8"]]
                      }}
                      legends={[
                        {
                          dataFrom: "keys",
                          anchor: "bottom-right",
                          direction: "row",
                          justify: false,
                          translateX: 94,
                          translateY: 65,
                          itemsSpacing: 14,
                          itemWidth: 89,
                          itemHeight: 20,
                          itemDirection: "left-to-right",
                          itemOpacity: 0.85,
                          symbolSize: 9,
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemOpacity: 1
                              }
                            }
                          ]
                        }
                      ]}
                      layers={[
                        "grid",
                        "axes",
                        "bars",
                        Line,
                        "markers",
                        "legends"
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-lg mt-3 mb-12 px-10 py-5 shadow-md">
                <h4 className="text-gray-500 text-base font-semibold">
                  Penalidades
                </h4>
                <span className="text-gray-500 text-sm font-regular">
                  Consolidado de penalidades acumuladas
                </span>
                <div className="py-3">
                  <div className="flex flex-wrap justify-between items-center py-2">
                    <div>
                      {/* <div className="border-l-yellow-400 border-4"></div> */}
                      <i className="mdi mdi-cloud-check mdi-custom-2 mdi-yellow"></i>
                      <span className="text-gray-600 text-md font-regular">
                        Energia reativa
                      </span>
                    </div>
                    <div>
                      <MoedaHtml
                        className="text-gray-600 font-bold text-md"
                        value={
                          entitiesAnual &&
                          entitiesAnual[0].penalidades_ufer == null
                            ? "-"
                            : entitiesAnual && entitiesAnual[0].penalidades_ufer
                        }
                      />
                    </div>
                  </div>
                  <div className="flex-grow border-t border-gray-200"></div>
                  <div className="flex flex-wrap justify-between items-center py-2">
                    <div>
                      <div className="left-border-red"></div>
                      <i className="mdi mdi-folder-plus mdi-custom-2 mdi-red"></i>
                      <span className="text-gray-600 text-md font-regular">
                        Ultrapassagem demanda
                      </span>
                    </div>
                    <div>
                      <MoedaHtml
                        className="text-gray-600 font-bold text-md"
                        value={
                          entitiesAnual &&
                          entitiesAnual[0].penalidades_ultrapassagem_energia ==
                            null
                            ? "-"
                            : entitiesAnual &&
                              entitiesAnual[0].penalidades_ultrapassagem_energia
                        }
                      />
                    </div>
                  </div>
                  <div className="flex-grow border-t border-gray-200"></div>
                  <div className="flex flex-wrap justify-between items-center py-2">
                    <div>
                      <div className="left-border-green"></div>
                      <i className="mdi mdi-file-document mdi-custom-2 mdi-green"></i>
                      <span className="text-gray-600 text-md font-regular">
                        Multa por atraso
                      </span>
                    </div>
                    <div>
                      <MoedaHtml
                        className="text-gray-600 font-bold text-md"
                        value={
                          entitiesAnual &&
                          entitiesAnual[0].penalidades_ultrapassagem_juros ==
                            null
                            ? "-"
                            : entitiesAnual &&
                              entitiesAnual[0].penalidades_ultrapassagem_juros
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
