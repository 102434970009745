import React from "react";
import { useField, useFormikContext } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
        classes.push("is-invalid");
    }


    return classes.join(" ");
};

export function MoedaHtml({
    ...props
}) {

    return (
        <>
            <NumberFormat thousandSeparator={true} prefix={'R$ '}
                displayType={'text'}
                decimalSeparator=","
                decimalScale={2}
                thousandSeparator="."
                value={props.value}
                {...props}
            />
        </>
    );
}
export function MoedaHtmlBoletim({
    ...props
}) {

    return (
        <>
            <NumberFormat  
                prefix={'R$ '}
                displayType={'text'}
                decimalSeparator=","
                decimalScale={4}
                thousandSeparator="."
                value={props.value}
                {...props}
            />
        </>
    );
}
export function MoedaHtmlBoletimCasaDecimal({
    ...props
}) {

    return (
        <>
            <NumberFormat  
                prefix={'R$ '}
                displayType={'text'}
                decimalSeparator=","
                decimalScale={2}
                thousandSeparator="."
                value={props.value}
                {...props}
            />
        </>
    );
}
