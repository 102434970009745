import axios from "axios";
import {config} from "../../../../Config";
export const SPECIFICATIONS_URL = config.url.apiEndPoint+"/notificacao";


// CREATE =>  POST: add a new crms to the server

export function createCRM(crm) {
  crm.idDistribuidora = parseInt(crm.idDistribuidora);
  crm.idCliente = parseInt(crm.clienteId);
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/incluir`,
    headers: {
      'Content-Type': 'application/json'
    },
    data:crm,
  };

  return axios(request);

}
// READ
// Server should return filtered crms by productId
export function getAllProductCRMSByProductId(productId) {
  return axios.get(`${SPECIFICATIONS_URL}?productId=${productId}`);
}

export function getCRMById(crmId) {
  return axios.get(`${SPECIFICATIONS_URL}/${crmId}`);
}

// Server should return sorted/filtered crms and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/crmsfind/{productId}'. Should be 'api/crms/find/{productId}'!!!
export function findCRMS(queryParams, todas) {
  const getUsuario = JSON.parse(localStorage.getItem('@plataforma/user')).idUsuario;
  queryParams.filter.idUsuario = parseInt(getUsuario);
  queryParams.filter.tipoNotificacaoMenu = "4";
  queryParams.filter.todasNotificacaoApenasMinha = todas;
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/list`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: queryParams,
  };

  return axios(request);
}

// UPDATE => PUT: update the crm
export function updateCRM(id,crm) {
  crm.IdCRM =parseInt(id);
  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/alterar`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: crm,
  };
  return axios(request);
}

// DELETE => delete the crm
export function deleteCRM(crmId) {
  return axios.delete(`${SPECIFICATIONS_URL}/${crmId}`);
}

export function deleteCRMS(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'I' },
  };

  return axios(request);
}

export function ativaCRMS(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'A' },
  };

  return axios(request);
}


export function marcarComoLidaCRM(tipoNotificacaoMenu) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { tipoNotificacaoMenu: tipoNotificacaoMenu ,Situacao:'L', todas: ''  },
  };

  return axios(request);
}
