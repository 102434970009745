import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { MailIcon, LockClosedIcon, XCircleIcon } from "@heroicons/react/solid";
import { InputError } from "../styles/LoginStyles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Mail, Lock } from "react-feather";
import FooterSecondary from "../../components/FooterSecondary";

const initialValues = {
  email: "",
  password: ""
};
export default function Login() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [acessoInvalido, setAcessoInvalido] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("AUTH.VALIDATION.REQUIRED_FIELD"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("AUTH.VALIDATION.REQUIRED_FIELD")
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const reLoginFocus = () => {
    setAcessoInvalido(false);
  };
  const asyncLocalStorage = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();

      login(values.email, values.password)
        .then(({ data: { entities } }) => {
          asyncLocalStorage.setItem("menuPlataforma",
            JSON.stringify(entities.menuAcesso)).then(function()
          {
            asyncLocalStorage.setItem( "@plataforma/user",
              JSON.stringify({
                email: entities.email,
                fullname: entities.fullname,
                idCliente: entities.idCliente,
                idUsuario: entities.idUsuario
              })).then(function(){
                disableLoading();
                dispatch(auth.actions.login(entities.accessToken));
            });
          });

          //props.login(entities.accessToken);
        })
        .catch(() => {
          disableLoading();
          setSubmitting(false);
          setAcessoInvalido(true);
          setStatus("AUTH.VALIDATION.INVALID_LOGIN");
        });
    }
  });

  return (
    <>
      {/* <main className="flex justify-center items-center my-52"> */}
      <div className="h-screen">
        <main className="flex pt-8 justify-center items-center">
          <div className="lg:w-4/12 w-auto px-16 py-11 shadow rounded-xl bg-white">
            <div className="">
              <img
                // src="/assets/images/logo-energetica-black.svg"
                src="/assets/logo/logo-acesso-dark.svg"
                className="mb-3 w-56"
              />
              <h5 className="mt-0 text-left text-lg  text-gray-400">
                Bem-vindo
              </h5>
              <h2 className="mt-0 text-left text-2xl font-extrabold text-gray-900">
                Realize o Login
              </h2>
            </div>

            {acessoInvalido && (
              <>
                <span className="inline-flex w-full rounded-full justify-center items-center py-0.5 pl-2.5 pr-1 text-xs font-normal bg-red-100 text-red-500">
                  E-mail ou senha incorreta , tente novamente.
                  <button
                    type="button"
                    className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-500 hover:bg-red-200 hover:text-red-500 "
                  >
                    <span className="sr-only">Remove large option</span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              </>
            )}
            <form>
              <div>
                <div className="mt-5 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail
                      className={
                        " text-24 " +
                        (acessoInvalido ? "text-red-400" : "text-gray-400")
                      }
                    />
                  </div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className={
                      "bg-white border  " +
                      (acessoInvalido
                        ? "border-2 border-red-400 "
                        : "border-gray-300 ") +
                      " text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                    }
                    placeholder="E-mail"
                    onFocus={() => reLoginFocus()}
                    {...formik.getFieldProps("email")}
                  />
                  {/* {formik.touched.email && formik.errors.email ? (
                  <InputError>{formik.errors.email}</InputError>
                ) : null} */}
                </div>
              </div>
              <div className="mt-4 mb-3.5 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock
                    className={
                      " text-24 " +
                      (acessoInvalido ? "text-red-400" : "text-gray-400")
                    }
                  />
                </div>
                <input
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  required
                  onFocus={() => reLoginFocus()}
                  className={
                    "bg-white border  " +
                    (acessoInvalido
                      ? "border-2 border-red-400 "
                      : "border-gray-300 ") +
                    " text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                  }
                  placeholder="Senha"
                  {...formik.getFieldProps("password")}
                />
                {/* {formik.touched.password && formik.errors.password ? (
                  <InputError>{formik.errors.password}</InputError>
                ) : null} */}
              </div>
              <div className="flex items-center mb-6">
                <div className="text-sm">
                  <a
                    href="/auth/forgot-password"
                    className="font-extrabold text-mainBlue hover:text-blue-500"
                  >
                    Esqueceu a senha?
                  </a>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  disabled={formik.isSubmitting}
                  onClick={formik.handleSubmit}
                  className="w-full flex justify-center py-2 px-2 rounded-md shadow-sm text-sm font-medium text-white bg-mainBlue hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="text-base">Acessar</span>
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
              <div className="flex justify-center items-center mt-6">
                <div className="mt-0 text-left text-sm  text-gray-400">
                  Problemas com a conta? &nbsp;
                  <a
                    href="/problemas-conta"
                    className="font-medium text-mainBlue hover:text-blue-500"
                  >
                    clique aqui
                  </a>
                </div>
              </div>
            </form>
          </div>
        </main>
        <FooterSecondary relativePosition=" absolute" />
      </div>
    </>
  );
}
