import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }


  return classes.join(" ");
};

export function TextArea({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      {label && <label  className="text-gray-500 text-sm font-semibold">{label}</label>}
      <textarea
        // className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        className={"w-full pl-3 pr-6  text-gray-600 border rounded-md border-gray-200 hover:bg-gray-50 focus:outline-none focus:border-gray-300 "}
        rows={"3"}
        {...field}
        {...props}
      />
      {withFeedbackLabel && errors[field.name] && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}

export function TextAreaAnotacao({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <textarea
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        rows={"3"}
        style={{marginLeft: '12px', marginRight: '34px'}}
        {...field}
        {...props}
      />
      {withFeedbackLabel && errors[field.name] && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
