/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";

// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "./_layout/layout";
import "./input.css";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
//const data_layout  = localStorage.getItem('data-layout');
//const data_layout_config  = localStorage.getItem('data-layout-config');

_redux.middlewareAxios(axios, store);

ReactDOM.render(
  <MetronicLayoutProvider>
    <MetronicSplashScreenProvider>
      <App store={store} persistor={persistor} basename={PUBLIC_URL} />
    </MetronicSplashScreenProvider>
  </MetronicLayoutProvider>,
  document.getElementById("root")
);
