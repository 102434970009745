import React, { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";
import { isFragment } from "react-is";

export const CardHeaderIcon = forwardRef(({ className }, ref) => (
  <span ref={ref} className={clsx("card-head-icon", className)} />
));

export const CardHeaderTitle = forwardRef(({ className, ...props }, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <div className="page-title-box">
    <div className="page-title-right">
      <ol className="breadcrumb m-0">
        <li className="breadcrumb-item"><a href="#">Inicio</a></li>
        <li className="breadcrumb-item active">Products</li>
      </ol>
    </div>
    <h4 {...props} ref={ref} className={clsx("page-title", className)} />
  </div>
));

export const Toolbar = forwardRef(
  ({ children, className, ...props }, ref) => (
    <div className="d-flex justify-content-end">
      <div {...props} ref={ref} style={{ "margin-top": "10px", "margin-bottom": "10px" }} className={clsx("card-toolbar", className)}>
        {children}
      </div>
    </div>
  )
);

export const CardHeader = forwardRef(
  (
    {
      children,
      icon,
      title,
      toolbar,
      className,
      sticky = false,
      labelRef,
      ...props
    },
    ref
  ) => {
    const [top, setTop] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);

    useEffect(() => {
      handleResize();

      function handleResize() {
        setWindowHeight(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    useEffect(() => {
      // Skip if sticky is disabled or on initial render when we don't know about window height.
      if (!sticky || windowHeight === 0) {
        return;
      }

      const headerElement = document.querySelector(".header");
      const subheaderElement = document.querySelector(".subheader");
      const headerMobileElement = document.querySelector(".header-mobile");

      let nextMarginTop = 0;

      // mobile header
      if (window.getComputedStyle(headerElement).height === "0px") {
        nextMarginTop = headerMobileElement.offsetHeight;
      } else {
        // desktop header
        if (document.body.classList.contains("header-minimize-topbar")) {
          // hardcoded minimized header height
          nextMarginTop = 60;
        } else {
          // normal fixed header
          if (document.body.classList.contains("header-fixed")) {
            nextMarginTop += headerElement.offsetHeight;
          }

          if (document.body.classList.contains("subheader-fixed")) {
            nextMarginTop += subheaderElement.offsetHeight;
          }
        }
      }

      setTop(nextMarginTop);
    }, [sticky, windowHeight]);

    return (

      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">{title}</h4>
          </div>
        </div>
      </div>
    );
  }
);

export const CardBody = forwardRef(
  ({ fit, fluid, className, ...props }, ref) => (
    <div className="row">
      <div className={"col-" + props.size ? 12 : props.size}>
        <div className="card">
          <div
            {...props}
            ref={ref}
            className={clsx(
              "card-body",
              {
                "card-body-fit": fit,
                "card-body-fluid": fluid
              },
              className
            )}
          />

        </div>
      </div>
    </div>


  )
);

export const CardFooter = forwardRef(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={clsx("card-footer", className)} />
));

export const Card = forwardRef(({ fluidHeight, className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={clsx(
      "container",
      className
    )}
  />
));

// Set display names for debugging.
if (process.env.NODE_ENV !== "production") {
  Card.displayName = "Card";

  CardHeader.displayName = "CardHeader";
  CardHeaderIcon.displayName = "CardHeaderIcon";
  CardHeaderTitle.displayName = "CardHeaderTitle";
  Toolbar.displayName = "Toolbar";

  CardBody.displayName = "CardBody";
  CardFooter.displayName = "CardFooter";
}
