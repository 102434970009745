import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialParceirosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  parceiroForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const parceirosSlice = createSlice({
  name: "parceiros",
  initialState: initialParceirosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getParceiroById
    parceiroFetched: (state, action) => {
      state.listLoading = false;
      // APLICAR A MASCARA
      state.parceiroForEdit = action.payload.parceiroForEdit;
      state.error = null;
    },
    // findParceiros
    parceirosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createParceiro
    parceiroCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      // state.entities.push(action.payload.parceiro);
    },
    // updateParceiro
    parceiroUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.parceiro.idParceiro) {
          return action.payload.parceiro;
        }
        return entity;
      });
    },
    // deleteParceiro
    parceiroDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteParceiros
    parceirosDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // parceirosUpdateState
    parceirosStatusUpdated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
