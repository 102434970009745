//Importação React
import React, { useState } from "react";
import Help from "./Help";

export default function FooterSecondary(props) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div
        className={
          "bg-gray-200 h-14 w-full bottom-0 left-0  " + props.relativePosition
        }
      >
        <div className="flex justify-center items-center h-full">
          <div className="mr-9">
            <a
              href="#"
              className="flex-1 text-center text-sm text-gray-600 hover:text-blue-600 cursor-pointer"
            >
              Fale conosco
            </a>
          </div>
          <div className="mr-9">
            <a
              href="#"
              className="flex-1 text-center text-sm text-gray-600 hover:text-blue-600 cursor-pointer"
            >
              Privacidade
            </a>
          </div>
          <div className="mr-9">
            <a
              href="#"
              className="flex-1 text-center text-sm text-gray-600 hover:text-blue-600 cursor-pointer"
            >
              Termos de uso
            </a>
          </div>
          <div className="mr-9">
            <a
              onClick={e => setShow(true)}
              className="flex-1 text-center text-sm text-gray-600 hover:text-blue-600 cursor-pointer"
            >
              Ajuda
            </a>
            {show && (
              <div
                className={!show ? "hidden" : "" + " relative z-10"}
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed z-10 inset-0 overflow-y-auto">
                  <div className="flex  items-end sm:items-center justify-center min-h-full text-center ">
                    <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all px-7 py-5 w-auto">
                      <Help
                        onClose={() => setShow(false)}
                        page={"calculadoraProposta"}
                        idProposta={null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
