import {createSlice} from "@reduxjs/toolkit";

const initialUsuariosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  usuarioForEdit: undefined,
  lastError: null,
  usuarioNomeAlterar: undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const usuariosSlice = createSlice({
  name: "usuarios",
  initialState: initialUsuariosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getUsuarioById
    usuarioFetched: (state, action) => {
      state.listLoading = false;
      state.usuarioForEdit = action.payload.usuarioForEdit;
      state.error = null;
    },
    // findUsuarios
    usuariosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createUsuario
    usuarioCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      console.log(action);
      state.entities.push(action.payload.usuario);
    },
    // updateUsuario
    usuarioUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.idUsuario === action.payload.usuario.idUsuario) {
          return action.payload.usuario;
        }
        return entity;
      });
    },
    usuarioDadosPessoalUpdated: (state, action) => {
      console.log(action, "STATE")
      state.error = null;
      state.listLoading = false;
      console.log(action.payload.usuarioNome.nomeUsuario, "SOU A INFORMAÇÃO")
      state.usuarioNomeAlterar = action.payload.usuarioNome.nomeUsuario
      // state.entities = state.entities.map(entity => {
      //   if (entity.idUsuario === action.payload.usuario.idUsuario) {
      //     return action.payload.usuario;
      //   }
      //   return entity;
      // });
    },
    // deleteUsuario
    usuarioDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteUsuarios
    usuariosDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // usuariosUpdateState
    usuariosStatusUpdated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
