import React from "react";
import NumberFormat from "react-number-format";

export function EnergiaFormatacao(cellContent, row, rowIndex) {
  return (
    <>
      <NumberFormat
        suffix={" kWh"}
        displayType={"text"}
        decimalSeparator=","
        thousandSeparator="."
        value={cellContent}
      />
    </>
  );
}


export function EnergiaFormatacaoBoletim(
  cellContent,
  row,
  rowIndex
) {

  return (
      <>
          <NumberFormat   
                     // thousandSeparator={true} 
                      suffix={' kWh'}
                        displayType={'text'}
                        decimalScale={5}
                        decimalSeparator=","
                        thousandSeparator="."
                        value={cellContent}
          />
      </>
  );
}
