//Importação React
import React, {Fragment, useState, useMemo, useEffect} from "react";
import { Dialog, Menu, Transition, Disclosure } from "@headlessui/react";

import {
  CurrencyDollarIcon,
  ShoppingCartIcon,
  ViewGridIcon,
  UsersIcon,
  LightningBoltIcon,
  CogIcon,
  HomeIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from "@heroicons/react/outline";
import { useHtmlClassService } from "../../../_layout/layout/_core/MetronicLayout";
import { NavLink, Link } from "react-router-dom";
import objectPath from "object-path";

import { toAbsoluteUrl } from "../../../_layout/_helpers";
import "../../../assets/css/ScrollBar.css";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideBar() {
  // const uiService = useHtmlClassService();
  // const menu = JSON.parse(localStorage.getItem("menuPlataforma"));
  // let layoutLogo = localStorage.getItem("@plataforma/logo");
  // layoutLogo = "energetica";
  // const layoutProps = useMemo(() => {
  //   return {
  //     headerClasses: uiService.getClasses("header", true),
  //     headerAttributes: uiService.getAttributes("header"),
  //     headerContainerClasses: uiService.getClasses("header_container", true),
  //     menuHeaderDisplay: objectPath.get(
  //       uiService.config,
  //       "header.menu.self.display"
  //     ),
  //   };
  // }, [uiService]);
  const [menuLateral, setMenuLateral] = useState();
  const [menuJson, setMenuJson] = useState();

  useEffect(() => {
    const menu = JSON.parse(localStorage.getItem("menuPlataforma"));
    setMenuJson(menu);
  },[]);
  const determinarIcone = icone => {
    switch (icone) {
      case "Gestão de Faturas":
        return <CurrencyDollarIcon className="w-4 h-4 mr-1" />;
      case "Mercado Livre":
        return <ShoppingCartIcon className="w-4 h-4 mr-1" />;
      case "Administrativo":
        return <ViewGridIcon className="w-4 h-4 mr-1" />;
      case "CRM":
        return <UsersIcon className="w-4 h-4 mr-1" />;
      case "GD":
        return <LightningBoltIcon className="w-4 h-4 mr-1" />;
    }
  };
  return (
    <>
      <div>
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          <div className="flex-1 flex flex-col min-h-0 bg-[#3F414E]">
            <div className="flex items-center pt-7 pb-3 flex-shrink-0 px-4 bg-[#3F414E]">
              <Link to="/dashboard">
                <img
                  class="h-11 w-auto"
                  // src="/assets/images/logo-energetica.svg"
                  src="/assets/logo/logo-acesso.svg"

                  alt="Logo Energética"
                />
              </Link>
            </div>
            <div className="flex-1 flex flex-col overflow-y-auto">
              <nav className="flex-1 px-2 py-4 space-y-1">
                <div className="space-y-1">
                  <span className="text-gray-400 pl-2 px-2 py-2 text-xs">
                    MENU
                  </span>
                </div>
                {/* <a
                  href="/dashboard"
                  className="text-gray-400 hover:bg-gray-800 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer focus:bg-gray-800 focus:text-white"
                >
                  <HomeIcon className="w-4 h-4 mr-1" />
                  <span className="flex-1"> Dashboard </span>
                </a> */}
                {menuJson && menuJson.map(item => (
                  <div className="space-y-1">
                    <a
                      onClick={() =>
                        setMenuLateral(
                          menuLateral === item.menuAcesso ? "" : item.menuAcesso
                        )
                      }
                      className={classNames(
                        menuLateral === item.menuAcesso
                          ? "bg-gray-800 text-white"
                          : "text-gray-400 hover:bg-gray-800 hover:text-white",
                        "group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer"
                      )}
                    >
                      {determinarIcone(item.menuAcesso)}
                      <span className="flex-1"> {item.menuAcesso} </span>
                      {menuLateral === item.menuAcesso ? (
                        <ChevronUpIcon className="w-4 h-4" />
                      ) : (
                        <ChevronDownIcon className="w-4 h-4" />
                      )}
                    </a>

                    <div
                      style={
                        menuLateral === item.menuAcesso
                          ? {}
                          : { display: "none" }
                      }
                      className="space-y-1"
                      id={"menu-" + item.menuAcesso}
                    >
                      {JSON.parse(item.itemMenuAcesso).map(itemMenu => (
                        <NavLink
                          className={classNames(
                            "text-gray-400 hover:bg-gray-800 hover:text-white",
                            "pl-11 pr-2 py-1.5  group flex items-center px-1.5 text-sm font-normal rounded-md"
                          )}
                          to={itemMenu.url}
                        >
                          {itemMenu.menu}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                ))}
                {/* <a
                  href="/page/config-plataforma"
                  className="text-gray-400 hover:bg-gray-800 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer focus:bg-gray-800 focus:text-white"
                >
                  <CogIcon className="w-4 h-4 mr-1" />
                  <span className="flex-1"> Configurações </span>
                </a> */}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
