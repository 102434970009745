/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_layout/_helpers";
import { ContentRoute } from "../../../../_layout/layout"
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";



export function AuthPage() {
  return (
    <Switch>
      <ContentRoute path="/auth/login" component={Login} />
      <ContentRoute
        path="/auth/forgot-password"
        component={ForgotPassword}
      />

      <Redirect from="/auth" exact={true} to="/auth/login" />
      <Redirect to="/auth/login" />
    </Switch>
  );
}
