import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useHtmlClassService } from "../../../_layout/layout/_core/MetronicLayout";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import * as actions from "../View/_redux/notificacoes/notificacoesAction";
import { Formik } from "formik";
import moment from "moment";
import { getSelectCliente, getSelectPeriodo, getSelectTelas } from "../../lib/selectAPI";
import { Input, Select } from "../../../_layout/_partials/controls";
// import { GestaoFaturas } from "../Notificacao/GestaoFaturas";



import * as actionsGestao from "../View/_redux/gestaoFaturas/gestaoFaturasActions";
import * as actionsMercadoLivre from "../View/_redux/mercadoLivres/mercadoLivresActions";
import * as actionsAdministrativos from "../View/_redux/administrativos/administrativosActions";
import * as actionsCrms from "../View/_redux/crms/crmsActions";

const layoutLogo = localStorage.getItem('@plataforma/logo');

export default function Header() {
  const uiService = useHtmlClassService();

  let history = useHistory();
  const handleClick = () => {
    history.push('/page/config-plataforma');
    setDropdownsLogin(false);
  }

  const handleClickDadosPessoal = () => {
    history.push('/page/alterar-dados');
    setDropdownsLogin(false);
  }
  const [dropdownsLogin, setDropdownsLogin] = useState(false);
  const [dropdownsNotificacao, setDropdownsNotificacao] = useState(false);
  const { currentState, usuarioNomeAlterar, entitiesGestaoFatura, entitiesMercadoLivre, entitiesCRMS, entitiesAdministrativo } = useSelector(
    (state) => ({
      currentState: state.usuarios,
      usuarioNomeAlterar: state.usuarios.usuarioNomeAlterar,
      entitiesGestaoFatura: state.gestaoFaturas.entities,
      entitiesMercadoLivre: state.mercadoLivres.entities,
      entitiesCRMS: state.crms.entities,
      entitiesAdministrativo: state.administrativos.entities,
    }),
    shallowEqual
  );
  const [usuario, setUsuario] = useState(JSON.parse(localStorage.getItem('@plataforma/user')) == null ? "" : JSON.parse(localStorage.getItem('@plataforma/user')).fullname);

  useEffect(() => {
    if (usuarioNomeAlterar != undefined) {
      setUsuario(usuarioNomeAlterar)
    }
  }, [usuarioNomeAlterar, dispatch]);

  const dispatch = useDispatch();

  const abrirNotificacao = e => {
    var notificacaoAtributo = document.getElementsByClassName(
      "nav-link dropdown-toggle arrow-none show"
    );

    // console.log(notificacaoAtributo.length, "como saber se esta aberto ou não");
    if (notificacaoAtributo.length === 1) {
      const queryParams = {
        filter: {
          notificacao: null,
          idTipoNotificacao: null
        },
        sortOrder: "asc",
        sortField: "VINCode",
        pageNumber: 1,
        pageSize: 30
      };

      // dispatch(actions.fetchNotificacoes(queryParams));
    } else {
      // console.log("FECHEI LOGO NÃO CHAMEI");
    }
  };

  const { notificacoes } = useSelector(
    state => ({ notificacoes: state.notificacoes.notificacao }),
    shallowEqual
  );

  var notificacaoDireta = [];
  var notificacaoSeguindo = [];
  var notificacaoTeste = [];
  var tipoNotificacao = [];

  if (notificacoes != undefined) {
    for (let i = 0; i < notificacoes.length; i++) {
      tipoNotificacao.push(notificacoes[i].TipoNotificacao);
    }

    var unique = [...new Set(tipoNotificacao)];
    // console.log(tipoNotificacao, "AQUI DENTRO TIPO")

    for (let i = 0; i < notificacoes.length; i++) {
      if (notificacoes[i].idTipoNotificacao == 1) {
        notificacaoDireta.push(notificacoes[i]);
      } else if (notificacoes[i].idTipoNotificacao == 2) {
        notificacaoSeguindo.push(notificacoes[i]);
      } else if (notificacoes[i].idTipoNotificacao == 3) {
        notificacaoTeste.push(notificacoes[i]);
      }
    }
  }



  const [tab, setTab] = useState("diretas")
  const [tabNotificacao, setTabNotificacao] = useState("gestaoFaturas")

  const [dropdownsNaoLido, setDropdownsNaoLido] = useState(true);
  const [dropdownsLido, setDropdownsLido] = useState(true);

  const [dropdownsLidoQtde, setDropdownsLidoQtde] = useState(true);



  const [dropdownsFiltro, setDropdownsFiltro] = useState(false);

  const [contadorGestaoLido, setContadorGestaoLido] = useState(0);
  const [contadorGestaoNaoLido, setContadorGestaoNaoLido] = useState(0);
  const [mercadoLivreLido, setMercadoLivreLido] = useState(0);
  const [mercadoLivreNaoLido, setMercadoLivreNaoLido] = useState(0);
  const [administrativoLido, setAdministrativoLido] = useState(0);
  const [administrativoNaoLido, setAdministrativoNaoLido] = useState(0);
  const [crmLido, setCRMLido] = useState(0);
  const [crmNaoLido, setCRMNaoLido] = useState(0);
  const [dropdownsNaoLidoQtde, setDropdownsNaoLidoQtde] = useState(0);


  const [contadorGestaoConcluido, setContadorGestaoConcluido] = useState(0);
  const [contadorGestaoErro, setContadorGestaoErro] = useState(0);

  const [mercadoLivreConcluido, setMercadoLivreConcluido] = useState(0);
  const [mercadoLivreErro, setMercadoLivreErro] = useState(0);


  const [administrativoConcluido, setAdministrativoConcluido] = useState(0);
  const [administrativoErro, setAdministrativoErro] = useState(0);

  const [crmConcluido, setCRMConcluido] = useState(0);
  const [crmErro, setCRMErro] = useState(0);

  const [contadorGestaoSoma, setContadorGestaoSoma] = useState(0);
  const [mercadoLivreSoma, setMercadoLivreSoma] = useState(0);
  const [administrativoSoma, setaAministrativoSoma] = useState(0);
  const [crmSoma, setCRMSoma] = useState(0);




  var contadorNaoLidoGestao = 0;
  var contadorLidoGestao = 0;
  var contadorConcluidoGestao = 0;
  var contadorErroGestao = 0;
  var contadorNaoLidoMercadoLivre = 0;
  var contadorLidoMercadoLivre = 0;
  var contadorConcluidoMercadoLivre = 0;
  var contadorErroMercadoLivre = 0;
  var contadorNaoLidoCRM = 0;
  var contadorLidoCRM = 0;
  var contadorConcluidoCRM = 0;
  var contadorErroCRM = 0;
  var contadorNaoLidoAdministrativo = 0;
  var contadorLidoAdministrativo = 0;
  var contadorConcluidoAdministrativo = 0;
  var contadorErroAdministrativo = 0;



  var contadorTodasAdministrativo = 0;
  var contadorTodasCRM = 0;
  var contadorTodasMercadoLivre = 0;
  var contadorTodasGestao = 0;


  useEffect(() => {
    if (entitiesGestaoFatura != null) {
      entitiesGestaoFatura.map((item) => {
        if (item.situacaoLeitura == 'N') {
          contadorNaoLidoGestao++;
        }
        if (item.situacaoLeitura == 'L') {
          contadorLidoGestao++;
        }
        if (item.situacao == 'C') {
          contadorConcluidoGestao++;
        }
        if (item.situacao == 'E') {
          contadorErroGestao++;
        }
      })
      setContadorGestaoNaoLido(contadorNaoLidoGestao);
      setContadorGestaoLido(contadorLidoGestao);
      setContadorGestaoConcluido(contadorConcluidoGestao);
      setContadorGestaoErro(contadorErroGestao);
      setContadorGestaoSoma(contadorConcluidoGestao + contadorErroGestao);
    } else {
      setContadorGestaoNaoLido(0)
      setContadorGestaoLido(0)
      setContadorGestaoConcluido(0);
      setContadorGestaoErro(0);
      setContadorGestaoSoma(0);
    }
  }, [entitiesGestaoFatura])


  useEffect(() => {
    if (entitiesMercadoLivre != null) {
      entitiesMercadoLivre.map((item) => {
        if (item.situacaoLeitura == 'N') {
          contadorNaoLidoMercadoLivre++;
        }
        if (item.situacaoLeitura == 'L') {
          contadorLidoMercadoLivre++;
        }
        if (item.situacao == 'C') {
          contadorConcluidoMercadoLivre++;
        }
        if (item.situacao == 'E') {
          contadorErroMercadoLivre++;
        }
      })
      setMercadoLivreNaoLido(contadorNaoLidoMercadoLivre);
      setMercadoLivreLido(contadorLidoMercadoLivre);
      setMercadoLivreConcluido(contadorConcluidoMercadoLivre);
      setMercadoLivreErro(contadorErroMercadoLivre);
      setMercadoLivreSoma(contadorConcluidoMercadoLivre + contadorErroMercadoLivre);
    } else {
      setMercadoLivreNaoLido(0);
      setMercadoLivreLido(0);
      setMercadoLivreConcluido(0);
      setMercadoLivreErro(0);
      setMercadoLivreSoma(0);
    }
  }, [entitiesMercadoLivre])



  useEffect(() => {
    if (entitiesCRMS != null) {
      entitiesCRMS.map((item) => {
        if (item.situacaoLeitura == 'N') {
          contadorNaoLidoCRM++;
        }
        if (item.situacaoLeitura == 'L') {
          contadorLidoCRM++;
        }
        if (item.situacao == 'C') {
          contadorConcluidoCRM++;
        }
        if (item.situacao == 'E') {
          contadorErroCRM++;
        }
      })
      setCRMNaoLido(contadorNaoLidoCRM);
      setCRMLido(contadorLidoCRM);
      setCRMConcluido(contadorConcluidoCRM);
      setCRMErro(contadorErroCRM);
      setCRMSoma(contadorConcluidoCRM + contadorErroCRM);
    } else {
      setCRMNaoLido(0);
      setCRMLido(0);
      setCRMConcluido(0);
      setCRMErro(0);
      setCRMSoma(0);
    }
  }, [entitiesCRMS])



  useEffect(() => {
    if (entitiesAdministrativo != null) {
      entitiesAdministrativo.map((item) => {
        if (item.situacaoLeitura == 'N') {
          contadorNaoLidoAdministrativo++;
        }
        if (item.situacaoLeitura == 'L') {
          contadorLidoAdministrativo++;
        }
        if (item.situacao == 'C') {
          contadorConcluidoAdministrativo++;
        }
        if (item.situacao == 'E') {
          contadorErroAdministrativo++;
        }
      })
      setAdministrativoNaoLido(contadorNaoLidoAdministrativo);
      setAdministrativoLido(contadorLidoAdministrativo);
      setAdministrativoConcluido(contadorConcluidoAdministrativo);
      setAdministrativoErro(contadorErroAdministrativo);
    } else {
      setAdministrativoNaoLido(0);
      setAdministrativoLido(0);
      setAdministrativoConcluido(0);
      setAdministrativoErro(0);
    }
  }, [entitiesAdministrativo])

  useEffect(() => {
    var total = contadorGestaoNaoLido + mercadoLivreNaoLido + administrativoNaoLido + crmNaoLido
    setDropdownsNaoLidoQtde(total)
  }, [dropdownsNaoLidoQtde, contadorGestaoNaoLido, mercadoLivreNaoLido, administrativoNaoLido, crmNaoLido])

  const handleMarcar = (e, tela) => {
    dispatch(actionsGestao.marcarComoLidaGestaoFaturas(tela));
  }


  const [clienteCombo, setClienteCombo] = useState([]);
  const [telaComboGestao, setTelaComboGestao] = useState([]);
  const [telaComboMercadoLivre, setTelaComboMercadoLivre] = useState([]);
  const [telaComboCRM, setTelaComboCRM] = useState([]);
  const [telaComboAdministrativa, setTelaComboAdministrativa] = useState([]);

  const deparaNome = (telas) => {
    var opcoesTelaGestao = [];
    var opcoesTelaMercadoLivre = [];
    var opcoesTelaCRM = [];
    var opcoesTelaAdministrativa = [];
    opcoesTelaGestao.push({ 'id': null, value: 'Todos' });
    opcoesTelaMercadoLivre.push({ 'id': null, value: 'Todos' });
    opcoesTelaCRM.push({ 'id': null, value: 'Todos' });
    opcoesTelaAdministrativa.push({ 'id': null, value: 'Todos' });
    telas.forEach((item) => {

      if (item.idMenuAcesso == 10) {
        opcoesTelaGestao.push({ id: item.id, value: `Gestão de Faturas - ${item.linkAcesso}` })
      }
      if (item.idMenuAcesso == 13) {
        opcoesTelaMercadoLivre.push({ id: item.id, value: `Mercado Livre - ${item.linkAcesso}` })
      }
      if (item.idMenuAcesso == 14) {
        opcoesTelaCRM.push({ id: item.id, value: `CRM - ${item.linkAcesso}` })
      }
      if (item.idMenuAcesso == 16) {
        opcoesTelaAdministrativa.push({ id: item.id, value: `Administrativo - ${item.linkAcesso}` })
      }
    })
    setTelaComboGestao(opcoesTelaGestao);
    setTelaComboMercadoLivre(opcoesTelaMercadoLivre);
    setTelaComboCRM(opcoesTelaCRM);
    setTelaComboAdministrativa(opcoesTelaAdministrativa);
  }

  useEffect(() => { 
    const queryParams = {
      filter: {
        periodo: null,
        data: null,
        idCliente: null,
        idLinkAcesso: null,
        tipoNotificacao: null,
        tipoNotificacaoMenu: null,
        idUsuario: null
      },

      sortOrder: "asc",
      sortField: "VINCode",
      pageNumber: 1,
      pageSize: 30
    };
    dispatch(actionsGestao.fetchGestaoFaturas(queryParams, "T"));
    dispatch(actionsMercadoLivre.fetchMercadoLivres(queryParams, "T"));
    dispatch(actionsAdministrativos.fetchAdministrativos(queryParams, "T"));
    dispatch(actionsCrms.fetchCRMS(queryParams, "T"));
  },[])


  useEffect(() => {
    if (tabNotificacao == "gestaoFaturas") {
      const queryParams = {
        filter: {
          periodo: null,
          data: null,
          idCliente: null,
          idLinkAcesso: null,
          tipoNotificacao: null,
          tipoNotificacaoMenu: null,
          idUsuario: null
        },

        sortOrder: "asc",
        sortField: "VINCode",
        pageNumber: 1,
        pageSize: 30
      };
      dispatch(actionsGestao.fetchGestaoFaturas(queryParams, "T"));
    } else if (tabNotificacao == "mercadoLivre") {
      const queryParams = {
        filter: {
          periodo: null,
          data: null,
          idCliente: null,
          idLinkAcesso: null,
          tipoNotificacao: null,
          tipoNotificacaoMenu: null,
          idUsuario: null
        },

        sortOrder: "asc",
        sortField: "VINCode",
        pageNumber: 1,
        pageSize: 30
      };
      dispatch(actionsMercadoLivre.fetchMercadoLivres(queryParams, "T"));
    } else if (tabNotificacao == "administrativo") {
      const queryParams = {
        filter: {
          periodo: null,
          data: null,
          idCliente: null,
          idLinkAcesso: null,
          tipoNotificacao: null,
          tipoNotificacaoMenu: null,
          idUsuario: null
        },

        sortOrder: "asc",
        sortField: "VINCode",
        pageNumber: 1,
        pageSize: 30
      };
      dispatch(actionsAdministrativos.fetchAdministrativos(queryParams, "T"));
    } else if (tabNotificacao == "crm") {
      const queryParams = {
        filter: {
          periodo: null,
          data: null,
          idCliente: null,
          idLinkAcesso: null,
          tipoNotificacao: null,
          tipoNotificacaoMenu: null,
          idUsuario: null
        },

        sortOrder: "asc",
        sortField: "VINCode",
        pageNumber: 1,
        pageSize: 30
      };
      dispatch(actionsCrms.fetchCRMS(queryParams, "T"));
    }
  }, [tabNotificacao])

  useEffect(() => {
    getSelectCliente(true, false).then(function (retorno) {
      setClienteCombo(retorno);
    });

    getSelectTelas(true, false).then(function (retorno) {
      deparaNome(retorno)
      // setTelaComboGestao(retorno);
    });

  }, [])

  const applyFilter = (values) => {
    if (values.tipoNotificacaoMenu == '1') {
      const queryParams = {
        filter: {
          periodo: values.periodo,
          data: values.data,
          idCliente: values.idCliente,
          idLinkAcesso: values.idLinkAcesso,
          tipoNotificacao: values.situacao,
          tipoNotificacaoMenu: values.tipoNotificacaoMenu,
          idUsuario: values.idUsuario,
          situacao: values.situacao
        },

        sortOrder: "asc",
        sortField: "VINCode",
        pageNumber: 1,
        pageSize: 30
      };
      dispatch(actionsGestao.fetchGestaoFaturas(queryParams, values.todasNotificacaoApenasMinha));
    } else if (values.tipoNotificacaoMenu == '2') {
      const queryParams = {
        filter: {
          periodo: values.periodo,
          data: values.data,
          idCliente: values.idCliente,
          idLinkAcesso: values.idLinkAcesso,
          tipoNotificacao: values.situacao,
          tipoNotificacaoMenu: values.tipoNotificacaoMenu,
          idUsuario: values.idUsuario,
          situacao: values.situacao
        },

        sortOrder: "asc",
        sortField: "VINCode",
        pageNumber: 1,
        pageSize: 30
      };
      dispatch(actionsMercadoLivre.fetchMercadoLivres(queryParams, values.todasNotificacaoApenasMinha));
    } else if (values.tipoNotificacaoMenu == '3') {
      const queryParams = {
        filter: {
          periodo: values.periodo,
          data: values.data,
          idCliente: values.idCliente,
          idLinkAcesso: values.idLinkAcesso,
          tipoNotificacao: values.situacao,
          tipoNotificacaoMenu: values.tipoNotificacaoMenu,
          idUsuario: values.idUsuario,
          situacao: values.situacao
        },

        sortOrder: "asc",
        sortField: "VINCode",
        pageNumber: 1,
        pageSize: 30
      };
      dispatch(actionsAdministrativos.fetchAdministrativos(queryParams, values.todasNotificacaoApenasMinha));
    } else if (values.tipoNotificacaoMenu == '4') {
      const queryParams = {
        filter: {
          periodo: values.periodo,
          data: values.data,
          idCliente: values.idCliente,
          idLinkAcesso: values.idLinkAcesso,
          tipoNotificacao: values.situacao,
          tipoNotificacaoMenu: values.tipoNotificacaoMenu,
          idUsuario: values.idUsuario,
        },

        sortOrder: "asc",
        sortField: "VINCode",
        pageNumber: 1,
        pageSize: 30
      };
      dispatch(actionsCrms.fetchCRMS(queryParams, values.todasNotificacaoApenasMinha));
    }
  }

  const queryParams = {
    filter: {
      periodo: null,
      data: null,
      idCliente: null,
      idLinkAcesso: null,
      tipoNotificacao: null,
      tipoNotificacaoMenu: null,
      idUsuario: null
    },

    sortOrder: "asc",
    sortField: "VINCode",
    pageNumber: 1,
    pageSize: 30
  };

  const handleNotificao = () => {
    // const queryParams = {
    //   filter: {
    //     periodo: null,
    //     data: null,
    //     idCliente: null,
    //     idLinkAcesso: null,
    //     tipoNotificacao: null,
    //     tipoNotificacaoMenu: null,
    //     idUsuario: null
    //   },

    //   sortOrder: "asc",
    //   sortField: "VINCode",
    //   pageNumber: 1,
    //   pageSize: 30
    // };
    dispatch(actionsGestao.marcarComoLidaGestaoFaturas(null, 'T')).then(() => {
      dispatch(actionsGestao.fetchGestaoFaturas(queryParams, "T"));
      dispatch(actionsMercadoLivre.fetchMercadoLivres(queryParams, "T"));
      dispatch(actionsAdministrativos.fetchAdministrativos(queryParams, "T"));
      dispatch(actionsCrms.fetchCRMS(queryParams, "T"));
    })
  }

  const handleMarcarGestao = (e) => {
    dispatch(actionsGestao.marcarComoLidaGestaoFaturas('1', '')).then(() => {
      dispatch(actionsGestao.fetchGestaoFaturas(queryParams));
    });
  }

  const handleMarcarMercadoLivre = (e) => {
    dispatch(actionsMercadoLivre.marcarComoLidaMercadoLivre('2')).then(() => {
      dispatch(actionsMercadoLivre.fetchMercadoLivres(queryParams));
    });
  }


  const handleMarcarAdministrativa = (e) => {
    dispatch(actionsAdministrativos.marcarComoLidaAdministrativo('3')).then(() => {
      dispatch(actionsAdministrativos.fetchAdministrativos(queryParams));
    });
  }

  const handleMarcarCRM = (e) => {
    dispatch(actionsCrms.marcarComoLidaCRM('4')).then(() => {
      dispatch(actionsCrms.fetchCRMS(queryParams));
    });
  }

  return (
    <>
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          type="button"
          className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              d="M4 6h16M4 12h16M4 18h7"
            />
          </svg>
        </button>
        <div class="flex-1 px-4 flex justify-between">
          <div class="flex-1 flex">
            <form class="w-full flex md:ml-0" action="#" method="GET">
              <label for="search-field" class="sr-only">
                Buscar
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search-field"
                  class="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                  placeholder="Buscar"
                  type="search"
                  name="search"
                />
              </div>
            </form>
          </div>
          <div class="ml-1 flex items-center md:ml-1">
            <div class="ml-1 relative">
              <div className="relative inline-block text-left">
                <div className="flex">

                  <button
                    // onClick={() => setDropdownsNotificacao(dropdownsNotificacao !== true ? true : false)}
                    id="menu-button" aria-expanded="true" aria-haspopup="true">
                    <div className="flex items-center mr-10">
                      <div className="rounded-full mr-4">
                        {dropdownsNotificacao == true ? (
                          <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="45" height="45" rx="22.5" fill="#EEF2FF" />
                            <path d="M25.625 27.7083H30.8333L29.3698 26.2449C28.9729 25.848 28.75 25.3097 28.75 24.7484V21.4583C28.75 18.737 27.0108 16.422 24.5833 15.564V15.2083C24.5833 14.0577 23.6506 13.125 22.5 13.125C21.3494 13.125 20.4166 14.0577 20.4166 15.2083V15.564C17.9891 16.422 16.25 18.737 16.25 21.4583V24.7484C16.25 25.3097 16.027 25.848 15.6301 26.2449L14.1666 27.7083H19.375M25.625 27.7083V28.75C25.625 30.4759 24.2258 31.875 22.5 31.875C20.7741 31.875 19.375 30.4759 19.375 28.75V27.7083M25.625 27.7083H19.375" stroke="#A1A1AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        ) : (
                          <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.625 15.7083H17.8333L16.3698 14.2449C15.9729 13.848 15.75 13.3097 15.75 12.7484V9.45834C15.75 6.73705 14.0108 4.42197 11.5833 3.56397V3.20833C11.5833 2.05774 10.6506 1.125 9.49996 1.125C8.34937 1.125 7.41663 2.05774 7.41663 3.20833V3.56397C4.98914 4.42197 3.24996 6.73705 3.24996 9.45834V12.7484C3.24996 13.3097 3.02699 13.848 2.6301 14.2449L1.16663 15.7083H6.37496M12.625 15.7083V16.75C12.625 18.4759 11.2258 19.875 9.49996 19.875C7.77407 19.875 6.37496 18.4759 6.37496 16.75V15.7083M12.625 15.7083H6.37496" stroke="#A1A1AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        )}
                      </div>
                    </div>
                  </button>
                </div>
                <div
                  style={dropdownsNotificacao === true ? { "width": "750px", "height": "600px", "overflow": "scroll" } : { display: 'none' }}
                  className="origin-top-right absolute right-0 mt-2 w-100 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex="-1"
                >
                  {/* <div className="px-4 py-3" role="none">
                    <p className="text-sm" role="none">Bem-vindo!</p>
                    <p className="text-sm font-medium text-gray-900" role="none">{usuario}</p>
                  </div>
                  <div className="py-1" role="none">
                    <a href="#" onClick={handleClickDadosPessoal} className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1"
                      id="menu-item-1">Alterar dados</a>
                    <a href="#"  const handleMarcar = (e) => { 
    dispatch(actions.marcarComoLidaGestaoFaturas('1','')).then(() => { 
      dispatch(actions.fetchGestaoFaturas(gestaoFaturasUIProps.queryParams));
    });
  }
em-2">Configurações</a>
                  </div>
                  <div className="py-1" role="none">
                    <a href="/logout" onClick={() => setDropdownsNotificacao(false)} className="text-gray-700 block w-full text-left px-4 py-2 text-sm">
                      Logout
                    </a>
                  </div> */}
                  <section className="mx-5 xl:mx-8">
                    <div className="flex justify-between items-center mt-2  mb-3">
                      <h6 className="text-gray-600 text-base	 font-bold">
                        Notificações
                      </h6>
                      <h3
                        onClick={(() => handleNotificao())}
                        className="text-gray-400 text-sm	 font-bold"
                        style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'cursor': 'grab' }}
                      >
                        Marcar todas como lidas <div className="ml-2"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 0C7.44772 0 7 0.447715 7 1C7 1.55228 7.44772 2 8 2H10.5858L4.29289 8.29289C3.90237 8.68342 3.90237 9.31658 4.29289 9.70711C4.68342 10.0976 5.31658 10.0976 5.70711 9.70711L12 3.41421V6C12 6.55228 12.4477 7 13 7C13.5523 7 14 6.55228 14 6V1C14 0.447715 13.5523 0 13 0H8Z" fill="#A1A1AA" />
                          <path d="M2 2C0.895431 2 0 2.89543 0 4V12C0 13.1046 0.89543 14 2 14H10C11.1046 14 12 13.1046 12 12V9C12 8.44772 11.5523 8 11 8C10.4477 8 10 8.44772 10 9V12H2V4L5 4C5.55228 4 6 3.55228 6 3C6 2.44772 5.55228 2 5 2H2Z" fill="#A1A1AA" />
                        </svg></div>

                      </h3>
                    </div>
                    <div className="flex justify-between items-center  mb-4">
                      <h6 className="text-gray-400 text-sm	 font-bold">
                        Total de <span className="text-gray-600 text-sm font-bold">{dropdownsNaoLidoQtde}</span> notificaçãos não vistas
                      </h6>
                    </div>

                    <div className="flex justify-between items-center mt-2  mb-4">
                    </div>
                    <div className="border-b border-gray-200">
                      <nav
                        className="lg:flex lg:space-x-7 lg:flex-wrap grid grid-cols-2 justify-items-center"
                        aria-label="Tabs"
                      >
                        <a
                          href="#"
                          onClick={() => setTabNotificacao("gestaoFaturas")}
                          className={
                            tabNotificacao === "gestaoFaturas"
                              ? "border-mainBlue text-mainBlue text-sm  whitespace-nowrap flex py-3 border-b-2 font-bold text-center"
                              : "border-transparent text-gray-500 text-sm  whitespace-nowrap flex py-3 border-b-2 font-normal hover:text-gray-600 text-center"
                          }
                        >
                          Gestão de Faturas
                          <span
                            className={
                              tab === "gestaoFaturas"
                                ? "bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5  dark:bg-blue-200 dark:text-blue-800 rounded-lg	"
                                : "bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5  dark:bg-blue-200 dark:text-blue-800 rounded-lg	"
                            }
                          >{contadorGestaoNaoLido}</span>
                        </a>
                        <a
                          href="#"
                          onClick={() => setTabNotificacao("mercadoLivre")}
                          className={
                            tabNotificacao === "mercadoLivre"
                              ? "border-mainBlue text-mainBlue text-sm  whitespace-nowrap flex py-3 border-b-2 font-bold text-center"
                              : "border-transparent text-gray-500 text-sm  whitespace-nowrap flex py-3 border-b-2 font-normal hover:text-gray-600 text-center"
                          }
                        >
                          Mercado Livre
                          <span
                            className={
                              tab === "mercadoLivre"
                                ? "bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5  dark:bg-blue-200 dark:text-blue-800 rounded-lg	"
                                : "bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5  dark:bg-blue-200 dark:text-blue-800 rounded-lg	"
                            }
                          >{mercadoLivreNaoLido}</span>
                        </a>
                        <a
                          href="#"
                          onClick={() => setTabNotificacao("administrativo")}
                          className={
                            tabNotificacao === "administrativo"
                              ? "border-mainBlue text-mainBlue text-sm  whitespace-nowrap flex py-3 border-b-2 font-bold text-center"
                              : "border-transparent text-gray-500 text-sm  whitespace-nowrap flex py-3 border-b-2 font-normal hover:text-gray-600 text-center"
                          }
                        >
                          Administrativo
                          <span
                            className={
                              tab === "administrativo"
                                ? "bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5  dark:bg-blue-200 dark:text-blue-800 rounded-lg	"
                                : "bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5  dark:bg-blue-200 dark:text-blue-800 rounded-lg	"
                            }
                          >{administrativoNaoLido}</span>
                        </a>
                        <a
                          href="#"
                          onClick={() => setTabNotificacao("crm")}
                          className={
                            tabNotificacao === "crm"
                              ? "border-mainBlue text-mainBlue text-sm  whitespace-nowrap flex py-3 border-b-2 font-bold text-center"
                              : "border-transparent text-gray-500 text-sm  whitespace-nowrap flex py-3 border-b-2 font-normal hover:text-gray-600 text-center"
                          }
                        >
                          CRM
                          <span
                            className={
                              tab === "crm"
                                ? "bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5  dark:bg-blue-200 dark:text-blue-800 rounded-lg	"
                                : "bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5  dark:bg-blue-200 dark:text-blue-800 rounded-lg	"
                            }
                          >{crmNaoLido}</span>
                        </a>
                      </nav>
                      <div className="mt-3">
                        {tabNotificacao === "gestaoFaturas" && (

                          <>
                            <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5">
                              <label className="text-gray-400 text-sm font-semibold">
                                Não Lidas - <span className="text-gray-600">{contadorGestaoNaoLido}</span>
                              </label>

                              <label className="text-gray-500 text-sm font-semibold">
                                <div className="flex items-center mr-10 gap-5">
                                  <button
                                    onClick={() => setDropdownsNaoLido(dropdownsNaoLido !== true ? true : false)}
                                    id="menu-button"
                                    aria-expanded="true"
                                    aria-haspopup="true"
                                  >
                                    <div className="flex flex-col items-start" onClick={(e) => handleMarcarGestao(e)}>
                                      <span className="text-sm text-gray-400 font-bold">Marcar todas como lida</span>
                                    </div>
                                  </button>
                                  <div>
                                    <button
                                      onClick={() => setDropdownsFiltro(dropdownsFiltro !== true ? true : false)}
                                      id="menu-button"
                                      aria-expanded="true"
                                      aria-haspopup="true"
                                    >
                                      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 1C3 0.447715 2.55228 0 2 0C1.44772 0 1 0.447715 1 1V8.26756C0.402199 8.61337 0 9.25972 0 10C0 10.7403 0.402199 11.3866 1 11.7324V13C1 13.5523 1.44772 14 2 14C2.55228 14 3 13.5523 3 13V11.7324C3.5978 11.3866 4 10.7403 4 10C4 9.25972 3.5978 8.61337 3 8.26756V1Z" fill="#A1A1AA" />
                                        <path d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V2.26756C6.4022 2.61337 6 3.25972 6 4C6 4.74028 6.4022 5.38663 7 5.73244V13C7 13.5523 7.44772 14 8 14C8.55229 14 9 13.5523 9 13V5.73244C9.5978 5.38663 10 4.74028 10 4C10 3.25972 9.5978 2.61337 9 2.26756V1Z" fill="#A1A1AA" />
                                        <path d="M14 0C14.5523 0 15 0.447715 15 1V8.26756C15.5978 8.61337 16 9.25972 16 10C16 10.7403 15.5978 11.3866 15 11.7324V13C15 13.5523 14.5523 14 14 14C13.4477 14 13 13.5523 13 13V11.7324C12.4022 11.3866 12 10.7403 12 10C12 9.25972 12.4022 8.61337 13 8.26756V1C13 0.447715 13.4477 0 14 0Z" fill="#A1A1AA" />
                                      </svg>
                                    </button>
                                  </div>

                                  <div className="rounded-full mr-4">

                                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L5 4.08579L8.29289 0.792893C8.68342 0.402369 9.31658 0.402369 9.70711 0.792893C10.0976 1.18342 10.0976 1.81658 9.70711 2.20711L5.70711 6.20711C5.31658 6.59763 4.68342 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z" fill="#A1A1AA" />
                                    </svg>

                                  </div>
                                </div>
                              </label>
                            </div>

                            <div style={dropdownsFiltro === true ? {} : { display: 'none' }}>
                              <Formik
                                initialValues={{
                                  periodo: "",
                                  data: "",
                                  idCliente: "",
                                  idLinkAcesso: "",
                                  tipoNotificacao: "",
                                  situacao: "",
                                  todasNotificacaoApenasMinha: "T",
                                  tipoNotificacaoMenu: '1',
                                }}
                                onSubmit={(values) => {
                                  if (values.periodo == "") {
                                    values.periodo = null
                                  }
                                  if (values.idCliente == "" || values.idCliente == null) {
                                    values.idCliente = null
                                  } else {
                                    values.idCliente = parseInt(values.idCliente)
                                  }
                                  if (values.idLinkAcesso == "" || values.idLinkAcesso == null) {
                                    values.idLinkAcesso = null
                                  } else {
                                    values.idLinkAcesso = parseInt(values.idLinkAcesso)
                                  }
                                  if (values.tipoNotificacao == "") {
                                    values.tipoNotificacao = null
                                  }
                                  if (values.data == "") {
                                    values.data = null
                                  }
                                  if (values.situacao == "") {
                                    values.situacao = null
                                  }
                                  applyFilter(values);
                                }}
                              >
                                {({
                                  values,
                                  handleSubmit,
                                  handleBlur,
                                  handleChange,
                                  setFieldValue,
                                  resetForm
                                }) => (
                                  <div
                                    className="origin-top-right absolute bg-white rounded-lg mt-5 px-5 py-5 shadow-md" style={{ 'width': '340px', 'marginLeft': '220px' }}
                                  // className="origin-top-right absolute right-0 mt-2 w-100 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                                  >
                                    <div class="" >
                                      {/* style={{ 'marginLeft': '250px' }} */}
                                      <div className="flex justify-between">
                                        <h3 className="text-gray-600 text-base	 font-bold">
                                          Filtros
                                        </h3>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            resetForm();
                                            handleSubmit();
                                          }}
                                        >
                                          <h3
                                            className="text-gray-400 text-xs font-bold underline">
                                            Limpar Filtros
                                          </h3>
                                        </button>
                                        <div onClick={() => setDropdownsFiltro(false)}>
                                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.863579 0.86361C1.21505 0.512138 1.7849 0.512138 2.13637 0.86361L5.99998 4.72721L9.86358 0.86361C10.2151 0.512138 10.7849 0.512138 11.1364 0.86361C11.4878 1.21508 11.4878 1.78493 11.1364 2.1364L7.27277 6.00001L11.1364 9.86361C11.4878 10.2151 11.4878 10.7849 11.1364 11.1364C10.7849 11.4879 10.2151 11.4879 9.86358 11.1364L5.99998 7.2728L2.13637 11.1364C1.7849 11.4879 1.21505 11.4879 0.863579 11.1364C0.512108 10.7849 0.512108 10.2151 0.863579 9.86361L4.72718 6.00001L0.863579 2.1364C0.512108 1.78493 0.512108 1.21508 0.863579 0.86361Z" fill="#52525B" />
                                          </svg>
                                        </div>
                                      </div>

                                      <div>
                                        <div class="flex items-center mb-4 gap-3  flex-col mt-4">
                                          <div style={{ 'marginRight': '100px' }}>
                                            <input id="default-radio-1"
                                              type="radio"
                                              value="T"
                                              // onChange={(e) => handleNotificao('T')}
                                              onChange={e => {
                                                setFieldValue("todasNotificacaoApenasMinha", e.target.value);
                                                handleSubmit();
                                              }}
                                              // value={values.pertodasNotificacaoApenasMinhaiodo}
                                              name="default-radio"
                                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-radio-1" class="ml-2 text-xs	 font-medium text-gray-900 dark:text-gray-300">Todas as notificações</label>
                                          </div>
                                          <div style={{ 'marginRight': '56px' }}>
                                            <input
                                              id="default-radio-2"
                                              type="radio"
                                              value="A"
                                              onChange={e => {
                                                setFieldValue("todasNotificacaoApenasMinha", e.target.value);
                                                handleSubmit();
                                              }}
                                              name="default-radio"
                                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-radio-2" class="ml-2 text-xs	 font-medium text-gray-900 dark:text-gray-300">Apenas minhas notificações</label>
                                          </div>
                                        </div>
                                      </div>


                                      <div>
                                        <div className="mt-1 mb-1">
                                          <Select
                                            type="text"
                                            component={Input}
                                            label="Período"
                                            name="periodo"
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue("periodo", e.target.value);
                                              handleSubmit();
                                            }}
                                            value={values.periodo}
                                          >
                                            {getSelectPeriodo(true).map((item) => (
                                              <option value={item.id}>
                                                {item.valor}
                                              </option>
                                            ))}
                                          </Select>
                                        </div>

                                        <div className="mt-1 mb-1">
                                          <Select
                                            name="idCliente"
                                            label="Clientes"
                                            placeholder="Filtrar por Cliente"
                                            onChange={(e) => {
                                              setFieldValue("idCliente", e.target.value);
                                              handleSubmit();
                                            }}
                                            onBlur={handleBlur}
                                            value={values.idCliente}
                                          >
                                            {clienteCombo.map((item) => (
                                              <option value={item.idCliente}>
                                                {item.nomeCliente}
                                              </option>
                                            ))}
                                          </Select>
                                        </div>
                                        <div className="mt-1 mb-1">
                                          <Select
                                            name="idLinkAcesso"
                                            label="Telas"
                                            placeholder="Filtrar por Cliente"
                                            onChange={(e) => {
                                              setFieldValue("idLinkAcesso", e.target.value);
                                              handleSubmit();
                                            }}
                                            onBlur={handleBlur}
                                            value={values.idLinkAcesso}
                                          >
                                            {telaComboGestao.map((item) => (
                                              <option value={item.id}>
                                                {item.value}
                                              </option>
                                            ))}
                                          </Select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2">
                                      <div class="flex items-center mb-4 gap-3">
                                        <input id="default-checkbox"
                                          type="checkbox"
                                          onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                          }}
                                          value=""
                                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="2.5" cy="3" r="2.5" fill="#A1A1AA" />
                                        </svg>
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Selecionar Todos</label>
                                        <span>{contadorGestaoSoma}</span>
                                      </div>
                                      <div class="flex items-center mb-4 gap-3">
                                        <input id="default-checkbox"
                                          type="checkbox"
                                          value="C"
                                          onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                          }}
                                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="2.5" cy="3" r="2.5" fill="#22C55E" />
                                        </svg>
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Concluído</label>
                                        <span>{contadorGestaoConcluido}</span>
                                      </div>
                                      <div class="flex items-center mb-4 gap-3">
                                        <input
                                          id="default-checkbox"
                                          type="checkbox" value="E"
                                          onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                          }}
                                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="2.5" cy="3" r="2.5" fill="#EF4444" />
                                        </svg>
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Erro</label>
                                        <span>{contadorGestaoErro}</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Formik>
                            </div>


                            <div style={dropdownsNaoLido === true ? {} : { display: 'none' }}>
                              {entitiesGestaoFatura && entitiesGestaoFatura.map((item) => {
                                return (
                                  <>
                                    {item.situacaoLeitura == 'N' && (
                                      <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5 ">
                                        <div>
                                          <div className="flex lg:flex-row flex-col  lg:items-center mt-5">
                                            <div className="flex" style={{ display: 'flex', 'marginLeft': '10px', 'alignContent': 'center' }}>
                                              {item.notificacaoSucesso == 'E' ?
                                                (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M19.2389 25.264H15.5709L14.9829 27H12.4769L16.0329 17.172H18.8049L22.3609 27H19.8269L19.2389 25.264ZM18.6229 23.416L17.4049 19.818L16.2009 23.416H18.6229ZM27.1252 17.172C28.1612 17.172 29.0665 17.3773 29.8412 17.788C30.6159 18.1987 31.2132 18.7773 31.6332 19.524C32.0625 20.2613 32.2772 21.1153 32.2772 22.086C32.2772 23.0473 32.0625 23.9013 31.6332 24.648C31.2132 25.3947 30.6112 25.9733 29.8272 26.384C29.0525 26.7947 28.1519 27 27.1252 27H23.4432V17.172H27.1252ZM26.9712 24.928C27.8765 24.928 28.5812 24.6807 29.0852 24.186C29.5892 23.6913 29.8412 22.9913 29.8412 22.086C29.8412 21.1807 29.5892 20.476 29.0852 19.972C28.5812 19.468 27.8765 19.216 26.9712 19.216H25.8372V24.928H26.9712Z" fill="#4169E1" />
                                                </svg>)
                                                : (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M22.59 17.172V27H20.196V22.954H16.472V27H14.078V17.172H16.472V21.022H20.196V17.172H22.59ZM26.8662 25.04H31.0942V27H24.1502V25.18L28.3502 19.132H24.1502V17.172H31.0942V18.992L26.8662 25.04Z" fill="#4169E1" />
                                                </svg>
                                                )}
                                            </div>
                                            <label className="text-gray-500 text-base	font-semibold">
                                              <h3 style={{ display: 'flex', 'marginLeft': '10px', 'justifyContent': 'center', 'alignContent': 'center' }}>{item.situacao == 'E' ? 'Erro' : 'Concluído'}  - {item.notificacao}
                                                {item.situacao == 'E' ? (
                                                  <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignContent': 'center', 'marginLeft': '10px' }}><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                  </svg></div>
                                                ) :
                                                  <>
                                                  </>
                                                  // <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  //   <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                  // </svg>
                                                } </h3>
                                            </label>

                                          </div>
                                          <div className="flex gap-4 ml-14">
                                            <div>
                                              <label className="text-gray-500 text-xs	 font-semibold">
                                                {item.dataNotificacao != null ? moment(item.dataNotificacao).startOf('hour').fromNow() : ''}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs	 font-semibold">
                                                {item.nomeCliente}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs	 font-semibold">
                                                {item.nomeUsuario}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5" cy="5.5" r="5" fill="#4169E1" />
                                          </svg>

                                        </div>
                                      </div>
                                    )}
                                  </>
                                )
                              })}
                            </div>

                            <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5">
                              <label className="text-gray-400 text-sm font-semibold">
                                Lidas - <span className="text-gray-600">{contadorGestaoLido}</span>
                              </label>

                              <label className="text-gray-500 text-sm font-semibold">
                                <button
                                  onClick={() => setDropdownsLido(dropdownsLido !== true ? true : false)}
                                  id="menu-button" aria-expanded="true" aria-haspopup="true">
                                  <div className="flex items-center mr-10 gap-5">
                                    {/* <div className="flex flex-col items-start">
                                <span className="text-sm text-gray-400 font-bold">Marcar todas como lida</span>
                              </div> */}
                                    <div className="rounded-full mr-4">
                                      <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L5 4.08579L8.29289 0.792893C8.68342 0.402369 9.31658 0.402369 9.70711 0.792893C10.0976 1.18342 10.0976 1.81658 9.70711 2.20711L5.70711 6.20711C5.31658 6.59763 4.68342 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z" fill="#A1A1AA" />
                                      </svg>
                                    </div>
                                  </div>
                                </button>
                              </label>
                            </div>

                            <div style={dropdownsLido === true ? {} : { display: 'none' }}>
                              {entitiesGestaoFatura && entitiesGestaoFatura.map((item) => {
                                return (
                                  <>
                                    {item.situacaoLeitura == 'L' && (
                                      <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5 ">
                                        <div>
                                          <div className="flex lg:flex-row flex-col  lg:items-center mt-5">
                                            <div className="flex" style={{ display: 'flex', 'marginLeft': '10px', 'alignContent': 'center' }}>
                                              {item.notificacaoSucesso == 'E' ?
                                                (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M19.2389 25.264H15.5709L14.9829 27H12.4769L16.0329 17.172H18.8049L22.3609 27H19.8269L19.2389 25.264ZM18.6229 23.416L17.4049 19.818L16.2009 23.416H18.6229ZM27.1252 17.172C28.1612 17.172 29.0665 17.3773 29.8412 17.788C30.6159 18.1987 31.2132 18.7773 31.6332 19.524C32.0625 20.2613 32.2772 21.1153 32.2772 22.086C32.2772 23.0473 32.0625 23.9013 31.6332 24.648C31.2132 25.3947 30.6112 25.9733 29.8272 26.384C29.0525 26.7947 28.1519 27 27.1252 27H23.4432V17.172H27.1252ZM26.9712 24.928C27.8765 24.928 28.5812 24.6807 29.0852 24.186C29.5892 23.6913 29.8412 22.9913 29.8412 22.086C29.8412 21.1807 29.5892 20.476 29.0852 19.972C28.5812 19.468 27.8765 19.216 26.9712 19.216H25.8372V24.928H26.9712Z" fill="#4169E1" />
                                                </svg>)
                                                : (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M22.59 17.172V27H20.196V22.954H16.472V27H14.078V17.172H16.472V21.022H20.196V17.172H22.59ZM26.8662 25.04H31.0942V27H24.1502V25.18L28.3502 19.132H24.1502V17.172H31.0942V18.992L26.8662 25.04Z" fill="#4169E1" />
                                                </svg>
                                                )}
                                            </div>
                                            <label className="text-gray-500 text-base	font-semibold">
                                              <h3 style={{ display: 'flex', 'marginLeft': '10px', 'justifyContent': 'center', 'alignContent': 'center' }}>{item.situacao == 'E' ? 'Erro' : 'Concluído'}  - {item.notificacao}
                                                {item.situacao == 'E' ? (<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                </svg>
                                                ) : <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                </svg>
                                                } </h3>
                                            </label>

                                          </div>
                                          <div className="flex gap-4 ml-14">
                                            <div>
                                              <label className="text-gray-500 text-xs font-semibold">
                                                {item.dataNotificacao != null ? moment(item.dataNotificacao).startOf('hour').fromNow() : ''}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs font-semibold">
                                                {item.nomeCliente}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs font-semibold">
                                                {item.nomeUsuario}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5" cy="5.5" r="5" fill="#4169E1" />
                                          </svg>

                                        </div>
                                      </div>
                                    )}

                                  </>
                                )
                              })}
                            </div>
                          </>
                        )}

                        {tabNotificacao === "mercadoLivre" && (
                          <>
                            <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5">
                              <label className="text-gray-400 text-sm font-semibold">
                                Não Lidas - <span className="text-gray-600">{mercadoLivreNaoLido}</span>
                              </label>

                              <label className="text-gray-500 text-sm font-semibold">
                                <div className="flex items-center mr-10 gap-5">
                                  <button
                                    onClick={() => setDropdownsNaoLido(dropdownsNaoLido !== true ? true : false)}
                                    id="menu-button"
                                    aria-expanded="true"
                                    aria-haspopup="true"
                                  >
                                    <div className="flex flex-col items-start" onClick={(e) => handleMarcarMercadoLivre(e)}>
                                      <span className="text-sm text-gray-400 font-bold">Marcar todas como lida</span>
                                    </div>
                                  </button>
                                  <div>
                                    <button
                                      onClick={() => setDropdownsFiltro(dropdownsFiltro !== true ? true : false)}
                                      id="menu-button"
                                      aria-expanded="true"
                                      aria-haspopup="true"
                                    >
                                      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 1C3 0.447715 2.55228 0 2 0C1.44772 0 1 0.447715 1 1V8.26756C0.402199 8.61337 0 9.25972 0 10C0 10.7403 0.402199 11.3866 1 11.7324V13C1 13.5523 1.44772 14 2 14C2.55228 14 3 13.5523 3 13V11.7324C3.5978 11.3866 4 10.7403 4 10C4 9.25972 3.5978 8.61337 3 8.26756V1Z" fill="#A1A1AA" />
                                        <path d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V2.26756C6.4022 2.61337 6 3.25972 6 4C6 4.74028 6.4022 5.38663 7 5.73244V13C7 13.5523 7.44772 14 8 14C8.55229 14 9 13.5523 9 13V5.73244C9.5978 5.38663 10 4.74028 10 4C10 3.25972 9.5978 2.61337 9 2.26756V1Z" fill="#A1A1AA" />
                                        <path d="M14 0C14.5523 0 15 0.447715 15 1V8.26756C15.5978 8.61337 16 9.25972 16 10C16 10.7403 15.5978 11.3866 15 11.7324V13C15 13.5523 14.5523 14 14 14C13.4477 14 13 13.5523 13 13V11.7324C12.4022 11.3866 12 10.7403 12 10C12 9.25972 12.4022 8.61337 13 8.26756V1C13 0.447715 13.4477 0 14 0Z" fill="#A1A1AA" />
                                      </svg>
                                    </button>
                                  </div>

                                  <div className="rounded-full mr-4">

                                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L5 4.08579L8.29289 0.792893C8.68342 0.402369 9.31658 0.402369 9.70711 0.792893C10.0976 1.18342 10.0976 1.81658 9.70711 2.20711L5.70711 6.20711C5.31658 6.59763 4.68342 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z" fill="#A1A1AA" />
                                    </svg>

                                  </div>
                                </div>
                              </label>
                            </div>

                            <div style={dropdownsFiltro === true ? {} : { display: 'none' }}>
                              <Formik
                                initialValues={{
                                  periodo: "",
                                  data: "",
                                  idCliente: "",
                                  idLinkAcesso: "",
                                  tipoNotificacao: "",
                                  situacao: "",
                                  todasNotificacaoApenasMinha: "T",
                                  tipoNotificacaoMenu: '1',
                                }}
                                onSubmit={values => {
                                  if (values.periodo == "") {
                                    values.periodo = null
                                  }
                                  if (values.idCliente == "" || values.idCliente == null) {
                                    values.idCliente = null
                                  } else {
                                    values.idCliente = parseInt(values.idCliente)
                                  }
                                  if (values.idLinkAcesso == "" || values.idLinkAcesso == null) {
                                    values.idLinkAcesso = null
                                  } else {
                                    values.idLinkAcesso = parseInt(values.idLinkAcesso)
                                  }
                                  if (values.tipoNotificacao == "") {
                                    values.tipoNotificacao = null
                                  }
                                  if (values.data == "") {
                                    values.data = null
                                  }
                                  if (values.situacao == "") {
                                    values.situacao = null
                                  }
                                  applyFilter(values);
                                }}
                              >
                                {({
                                  values,
                                  handleSubmit,
                                  handleBlur,
                                  handleChange,
                                  setFieldValue,
                                  resetForm
                                }) => (
                                  <div
                                    className="origin-top-right absolute bg-white rounded-lg mt-5 px-5 py-5 shadow-md" style={{ 'width': '340px', 'marginLeft': '220px' }}
                                  // className="origin-top-right absolute right-0 mt-2 w-100 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                                  >
                                    <div class="" >
                                      {/* style={{ 'marginLeft': '250px' }} */}
                                      <div className="flex justify-between">
                                        <h3 className="text-gray-600 text-base	 font-bold">
                                          Filtros
                                        </h3>
                                        <button type="button"
                                          onClick={() => {
                                            resetForm();
                                            handleSubmit();
                                          }}
                                        >
                                          <h3
                                            className="text-gray-400 text-xs font-bold underline">
                                            Limpar Filtros
                                          </h3>
                                        </button>
                                        <div onClick={() => setDropdownsFiltro(false)}>
                                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.863579 0.86361C1.21505 0.512138 1.7849 0.512138 2.13637 0.86361L5.99998 4.72721L9.86358 0.86361C10.2151 0.512138 10.7849 0.512138 11.1364 0.86361C11.4878 1.21508 11.4878 1.78493 11.1364 2.1364L7.27277 6.00001L11.1364 9.86361C11.4878 10.2151 11.4878 10.7849 11.1364 11.1364C10.7849 11.4879 10.2151 11.4879 9.86358 11.1364L5.99998 7.2728L2.13637 11.1364C1.7849 11.4879 1.21505 11.4879 0.863579 11.1364C0.512108 10.7849 0.512108 10.2151 0.863579 9.86361L4.72718 6.00001L0.863579 2.1364C0.512108 1.78493 0.512108 1.21508 0.863579 0.86361Z" fill="#52525B" />
                                          </svg>
                                        </div>
                                      </div>

                                      <div>
                                        <div class="flex items-center mb-4 gap-3  flex-col mt-4">
                                          <div style={{ 'marginRight': '100px' }}>
                                            <input id="default-radio-1"
                                              type="radio"
                                              value="T"
                                              // onChange={(e) => handleNotificao('T')}
                                              onChange={e => {
                                                setFieldValue("todasNotificacaoApenasMinha", e.target.value);
                                                handleSubmit();
                                              }}
                                              // value={values.pertodasNotificacaoApenasMinhaiodo}
                                              name="default-radio"
                                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-radio-1" class="ml-2 text-xs	 font-medium text-gray-900 dark:text-gray-300">Todas as notificações</label>
                                          </div>
                                          <div style={{ 'marginRight': '56px' }}>
                                            <input
                                              id="default-radio-2"
                                              type="radio"
                                              value="A"
                                              onChange={e => {
                                                setFieldValue("todasNotificacaoApenasMinha", e.target.value);
                                                handleSubmit();
                                              }}
                                              name="default-radio"
                                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-radio-2" class="ml-2 text-xs	 font-medium text-gray-900 dark:text-gray-300">Apenas minhas notificações</label>
                                          </div>
                                        </div>
                                      </div>


                                      <div>
                                        <div className="mt-1 mb-1">
                                          <Select
                                            type="text"
                                            component={Input}
                                            label="Período"
                                            name="periodo"
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue("periodo", e.target.value);
                                              handleSubmit();
                                            }}
                                            value={values.periodo}
                                          >
                                            {getSelectPeriodo(true).map((item) => (
                                              <option value={item.id}>
                                                {item.valor}
                                              </option>
                                            ))}
                                          </Select>
                                        </div>

                                        <div className="mt-1 mb-1">
                                          <Select
                                            name="idCliente"
                                            label="Clientes"
                                            placeholder="Filtrar por Cliente"
                                            onChange={(e) => {
                                              setFieldValue("idCliente", e.target.value);
                                              handleSubmit();
                                            }}
                                            onBlur={handleBlur}
                                            value={values.idCliente}
                                          >
                                            {clienteCombo.map((item) => (
                                              <option value={item.idCliente}>
                                                {item.nomeCliente}
                                              </option>
                                            ))}
                                          </Select>
                                        </div>
                                        <div className="mt-1 mb-1">
                                          <Select
                                            name="idLinkAcesso"
                                            label="Telas"
                                            placeholder="Filtrar por Cliente"
                                            onChange={(e) => {
                                              setFieldValue("idLinkAcesso", e.target.value);
                                              handleSubmit();
                                            }}
                                            onBlur={handleBlur}
                                            value={values.idLinkAcesso}
                                          >
                                            {telaComboGestao.map((item) => (
                                              <option value={item.id}>
                                                {item.value}
                                              </option>
                                            ))}
                                          </Select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2">
                                      <div class="flex items-center mb-4 gap-3">
                                        <input id="default-checkbox"
                                          type="checkbox"
                                          onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                          }}
                                          value=""
                                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="2.5" cy="3" r="2.5" fill="#A1A1AA" />
                                        </svg>
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Selecionar Todos</label>
                                        <span>{mercadoLivreSoma}</span>
                                      </div>
                                      <div class="flex items-center mb-4 gap-3">
                                        <input id="default-checkbox"
                                          type="checkbox"
                                          value="C"
                                          onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                          }}
                                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="2.5" cy="3" r="2.5" fill="#22C55E" />
                                        </svg>
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Concluído</label>
                                        <span>{mercadoLivreConcluido}</span>
                                      </div>
                                      <div class="flex items-center mb-4 gap-3">
                                        <input
                                          id="default-checkbox"
                                          type="checkbox" value="E"
                                          onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                          }}
                                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="2.5" cy="3" r="2.5" fill="#EF4444" />
                                        </svg>
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Erro</label>
                                        <span>{mercadoLivreErro}</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Formik>
                            </div>


                            <div style={dropdownsNaoLido === true ? {} : { display: 'none' }}>
                              {entitiesMercadoLivre && entitiesMercadoLivre.map((item) => {
                                return (
                                  <>
                                    {item.situacaoLeitura == 'N' && (
                                      <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5 ">
                                        <div>
                                          <div className="flex lg:flex-row flex-col  lg:items-center mt-5">
                                            <div className="flex" style={{ display: 'flex', 'marginLeft': '10px', 'alignContent': 'center' }}>
                                              {item.notificacaoSucesso == 'E' ?
                                                (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M19.2389 25.264H15.5709L14.9829 27H12.4769L16.0329 17.172H18.8049L22.3609 27H19.8269L19.2389 25.264ZM18.6229 23.416L17.4049 19.818L16.2009 23.416H18.6229ZM27.1252 17.172C28.1612 17.172 29.0665 17.3773 29.8412 17.788C30.6159 18.1987 31.2132 18.7773 31.6332 19.524C32.0625 20.2613 32.2772 21.1153 32.2772 22.086C32.2772 23.0473 32.0625 23.9013 31.6332 24.648C31.2132 25.3947 30.6112 25.9733 29.8272 26.384C29.0525 26.7947 28.1519 27 27.1252 27H23.4432V17.172H27.1252ZM26.9712 24.928C27.8765 24.928 28.5812 24.6807 29.0852 24.186C29.5892 23.6913 29.8412 22.9913 29.8412 22.086C29.8412 21.1807 29.5892 20.476 29.0852 19.972C28.5812 19.468 27.8765 19.216 26.9712 19.216H25.8372V24.928H26.9712Z" fill="#4169E1" />
                                                </svg>)
                                                : (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M22.59 17.172V27H20.196V22.954H16.472V27H14.078V17.172H16.472V21.022H20.196V17.172H22.59ZM26.8662 25.04H31.0942V27H24.1502V25.18L28.3502 19.132H24.1502V17.172H31.0942V18.992L26.8662 25.04Z" fill="#4169E1" />
                                                </svg>
                                                )}
                                            </div>
                                            <label className="text-gray-500 text-base	font-semibold">
                                              <h3 style={{ display: 'flex', 'marginLeft': '10px', 'justifyContent': 'center', 'alignContent': 'center' }}>{item.situacao == 'E' ? 'Erro' : 'Concluído'}  - {item.notificacao}
                                                {item.situacao == 'E' ? (
                                                  <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignContent': 'center', 'marginLeft': '10px' }}><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                  </svg></div>
                                                ) :
                                                  <>
                                                  </>
                                                  // <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  //   <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                  // </svg>
                                                } </h3>
                                            </label>

                                          </div>
                                          <div className="flex gap-4 ml-14">
                                            <div>
                                              <label className="text-gray-500 text-xs	 font-semibold">
                                                {item.dataNotificacao != null ? moment(item.dataNotificacao).startOf('hour').fromNow() : ''}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs	 font-semibold">
                                                {item.nomeCliente}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs	 font-semibold">
                                                {item.nomeUsuario}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5" cy="5.5" r="5" fill="#4169E1" />
                                          </svg>

                                        </div>
                                      </div>
                                    )}
                                  </>
                                )
                              })}
                            </div>

                            <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5">
                              <label className="text-gray-400 text-sm font-semibold">
                                Lidas - <span className="text-gray-600">{mercadoLivreLido}</span>
                              </label>

                              <label className="text-gray-500 text-sm font-semibold">
                                <button
                                  onClick={() => setDropdownsLido(dropdownsLido !== true ? true : false)}
                                  id="menu-button" aria-expanded="true" aria-haspopup="true">
                                  <div className="flex items-center mr-10 gap-5">
                                    {/* <div className="flex flex-col items-start">
                                <span className="text-sm text-gray-400 font-bold">Marcar todas como lida</span>
                              </div> */}
                                    <div className="rounded-full mr-4">
                                      <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L5 4.08579L8.29289 0.792893C8.68342 0.402369 9.31658 0.402369 9.70711 0.792893C10.0976 1.18342 10.0976 1.81658 9.70711 2.20711L5.70711 6.20711C5.31658 6.59763 4.68342 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z" fill="#A1A1AA" />
                                      </svg>
                                    </div>
                                  </div>
                                </button>
                              </label>
                            </div>

                            <div style={dropdownsLido === true ? {} : { display: 'none' }}>
                              {entitiesMercadoLivre && entitiesMercadoLivre.map((item) => {
                                return (
                                  <>
                                    {item.situacaoLeitura == 'L' && (
                                      <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5 ">
                                        <div>
                                          <div className="flex lg:flex-row flex-col  lg:items-center mt-5">
                                            <div className="flex" style={{ display: 'flex', 'marginLeft': '10px', 'alignContent': 'center' }}>
                                              {item.notificacaoSucesso == 'E' ?
                                                (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M19.2389 25.264H15.5709L14.9829 27H12.4769L16.0329 17.172H18.8049L22.3609 27H19.8269L19.2389 25.264ZM18.6229 23.416L17.4049 19.818L16.2009 23.416H18.6229ZM27.1252 17.172C28.1612 17.172 29.0665 17.3773 29.8412 17.788C30.6159 18.1987 31.2132 18.7773 31.6332 19.524C32.0625 20.2613 32.2772 21.1153 32.2772 22.086C32.2772 23.0473 32.0625 23.9013 31.6332 24.648C31.2132 25.3947 30.6112 25.9733 29.8272 26.384C29.0525 26.7947 28.1519 27 27.1252 27H23.4432V17.172H27.1252ZM26.9712 24.928C27.8765 24.928 28.5812 24.6807 29.0852 24.186C29.5892 23.6913 29.8412 22.9913 29.8412 22.086C29.8412 21.1807 29.5892 20.476 29.0852 19.972C28.5812 19.468 27.8765 19.216 26.9712 19.216H25.8372V24.928H26.9712Z" fill="#4169E1" />
                                                </svg>)
                                                : (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M22.59 17.172V27H20.196V22.954H16.472V27H14.078V17.172H16.472V21.022H20.196V17.172H22.59ZM26.8662 25.04H31.0942V27H24.1502V25.18L28.3502 19.132H24.1502V17.172H31.0942V18.992L26.8662 25.04Z" fill="#4169E1" />
                                                </svg>
                                                )}
                                            </div>
                                            <label className="text-gray-500 text-base	font-semibold">
                                              <h3 style={{ display: 'flex', 'marginLeft': '10px', 'justifyContent': 'center', 'alignContent': 'center' }}>{item.situacao == 'E' ? 'Erro' : 'Concluído'}  - {item.notificacao}
                                                {item.situacao == 'E' ? (<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                </svg>
                                                ) : <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                </svg>
                                                } </h3>
                                            </label>

                                          </div>
                                          <div className="flex gap-4 ml-14">
                                            <div>
                                              <label className="text-gray-500 text-xs font-semibold">
                                                {item.dataNotificacao != null ? moment(item.dataNotificacao).startOf('hour').fromNow() : ''}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs font-semibold">
                                                {item.nomeCliente}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs font-semibold">
                                                {item.nomeUsuario}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5" cy="5.5" r="5" fill="#4169E1" />
                                          </svg>

                                        </div>
                                      </div>
                                    )}

                                  </>
                                )
                              })}
                            </div>
                          </>
                        )}
                        {tabNotificacao === "administrativo" && (

                          <>
                            <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5">
                              <label className="text-gray-400 text-sm font-semibold">
                                Não Lidas - <span className="text-gray-600">{administrativoNaoLido}</span>
                              </label>

                              <label className="text-gray-500 text-sm font-semibold">
                                <div className="flex items-center mr-10 gap-5">
                                  <button
                                    onClick={() => setDropdownsNaoLido(dropdownsNaoLido !== true ? true : false)}
                                    id="menu-button"
                                    aria-expanded="true"
                                    aria-haspopup="true"
                                  >
                                    <div className="flex flex-col items-start" onClick={(e) => handleMarcarAdministrativa(e)}>
                                      <span className="text-sm text-gray-400 font-bold">Marcar todas como lida</span>
                                    </div>
                                  </button>
                                  <div>
                                    <button
                                      onClick={() => setDropdownsFiltro(dropdownsFiltro !== true ? true : false)}
                                      id="menu-button"
                                      aria-expanded="true"
                                      aria-haspopup="true"
                                    >
                                      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 1C3 0.447715 2.55228 0 2 0C1.44772 0 1 0.447715 1 1V8.26756C0.402199 8.61337 0 9.25972 0 10C0 10.7403 0.402199 11.3866 1 11.7324V13C1 13.5523 1.44772 14 2 14C2.55228 14 3 13.5523 3 13V11.7324C3.5978 11.3866 4 10.7403 4 10C4 9.25972 3.5978 8.61337 3 8.26756V1Z" fill="#A1A1AA" />
                                        <path d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V2.26756C6.4022 2.61337 6 3.25972 6 4C6 4.74028 6.4022 5.38663 7 5.73244V13C7 13.5523 7.44772 14 8 14C8.55229 14 9 13.5523 9 13V5.73244C9.5978 5.38663 10 4.74028 10 4C10 3.25972 9.5978 2.61337 9 2.26756V1Z" fill="#A1A1AA" />
                                        <path d="M14 0C14.5523 0 15 0.447715 15 1V8.26756C15.5978 8.61337 16 9.25972 16 10C16 10.7403 15.5978 11.3866 15 11.7324V13C15 13.5523 14.5523 14 14 14C13.4477 14 13 13.5523 13 13V11.7324C12.4022 11.3866 12 10.7403 12 10C12 9.25972 12.4022 8.61337 13 8.26756V1C13 0.447715 13.4477 0 14 0Z" fill="#A1A1AA" />
                                      </svg>
                                    </button>
                                  </div>

                                  <div className="rounded-full mr-4">

                                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L5 4.08579L8.29289 0.792893C8.68342 0.402369 9.31658 0.402369 9.70711 0.792893C10.0976 1.18342 10.0976 1.81658 9.70711 2.20711L5.70711 6.20711C5.31658 6.59763 4.68342 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z" fill="#A1A1AA" />
                                    </svg>

                                  </div>
                                </div>
                              </label>
                            </div>

                            <div style={dropdownsFiltro === true ? {} : { display: 'none' }}>
                              <Formik
                                initialValues={{
                                  periodo: "",
                                  data: "",
                                  idCliente: "",
                                  idLinkAcesso: "",
                                  tipoNotificacao: "",
                                  situacao: "",
                                  todasNotificacaoApenasMinha: "T",
                                  tipoNotificacaoMenu: '1',
                                }}
                                onSubmit={values => {
                                  if (values.periodo == "") {
                                    values.periodo = null
                                  }
                                  if (values.idCliente == "" || values.idCliente == null) {
                                    values.idCliente = null
                                  } else {
                                    values.idCliente = parseInt(values.idCliente)
                                  }
                                  if (values.idLinkAcesso == "" || values.idLinkAcesso == null) {
                                    values.idLinkAcesso = null
                                  } else {
                                    values.idLinkAcesso = parseInt(values.idLinkAcesso)
                                  }
                                  if (values.tipoNotificacao == "") {
                                    values.tipoNotificacao = null
                                  }
                                  if (values.data == "") {
                                    values.data = null
                                  }
                                  if (values.situacao == "") {
                                    values.situacao = null
                                  }
                                  applyFilter(values);
                                }}
                              >
                                {({
                                  values,
                                  handleSubmit,
                                  handleBlur,
                                  handleChange,
                                  setFieldValue,
                                  resetForm
                                }) => (
                                  <div
                                    className="origin-top-right absolute bg-white rounded-lg mt-5 px-5 py-5 shadow-md" style={{ 'width': '340px', 'marginLeft': '220px' }}
                                  // className="origin-top-right absolute right-0 mt-2 w-100 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                                  >
                                    <div class="" >
                                      {/* style={{ 'marginLeft': '250px' }} */}
                                      <div className="flex justify-between">
                                        <h3 className="text-gray-600 text-base	 font-bold">
                                          Filtros
                                        </h3>
                                        <button type="button"
                                          onClick={() => {
                                            resetForm();
                                            handleSubmit();
                                          }}
                                        >
                                          <h3
                                            className="text-gray-400 text-xs font-bold underline">
                                            Limpar Filtros
                                          </h3>
                                        </button>
                                        <div onClick={() => setDropdownsFiltro(false)}>
                                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.863579 0.86361C1.21505 0.512138 1.7849 0.512138 2.13637 0.86361L5.99998 4.72721L9.86358 0.86361C10.2151 0.512138 10.7849 0.512138 11.1364 0.86361C11.4878 1.21508 11.4878 1.78493 11.1364 2.1364L7.27277 6.00001L11.1364 9.86361C11.4878 10.2151 11.4878 10.7849 11.1364 11.1364C10.7849 11.4879 10.2151 11.4879 9.86358 11.1364L5.99998 7.2728L2.13637 11.1364C1.7849 11.4879 1.21505 11.4879 0.863579 11.1364C0.512108 10.7849 0.512108 10.2151 0.863579 9.86361L4.72718 6.00001L0.863579 2.1364C0.512108 1.78493 0.512108 1.21508 0.863579 0.86361Z" fill="#52525B" />
                                          </svg>
                                        </div>
                                      </div>

                                      <div>
                                        <div class="flex items-center mb-4 gap-3  flex-col mt-4">
                                          <div style={{ 'marginRight': '100px' }}>
                                            <input id="default-radio-1"
                                              type="radio"
                                              value="T"
                                              // onChange={(e) => handleNotificao('T')}
                                              onChange={e => {
                                                setFieldValue("todasNotificacaoApenasMinha", e.target.value);
                                                handleSubmit();
                                              }}
                                              // value={values.pertodasNotificacaoApenasMinhaiodo}
                                              name="default-radio"
                                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-radio-1" class="ml-2 text-xs	 font-medium text-gray-900 dark:text-gray-300">Todas as notificações</label>
                                          </div>
                                          <div style={{ 'marginRight': '56px' }}>
                                            <input
                                              id="default-radio-2"
                                              type="radio"
                                              value="A"
                                              onChange={e => {
                                                setFieldValue("todasNotificacaoApenasMinha", e.target.value);
                                                handleSubmit();
                                              }}
                                              name="default-radio"
                                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-radio-2" class="ml-2 text-xs	 font-medium text-gray-900 dark:text-gray-300">Apenas minhas notificações</label>
                                          </div>
                                        </div>
                                      </div>


                                      <div>
                                        <div className="mt-1 mb-1">
                                          <Select
                                            type="text"
                                            component={Input}
                                            label="Período"
                                            name="periodo"
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue("periodo", e.target.value);
                                              handleSubmit();
                                            }}
                                            value={values.periodo}
                                          >
                                            {getSelectPeriodo(true).map((item) => (
                                              <option value={item.id}>
                                                {item.valor}
                                              </option>
                                            ))}
                                          </Select>
                                        </div>

                                        <div className="mt-1 mb-1">
                                          <Select
                                            name="idCliente"
                                            label="Clientes"
                                            placeholder="Filtrar por Cliente"
                                            onChange={(e) => {
                                              setFieldValue("idCliente", e.target.value);
                                              handleSubmit();
                                            }}
                                            onBlur={handleBlur}
                                            value={values.idCliente}
                                          >
                                            {clienteCombo.map((item) => (
                                              <option value={item.idCliente}>
                                                {item.nomeCliente}
                                              </option>
                                            ))}
                                          </Select>
                                        </div>
                                        <div className="mt-1 mb-1">
                                          <Select
                                            name="idLinkAcesso"
                                            label="Telas"
                                            placeholder="Filtrar por Cliente"
                                            onChange={(e) => {
                                              setFieldValue("idLinkAcesso", e.target.value);
                                              handleSubmit();
                                            }}
                                            onBlur={handleBlur}
                                            value={values.idLinkAcesso}
                                          >
                                            {telaComboGestao.map((item) => (
                                              <option value={item.id}>
                                                {item.value}
                                              </option>
                                            ))}
                                          </Select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2">
                                      <div class="flex items-center mb-4 gap-3">
                                        <input id="default-checkbox"
                                          type="checkbox"
                                          onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                          }}
                                          value=""
                                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="2.5" cy="3" r="2.5" fill="#A1A1AA" />
                                        </svg>
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Selecionar Todos</label>
                                        <span>{administrativoSoma}</span>
                                      </div>
                                      <div class="flex items-center mb-4 gap-3">
                                        <input id="default-checkbox"
                                          type="checkbox"
                                          value="C"
                                          onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                          }}
                                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="2.5" cy="3" r="2.5" fill="#22C55E" />
                                        </svg>
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Concluído</label>
                                        <span>{administrativoConcluido}</span>
                                      </div>
                                      <div class="flex items-center mb-4 gap-3">
                                        <input
                                          id="default-checkbox"
                                          type="checkbox" value="E"
                                          onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                          }}
                                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="2.5" cy="3" r="2.5" fill="#EF4444" />
                                        </svg>
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Erro</label>
                                        <span>{administrativoErro}</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Formik>
                            </div>


                            <div style={dropdownsNaoLido === true ? {} : { display: 'none' }}>
                              {entitiesAdministrativo && entitiesAdministrativo.map((item) => {
                                return (
                                  <>
                                    {item.situacaoLeitura == 'N' && (
                                      <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5 ">
                                        <div>
                                          <div className="flex lg:flex-row flex-col  lg:items-center mt-5">
                                            <div className="flex" style={{ display: 'flex', 'marginLeft': '10px', 'alignContent': 'center' }}>
                                              {item.notificacaoSucesso == 'E' ?
                                                (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M19.2389 25.264H15.5709L14.9829 27H12.4769L16.0329 17.172H18.8049L22.3609 27H19.8269L19.2389 25.264ZM18.6229 23.416L17.4049 19.818L16.2009 23.416H18.6229ZM27.1252 17.172C28.1612 17.172 29.0665 17.3773 29.8412 17.788C30.6159 18.1987 31.2132 18.7773 31.6332 19.524C32.0625 20.2613 32.2772 21.1153 32.2772 22.086C32.2772 23.0473 32.0625 23.9013 31.6332 24.648C31.2132 25.3947 30.6112 25.9733 29.8272 26.384C29.0525 26.7947 28.1519 27 27.1252 27H23.4432V17.172H27.1252ZM26.9712 24.928C27.8765 24.928 28.5812 24.6807 29.0852 24.186C29.5892 23.6913 29.8412 22.9913 29.8412 22.086C29.8412 21.1807 29.5892 20.476 29.0852 19.972C28.5812 19.468 27.8765 19.216 26.9712 19.216H25.8372V24.928H26.9712Z" fill="#4169E1" />
                                                </svg>)
                                                : (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M22.59 17.172V27H20.196V22.954H16.472V27H14.078V17.172H16.472V21.022H20.196V17.172H22.59ZM26.8662 25.04H31.0942V27H24.1502V25.18L28.3502 19.132H24.1502V17.172H31.0942V18.992L26.8662 25.04Z" fill="#4169E1" />
                                                </svg>
                                                )}
                                            </div>
                                            <label className="text-gray-500 text-base	font-semibold">
                                              <h3 style={{ display: 'flex', 'marginLeft': '10px', 'justifyContent': 'center', 'alignContent': 'center' }}>{item.situacao == 'E' ? 'Erro' : 'Concluído'}  - {item.notificacao}
                                                {item.situacao == 'E' ? (
                                                  <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignContent': 'center', 'marginLeft': '10px' }}><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                  </svg></div>
                                                ) :
                                                  <>
                                                  </>
                                                  // <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  //   <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                  // </svg>
                                                } </h3>
                                            </label>

                                          </div>
                                          <div className="flex gap-4 ml-14">
                                            <div>
                                              <label className="text-gray-500 text-xs	 font-semibold">
                                                {item.dataNotificacao != null ? moment(item.dataNotificacao).startOf('hour').fromNow() : ''}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs	 font-semibold">
                                                {item.nomeCliente}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs	 font-semibold">
                                                {item.nomeUsuario}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5" cy="5.5" r="5" fill="#4169E1" />
                                          </svg>

                                        </div>
                                      </div>
                                    )}
                                  </>
                                )
                              })}
                            </div>

                            <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5">
                              <label className="text-gray-400 text-sm font-semibold">
                                Lidas - <span className="text-gray-600">{administrativoLido}</span>
                              </label>

                              <label className="text-gray-500 text-sm font-semibold">
                                <button
                                  onClick={() => setDropdownsLido(dropdownsLido !== true ? true : false)}
                                  id="menu-button" aria-expanded="true" aria-haspopup="true">
                                  <div className="flex items-center mr-10 gap-5">
                                    {/* <div className="flex flex-col items-start">
    <span className="text-sm text-gray-400 font-bold">Marcar todas como lida</span>
  </div> */}
                                    <div className="rounded-full mr-4">
                                      <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L5 4.08579L8.29289 0.792893C8.68342 0.402369 9.31658 0.402369 9.70711 0.792893C10.0976 1.18342 10.0976 1.81658 9.70711 2.20711L5.70711 6.20711C5.31658 6.59763 4.68342 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z" fill="#A1A1AA" />
                                      </svg>
                                    </div>
                                  </div>
                                </button>
                              </label>
                            </div>

                            <div style={dropdownsLido === true ? {} : { display: 'none' }}>
                              {entitiesAdministrativo && entitiesAdministrativo.map((item) => {
                                return (
                                  <>
                                    {item.situacaoLeitura == 'L' && (
                                      <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5 ">
                                        <div>
                                          <div className="flex lg:flex-row flex-col  lg:items-center mt-5">
                                            <div className="flex" style={{ display: 'flex', 'marginLeft': '10px', 'alignContent': 'center' }}>
                                              {item.notificacaoSucesso == 'E' ?
                                                (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M19.2389 25.264H15.5709L14.9829 27H12.4769L16.0329 17.172H18.8049L22.3609 27H19.8269L19.2389 25.264ZM18.6229 23.416L17.4049 19.818L16.2009 23.416H18.6229ZM27.1252 17.172C28.1612 17.172 29.0665 17.3773 29.8412 17.788C30.6159 18.1987 31.2132 18.7773 31.6332 19.524C32.0625 20.2613 32.2772 21.1153 32.2772 22.086C32.2772 23.0473 32.0625 23.9013 31.6332 24.648C31.2132 25.3947 30.6112 25.9733 29.8272 26.384C29.0525 26.7947 28.1519 27 27.1252 27H23.4432V17.172H27.1252ZM26.9712 24.928C27.8765 24.928 28.5812 24.6807 29.0852 24.186C29.5892 23.6913 29.8412 22.9913 29.8412 22.086C29.8412 21.1807 29.5892 20.476 29.0852 19.972C28.5812 19.468 27.8765 19.216 26.9712 19.216H25.8372V24.928H26.9712Z" fill="#4169E1" />
                                                </svg>)
                                                : (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M22.59 17.172V27H20.196V22.954H16.472V27H14.078V17.172H16.472V21.022H20.196V17.172H22.59ZM26.8662 25.04H31.0942V27H24.1502V25.18L28.3502 19.132H24.1502V17.172H31.0942V18.992L26.8662 25.04Z" fill="#4169E1" />
                                                </svg>
                                                )}
                                            </div>
                                            <label className="text-gray-500 text-base	font-semibold">
                                              <h3 style={{ display: 'flex', 'marginLeft': '10px', 'justifyContent': 'center', 'alignContent': 'center' }}>{item.situacao == 'E' ? 'Erro' : 'Concluído'}  - {item.notificacao}
                                                {item.situacao == 'E' ? (<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                </svg>
                                                ) : <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                </svg>
                                                } </h3>
                                            </label>

                                          </div>
                                          <div className="flex gap-4 ml-14">
                                            <div>
                                              <label className="text-gray-500 text-xs font-semibold">
                                                {item.dataNotificacao != null ? moment(item.dataNotificacao).startOf('hour').fromNow() : ''}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs font-semibold">
                                                {item.nomeCliente}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs font-semibold">
                                                {item.nomeUsuario}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5" cy="5.5" r="5" fill="#4169E1" />
                                          </svg>

                                        </div>
                                      </div>
                                    )}

                                  </>
                                )
                              })}
                            </div>
                          </>
                        )}
                        {tabNotificacao === "crm" && (
                          <>
                            <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5">
                              <label className="text-gray-400 text-sm font-semibold">
                                Não Lidas - <span className="text-gray-600">{crmNaoLido}</span>
                              </label>

                              <label className="text-gray-500 text-sm font-semibold">
                                <div className="flex items-center mr-10 gap-5">
                                  <button
                                    onClick={() => setDropdownsNaoLido(dropdownsNaoLido !== true ? true : false)}
                                    id="menu-button"
                                    aria-expanded="true"
                                    aria-haspopup="true"
                                  >
                                    <div className="flex flex-col items-start" onClick={(e) => handleMarcarCRM(e)}>
                                      <span className="text-sm text-gray-400 font-bold">Marcar todas como lida</span>
                                    </div>
                                  </button>
                                  <div>
                                    <button
                                      onClick={() => setDropdownsFiltro(dropdownsFiltro !== true ? true : false)}
                                      id="menu-button"
                                      aria-expanded="true"
                                      aria-haspopup="true"
                                    >
                                      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 1C3 0.447715 2.55228 0 2 0C1.44772 0 1 0.447715 1 1V8.26756C0.402199 8.61337 0 9.25972 0 10C0 10.7403 0.402199 11.3866 1 11.7324V13C1 13.5523 1.44772 14 2 14C2.55228 14 3 13.5523 3 13V11.7324C3.5978 11.3866 4 10.7403 4 10C4 9.25972 3.5978 8.61337 3 8.26756V1Z" fill="#A1A1AA" />
                                        <path d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V2.26756C6.4022 2.61337 6 3.25972 6 4C6 4.74028 6.4022 5.38663 7 5.73244V13C7 13.5523 7.44772 14 8 14C8.55229 14 9 13.5523 9 13V5.73244C9.5978 5.38663 10 4.74028 10 4C10 3.25972 9.5978 2.61337 9 2.26756V1Z" fill="#A1A1AA" />
                                        <path d="M14 0C14.5523 0 15 0.447715 15 1V8.26756C15.5978 8.61337 16 9.25972 16 10C16 10.7403 15.5978 11.3866 15 11.7324V13C15 13.5523 14.5523 14 14 14C13.4477 14 13 13.5523 13 13V11.7324C12.4022 11.3866 12 10.7403 12 10C12 9.25972 12.4022 8.61337 13 8.26756V1C13 0.447715 13.4477 0 14 0Z" fill="#A1A1AA" />
                                      </svg>
                                    </button>
                                  </div>

                                  <div className="rounded-full mr-4">

                                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L5 4.08579L8.29289 0.792893C8.68342 0.402369 9.31658 0.402369 9.70711 0.792893C10.0976 1.18342 10.0976 1.81658 9.70711 2.20711L5.70711 6.20711C5.31658 6.59763 4.68342 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z" fill="#A1A1AA" />
                                    </svg>

                                  </div>
                                </div>
                              </label>
                            </div>

                            <div style={dropdownsFiltro === true ? {} : { display: 'none' }}>
                              <Formik
                                initialValues={{
                                  periodo: "",
                                  data: "",
                                  idCliente: "",
                                  idLinkAcesso: "",
                                  tipoNotificacao: "",
                                  situacao: "",
                                  todasNotificacaoApenasMinha: "T",
                                  tipoNotificacaoMenu: '1',
                                }}
                                onSubmit={values => {
                                  if (values.periodo == "") {
                                    values.periodo = null
                                  }
                                  if (values.idCliente == "" || values.idCliente == null) {
                                    values.idCliente = null
                                  } else {
                                    values.idCliente = parseInt(values.idCliente)
                                  }
                                  if (values.idLinkAcesso == "" || values.idLinkAcesso == null) {
                                    values.idLinkAcesso = null
                                  } else {
                                    values.idLinkAcesso = parseInt(values.idLinkAcesso)
                                  }
                                  if (values.tipoNotificacao == "") {
                                    values.tipoNotificacao = null
                                  }
                                  if (values.data == "") {
                                    values.data = null
                                  }
                                  if (values.situacao == "") {
                                    values.situacao = null
                                  }
                                  applyFilter(values);
                                }}
                              >
                                {({
                                  values,
                                  handleSubmit,
                                  handleBlur,
                                  handleChange,
                                  setFieldValue,
                                  resetForm
                                }) => (
                                  <div
                                    className="origin-top-right absolute bg-white rounded-lg mt-5 px-5 py-5 shadow-md" style={{ 'width': '340px', 'marginLeft': '220px' }}
                                  // className="origin-top-right absolute right-0 mt-2 w-100 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                                  >
                                    <div class="" >
                                      {/* style={{ 'marginLeft': '250px' }} */}
                                      <div className="flex justify-between">
                                        <h3 className="text-gray-600 text-base	 font-bold">
                                          Filtros
                                        </h3>
                                        <button type="button"
                                          onClick={() => {
                                            resetForm();
                                            handleSubmit();
                                          }}
                                        >
                                          <h3
                                            className="text-gray-400 text-xs font-bold underline">
                                            Limpar Filtros
                                          </h3>
                                        </button>
                                        <div onClick={() => setDropdownsFiltro(false)}>
                                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.863579 0.86361C1.21505 0.512138 1.7849 0.512138 2.13637 0.86361L5.99998 4.72721L9.86358 0.86361C10.2151 0.512138 10.7849 0.512138 11.1364 0.86361C11.4878 1.21508 11.4878 1.78493 11.1364 2.1364L7.27277 6.00001L11.1364 9.86361C11.4878 10.2151 11.4878 10.7849 11.1364 11.1364C10.7849 11.4879 10.2151 11.4879 9.86358 11.1364L5.99998 7.2728L2.13637 11.1364C1.7849 11.4879 1.21505 11.4879 0.863579 11.1364C0.512108 10.7849 0.512108 10.2151 0.863579 9.86361L4.72718 6.00001L0.863579 2.1364C0.512108 1.78493 0.512108 1.21508 0.863579 0.86361Z" fill="#52525B" />
                                          </svg>
                                        </div>
                                      </div>

                                      <div>
                                        <div class="flex items-center mb-4 gap-3  flex-col mt-4">
                                          <div style={{ 'marginRight': '100px' }}>
                                            <input id="default-radio-1"
                                              type="radio"
                                              value="T"
                                              // onChange={(e) => handleNotificao('T')}
                                              onChange={e => {
                                                setFieldValue("todasNotificacaoApenasMinha", e.target.value);
                                                handleSubmit();
                                              }}
                                              // value={values.pertodasNotificacaoApenasMinhaiodo}
                                              name="default-radio"
                                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-radio-1" class="ml-2 text-xs	 font-medium text-gray-900 dark:text-gray-300">Todas as notificações</label>
                                          </div>
                                          <div style={{ 'marginRight': '56px' }}>
                                            <input
                                              id="default-radio-2"
                                              type="radio"
                                              value="A"
                                              onChange={e => {
                                                setFieldValue("todasNotificacaoApenasMinha", e.target.value);
                                                handleSubmit();
                                              }}
                                              name="default-radio"
                                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-radio-2" class="ml-2 text-xs	 font-medium text-gray-900 dark:text-gray-300">Apenas minhas notificações</label>
                                          </div>
                                        </div>
                                      </div>


                                      <div>
                                        <div className="mt-1 mb-1">
                                          <Select
                                            type="text"
                                            component={Input}
                                            label="Período"
                                            name="periodo"
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue("periodo", e.target.value);
                                              handleSubmit();
                                            }}
                                            value={values.periodo}
                                          >
                                            {getSelectPeriodo(true).map((item) => (
                                              <option value={item.id}>
                                                {item.valor}
                                              </option>
                                            ))}
                                          </Select>
                                        </div>

                                        <div className="mt-1 mb-1">
                                          <Select
                                            name="idCliente"
                                            label="Clientes"
                                            placeholder="Filtrar por Cliente"
                                            onChange={(e) => {
                                              setFieldValue("idCliente", e.target.value);
                                              handleSubmit();
                                            }}
                                            onBlur={handleBlur}
                                            value={values.idCliente}
                                          >
                                            {clienteCombo.map((item) => (
                                              <option value={item.idCliente}>
                                                {item.nomeCliente}
                                              </option>
                                            ))}
                                          </Select>
                                        </div>
                                        <div className="mt-1 mb-1">
                                          <Select
                                            name="idLinkAcesso"
                                            label="Telas"
                                            placeholder="Filtrar por Cliente"
                                            onChange={(e) => {
                                              setFieldValue("idLinkAcesso", e.target.value);
                                              handleSubmit();
                                            }}
                                            onBlur={handleBlur}
                                            value={values.idLinkAcesso}
                                          >
                                            {telaComboGestao.map((item) => (
                                              <option value={item.id}>
                                                {item.value}
                                              </option>
                                            ))}
                                          </Select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2">
                                      <div class="flex items-center mb-4 gap-3">
                                        <input id="default-checkbox"
                                          type="checkbox"
                                          onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                          }}
                                          value=""
                                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="2.5" cy="3" r="2.5" fill="#A1A1AA" />
                                        </svg>
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Selecionar Todos</label>
                                        <span>{crmSoma}</span>
                                      </div>
                                      <div class="flex items-center mb-4 gap-3">
                                        <input id="default-checkbox"
                                          type="checkbox"
                                          value="C"
                                          onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                          }}
                                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="2.5" cy="3" r="2.5" fill="#22C55E" />
                                        </svg>
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Concluído</label>
                                        <span>{crmConcluido}</span>
                                      </div>
                                      <div class="flex items-center mb-4 gap-3">
                                        <input
                                          id="default-checkbox"
                                          type="checkbox" value="E"
                                          onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                          }}
                                          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="2.5" cy="3" r="2.5" fill="#EF4444" />
                                        </svg>
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Erro</label>
                                        <span>{crmErro}</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Formik>
                            </div>


                            <div style={dropdownsNaoLido === true ? {} : { display: 'none' }}>
                              {entitiesCRMS && entitiesCRMS.map((item) => {
                                return (
                                  <>
                                    {item.situacaoLeitura == 'N' && (
                                      <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5 ">
                                        <div>
                                          <div className="flex lg:flex-row flex-col  lg:items-center mt-5">
                                            <div className="flex" style={{ display: 'flex', 'marginLeft': '10px', 'alignContent': 'center' }}>
                                              {item.notificacaoSucesso == 'E' ?
                                                (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M19.2389 25.264H15.5709L14.9829 27H12.4769L16.0329 17.172H18.8049L22.3609 27H19.8269L19.2389 25.264ZM18.6229 23.416L17.4049 19.818L16.2009 23.416H18.6229ZM27.1252 17.172C28.1612 17.172 29.0665 17.3773 29.8412 17.788C30.6159 18.1987 31.2132 18.7773 31.6332 19.524C32.0625 20.2613 32.2772 21.1153 32.2772 22.086C32.2772 23.0473 32.0625 23.9013 31.6332 24.648C31.2132 25.3947 30.6112 25.9733 29.8272 26.384C29.0525 26.7947 28.1519 27 27.1252 27H23.4432V17.172H27.1252ZM26.9712 24.928C27.8765 24.928 28.5812 24.6807 29.0852 24.186C29.5892 23.6913 29.8412 22.9913 29.8412 22.086C29.8412 21.1807 29.5892 20.476 29.0852 19.972C28.5812 19.468 27.8765 19.216 26.9712 19.216H25.8372V24.928H26.9712Z" fill="#4169E1" />
                                                </svg>)
                                                : (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M22.59 17.172V27H20.196V22.954H16.472V27H14.078V17.172H16.472V21.022H20.196V17.172H22.59ZM26.8662 25.04H31.0942V27H24.1502V25.18L28.3502 19.132H24.1502V17.172H31.0942V18.992L26.8662 25.04Z" fill="#4169E1" />
                                                </svg>
                                                )}
                                            </div>
                                            <label className="text-gray-500 text-base	font-semibold">
                                              <h3 style={{ display: 'flex', 'marginLeft': '10px', 'justifyContent': 'center', 'alignContent': 'center' }}>{item.situacao == 'E' ? 'Erro' : 'Concluído'}  - {item.notificacao}
                                                {item.situacao == 'E' ? (
                                                  <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignContent': 'center', 'marginLeft': '10px' }}><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                  </svg></div>
                                                ) :
                                                  <>
                                                  </>
                                                  // <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  //   <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                  // </svg>
                                                } </h3>
                                            </label>

                                          </div>
                                          <div className="flex gap-4 ml-14">
                                            <div>
                                              <label className="text-gray-500 text-xs	 font-semibold">
                                                {item.dataNotificacao != null ? moment(item.dataNotificacao).startOf('hour').fromNow() : ''}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs	 font-semibold">
                                                {item.nomeCliente}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs	 font-semibold">
                                                {item.nomeUsuario}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5" cy="5.5" r="5" fill="#4169E1" />
                                          </svg>

                                        </div>
                                      </div>
                                    )}
                                  </>
                                )
                              })}
                            </div>

                            <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5">
                              <label className="text-gray-400 text-sm font-semibold">
                                Lidas - <span className="text-gray-600">{crmLido}</span>
                              </label>

                              <label className="text-gray-500 text-sm font-semibold">
                                <button
                                  onClick={() => setDropdownsLido(dropdownsLido !== true ? true : false)}
                                  id="menu-button" aria-expanded="true" aria-haspopup="true">
                                  <div className="flex items-center mr-10 gap-5">
                                    {/* <div className="flex flex-col items-start">
                                                          <span className="text-sm text-gray-400 font-bold">Marcar todas como lida</span>
                                                        </div> */}
                                    <div className="rounded-full mr-4">
                                      <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L5 4.08579L8.29289 0.792893C8.68342 0.402369 9.31658 0.402369 9.70711 0.792893C10.0976 1.18342 10.0976 1.81658 9.70711 2.20711L5.70711 6.20711C5.31658 6.59763 4.68342 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z" fill="#A1A1AA" />
                                      </svg>
                                    </div>
                                  </div>
                                </button>
                              </label>
                            </div>

                            <div style={dropdownsLido === true ? {} : { display: 'none' }}>
                              {entitiesCRMS && entitiesCRMS.map((item) => {
                                return (
                                  <>
                                    {item.situacaoLeitura == 'L' && (
                                      <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center mt-5 ">
                                        <div>
                                          <div className="flex lg:flex-row flex-col  lg:items-center mt-5">
                                            <div className="flex" style={{ display: 'flex', 'marginLeft': '10px', 'alignContent': 'center' }}>
                                              {item.notificacaoSucesso == 'E' ?
                                                (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M19.2389 25.264H15.5709L14.9829 27H12.4769L16.0329 17.172H18.8049L22.3609 27H19.8269L19.2389 25.264ZM18.6229 23.416L17.4049 19.818L16.2009 23.416H18.6229ZM27.1252 17.172C28.1612 17.172 29.0665 17.3773 29.8412 17.788C30.6159 18.1987 31.2132 18.7773 31.6332 19.524C32.0625 20.2613 32.2772 21.1153 32.2772 22.086C32.2772 23.0473 32.0625 23.9013 31.6332 24.648C31.2132 25.3947 30.6112 25.9733 29.8272 26.384C29.0525 26.7947 28.1519 27 27.1252 27H23.4432V17.172H27.1252ZM26.9712 24.928C27.8765 24.928 28.5812 24.6807 29.0852 24.186C29.5892 23.6913 29.8412 22.9913 29.8412 22.086C29.8412 21.1807 29.5892 20.476 29.0852 19.972C28.5812 19.468 27.8765 19.216 26.9712 19.216H25.8372V24.928H26.9712Z" fill="#4169E1" />
                                                </svg>)
                                                : (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                  <path d="M22.59 17.172V27H20.196V22.954H16.472V27H14.078V17.172H16.472V21.022H20.196V17.172H22.59ZM26.8662 25.04H31.0942V27H24.1502V25.18L28.3502 19.132H24.1502V17.172H31.0942V18.992L26.8662 25.04Z" fill="#4169E1" />
                                                </svg>
                                                )}
                                            </div>
                                            <label className="text-gray-500 text-base	font-semibold">
                                              <h3 style={{ display: 'flex', 'marginLeft': '10px', 'justifyContent': 'center', 'alignContent': 'center' }}>{item.situacao == 'E' ? 'Erro' : 'Concluído'}  - {item.notificacao}
                                                {item.situacao == 'E' ? (<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                </svg>
                                                ) : <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                </svg>
                                                } </h3>
                                            </label>

                                          </div>
                                          <div className="flex gap-4 ml-14">
                                            <div>
                                              <label className="text-gray-500 text-xs font-semibold">
                                                {item.dataNotificacao != null ? moment(item.dataNotificacao).startOf('hour').fromNow() : ''}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs font-semibold">
                                                {item.nomeCliente}
                                              </label>
                                            </div>
                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
                                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                              </svg>
                                            </div>
                                            <div>
                                              <label className="text-gray-500 text-xs font-semibold">
                                                {item.nomeUsuario}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5" cy="5.5" r="5" fill="#4169E1" />
                                          </svg>

                                        </div>
                                      </div>
                                    )}

                                  </>
                                )
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div class="ml-4 flex items-center md:ml-6">
            <div class="ml-3 relative">
              <div className="relative inline-block text-left">
                <div className="flex">

                  <button
                    onClick={() => setDropdownsLogin(dropdownsLogin !== true ? true : false)}
                    id="menu-button" aria-expanded="true" aria-haspopup="true">
                    <div className="flex items-center mr-10">
                      <div className="rounded-full mr-4">
                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="15.5" cy="15.5" r="15.5" fill="#F5F5F5" />
                          <path d="M18.9229 16.1549C19.8274 15.4432 20.4876 14.4673 20.8117 13.363C21.1357 12.2586 21.1075 11.0807 20.731 9.99313C20.3544 8.90555 19.6483 7.96238 18.7107 7.29483C17.7732 6.62728 16.6509 6.26855 15.4999 6.26855C14.349 6.26855 13.2267 6.62728 12.2892 7.29483C11.3516 7.96238 10.6454 8.90555 10.2689 9.99313C9.89233 11.0807 9.86413 12.2586 10.1882 13.363C10.5123 14.4673 11.1725 15.4432 12.077 16.1549C10.5271 16.7758 9.17472 17.8058 8.16409 19.1349C7.15346 20.464 6.52245 22.0424 6.33833 23.7019C6.325 23.8231 6.33567 23.9457 6.36972 24.0627C6.40377 24.1797 6.46055 24.2889 6.53679 24.384C6.69079 24.5761 6.91477 24.6991 7.15946 24.726C7.40415 24.7529 7.64952 24.6815 7.84158 24.5275C8.03363 24.3735 8.15665 24.1496 8.18357 23.9049C8.38616 22.1013 9.24613 20.4357 10.5992 19.2261C11.9522 18.0165 13.7035 17.3478 15.5184 17.3478C17.3333 17.3478 19.0845 18.0165 20.4376 19.2261C21.7906 20.4357 22.6506 22.1013 22.8532 23.9049C22.8783 24.1316 22.9865 24.341 23.1568 24.4926C23.3272 24.6442 23.5477 24.7274 23.7758 24.726H23.8773C24.1192 24.6982 24.3402 24.5759 24.4923 24.3858C24.6444 24.1957 24.7152 23.9532 24.6892 23.7111C24.5042 22.0469 23.8698 20.4644 22.854 19.1333C21.8382 17.8021 20.4792 16.7725 18.9229 16.1549ZM15.4999 15.4998C14.77 15.4998 14.0565 15.2834 13.4496 14.8779C12.8427 14.4723 12.3697 13.896 12.0904 13.2216C11.8111 12.5473 11.738 11.8052 11.8804 11.0894C12.0228 10.3735 12.3743 9.7159 12.8904 9.19978C13.4065 8.68366 14.0641 8.33217 14.78 8.18977C15.4958 8.04738 16.2379 8.12046 16.9122 8.39978C17.5866 8.67911 18.1629 9.15213 18.5685 9.75902C18.974 10.3659 19.1904 11.0794 19.1904 11.8093C19.1904 12.7881 18.8016 13.7268 18.1095 14.4189C17.4174 15.111 16.4787 15.4998 15.4999 15.4998Z" fill="#52525B" />
                        </svg>
                      </div>
                      <div className="flex flex-col items-start">
                        <span className="text-sm text-gray-400 font-bold">{usuario}</span>
                      </div>
                    </div>
                  </button>
                </div>
                <div
                  style={dropdownsLogin === true ? {} : { display: 'none' }}
                  className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                  <div className="px-4 py-3" role="none">
                    <p className="text-sm" role="none">Bem-vindo!</p>
                    <p className="text-sm font-medium text-gray-900" role="none">{usuario}</p>
                  </div>
                  <div className="py-1" role="none">
                    <a href="#" onClick={handleClickDadosPessoal} className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1"
                      id="menu-item-1">Alterar dados</a>
                    <a href="#" onClick={handleClick} style={{ "cursor": "pointer" }} className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1"
                      id="menu-item-2">Configurações</a>
                  </div>
                  <div className="py-1" role="none">
                    <a href="/logout" onClick={() => setDropdownsLogin(false)} className="text-gray-700 block w-full text-left px-4 py-2 text-sm">
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}
