import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { usinasSlice } from "../app/modules/View/_redux/usinas/usinasSlice";
import { usuariosSlice } from "../app/modules/View/_redux/usuarios/usuariosSlice";
import { clientesSlice } from "../app/modules/View/_redux/clientes/clientesSlice";
import { crmSlice } from "../app/modules/View/_redux/dashboard/crm/crmSlice";
import { acessosSlice } from "../app/modules/View/_redux/acessos/acessosSlice";
import { usuarioClientesSlice } from "../app/modules/View/_redux/usuariosCliente/usuarioClientesSlice";
import { anexosLeadSlice } from "../app/modules/View/_redux/anexosLead/anexosLeadSlice";
import { anotacoesLeadSlice } from "../app/modules/View/_redux/anotacoesLead/anotacoesLeadSlice";
import { leadsSlice } from "../app/modules/View/_redux/leads/leadsSlice";
import { leadAnexosSlice } from "../app/modules/View/_redux/leadAnexos/leadAnexosSlice";
import { leadEmailsSlice } from "../app/modules/View/_redux/leadEmails/leadEmailsSlice";
import { leadPropostasSlice } from "../app/modules/View/_redux/leadPropostas/leadPropostasSlice";
import { leadContatosSlice } from "../app/modules/View/_redux/leadContatos/leadContatosSlice";
import { importacaoArquivoSlice } from "../app/modules/View/_redux/importacaoArquivo/importacaoArquivoSlice";
import { perfilAcessosSlice } from "../app/modules/View/_redux/perfilAcessos/perfilAcessosSlice";
import { templatesSlice } from "../app/modules/View/_redux/templates/templatesSlice";
import { leadsV2Slice } from "../app/modules/View/_redux/leadv2/leadsV2Slice";
import { leadAnotacoesSlice } from "../app/modules/View/_redux/leadAnotacoes/leadAnotacoesSlice";
import { leadAtividadesSlice } from "../app/modules/View/_redux/leadAtividades/leadAtividadesSlice";
import { configuracoesSlice } from "../app/modules/View/_redux/configuracoes/configuracoesSlice";
import { dadosPessoalSlice } from "../app/modules/View/_redux/dadosPessoal/dadosPessoalSlice";
import { notificacoesSlice } from "../app/modules/View/_redux/notificacoes/notificacoesSlice";
import { tiposProdutoSlice } from "../app/modules/View/_redux/tiposProduto/tiposProdutoSlice";
import { configuracaoLeadCadastrosSlice } from "../app/modules/View/_redux/configuracaoLeadCadastros/configuracaoLeadCadastrosSlice";
import { produtosSlice } from "../app/modules/View/_redux/produtos/produtosSlice";
import { configurarSegmentosSlice } from "../app/modules/View/_redux/configurarSegmentos/configurarSegmentosSlice";
import { precoProdutosSlice } from "../app/modules/View/_redux/precoProdutos/precoProdutosSlice";
import { atividadesSlice } from "../app/modules/View/_redux/atividades/atividadesSlice";
import { canaisSlice } from "../app/modules/View/_redux/canais/canaisSlice";
import { parceirosSlice } from "../app/modules/View/_redux/parceiros/parceirosSlice";
import { segmentosSlice } from "../app/modules/View/_redux/segmentos/segmentosSlice";
import { statussSlice } from "../app/modules/View/_redux/statuss/statussSlice";
import { tiposOportunidadeSlice } from "../app/modules/View/_redux/tiposOportunidade/tiposOportunidadeSlice";
import { administrativosSlice } from "../app/modules/View/_redux/administrativos/administrativosSlice";
import { crmsSlice } from "../app/modules/View/_redux/crms/crmsSlice";
import { gestaoFaturasSlice } from "../app/modules/View/_redux/gestaoFaturas/gestaoFaturasSlice";
import { mercadoLivresSlice } from "../app/modules/View/_redux/mercadoLivres/mercadoLivresSlice";
import { ucsSlice } from "../app/modules/View/_redux/ucs/ucsSlice";
import { mailBoxsSlice } from "../app/modules/View/_redux/mailBoxs/mailBoxsSlice";


// import { agentesSlice } from "../app/modules/View/_redux/agentes/agentesSlice";
// import { distribuidoresSlice } from "../app/modules/View/_redux/distribuidores/distribuidoresSlice";
// import { prospeccaoClientesSlice } from "../app/modules/View/_redux/prospeccaoClientes/prospeccaoClientesSlice";
// // import { agenteRelatorioSlice } from "../app/modules/View/_redux/agenteRelatorio/agenteRelatorioSlice";
// import { usuarioAgentesSlice } from "../app/modules/View/_redux/usuariosAgente/usuarioAgentesSlice";
// import { extratoFaturasSlice } from "../app/modules/View/_redux/faturas/extratoFaturas/extratoFaturasSlice";
// import { processoFaturasSlice } from "../app/modules/View/_redux/consulta/processoFaturas/processoFaturasSlice";
// import { processoFaturasEmailSlice } from "../app/modules/View/_redux/consulta/processoFaturasEmail/processoFaturasEmailSlice";
// import { resultadoUCsSlice } from "../app/modules/View/_redux/consulta/resultadoUCs/resultadoUCsSlice";
// import { simulacaoLeadsSlice } from "../app/modules/View/_redux/simulacaoLeads/simulacaoLeadsSlice";
// import { rankingEficienciaSlice } from "../app/modules/View/_redux/faturas/rankingEficiencia/rankingEficienciaSlice";
// import { gestaoContasSlice } from "../app/modules/View/_redux/mercadolivre/gestaoContas/gestaoContasSlice";
// import { simuladorMLSlice } from "../app/modules/View/_redux/mercadolivre/simulador/simuladorMLSlice";
// import { balancosSlice } from "../app/modules/View/_redux/balancos/balancosSlice";
// import { simulacaoParametrosSlice } from "../app/modules/View/_redux/simulacaoParametros/simulacaoParametrosSlice";
// import { roboParametrosSlice } from "../app/modules/View/_redux/roboParametros/roboParametrosSlice";
// import { ucParticipantesSlice } from "../app/modules/View/_redux/ucParticipantes/ucParticipantesSlice";
// import { ucRateiosSlice } from "../app/modules/View/_redux/ucRateios/ucRateiosSlice";
// import { custosSlice } from "../app/modules/View/_redux/custos/custosSlice";
// import { deviceSlice } from "../app/modules/View/_redux/telemetria/device/deviceSlice";
// import { deviceUcSlice } from "../app/modules/View/_redux/telemetria/deviceUc/deviceUcSlice";
// import { deviceMedicaoBrutaSlice } from "../app/modules/View/_redux/telemetria/deviceMedicaoBruta/deviceMedicaoBrutaSlice";
// import { gcUcParticipantesSlice } from "../app/modules/View/_redux/gcUcParticipantes/gcUcParticipantesSlice";
// import { gcCustosSlice } from "../app/modules/View/_redux/gccustos/gcCustosSlice";
// import { gdsSlice } from "../app/modules/View/_redux/gds/gdsSlice";
// import { gcsSlice } from "../app/modules/View/_redux/gcs/gcsSlice";
// import { historicoConsumoSlice } from "../app/modules/View/_redux/faturas/historicoConsumo/historicoConsumoSlice";
// import { monitoramentoSensorSlice } from "../app/modules/View/_redux/telemetria/monitoramentoSensor/monitoramentoSensorSlice";
// import { faturaOportunidadesSlice } from "../app/modules/View/_redux/faturas/faturaOportunidades/faturaOportunidadesSlice";
// import { faturaOportunidadeDetalheSlice } from "../app/modules/View/_redux/faturas/faturaOportunidadeDetalhe/faturaOportunidadeDetalheSlice";
// import { monitoramentoOnlineSlice } from "../app/modules/View/_redux/telemetria/monitoramentoOnline/monitoramentoOnlineSlice";
// import { perfilConsumoSensorSlice } from "../app/modules/View/_redux/telemetria/perfilConsumoSensor/perfilConsumoSensorSlice";
// import { boletimFinanceirosSlice } from "../app/modules/View/_redux/boletimFinanceiros/boletimFinanceirosSlice";
// import { boletimMensalSlice } from "../app/modules/View/_redux/boletimMensal/boletimMensalSlice";
// import { fluxoCreditosSlice } from "../app/modules/View/_redux/gd/fluxoCreditos/fluxoCreditosSlice";
// import { historicoDesviosSlice } from "../app/modules/View/_redux/gd/historicoDesvios/historicoDesviosSlice";
// import { historicoRateiosSlice } from "../app/modules/View/_redux/gd/historicoRateios/historicoRateiosSlice";
// import { operacaoGDSlice } from "../app/modules/View/_redux/operacaoGD/operacaoGDSlice";
// import { compensacaoGDSlice } from "../app/modules/View/_redux/gd/compensacaoGD/compensacaoGDSlice";
// import { tarifasSlice } from "../app/modules/View/_redux/tarifas/tarifasSlice";
// import { propostasSlice } from "../app/modules/View/_redux/propostas/propostasSlice";
// import { cronsSlice } from "../app/modules/View/_redux/crons/cronsSlice";
// import { contratosSlice } from "../app/modules/View/_redux/contratos/contratosSlice";
// import { simuladoresSlice } from "../app/modules/View/_redux/simuladores/simuladoresSlice";
// import { nfesSlice } from "../app/modules/View/_redux/nfes/nfesSlice";
// import { ordensSlice } from "../app/modules/View/_redux/ordens/ordensSlice";
// import { gerenciadoArquivoFaturaSlice } from "../app/modules/View/_redux/faturas/gerenciadoArquivoFaturas/gestaoArquivoFaturasSlice";
// import { compensacaoManualEnergiaSlice } from "../app/modules/View/_redux/compensacaoManualEnergia/compensacaoManualEnergiaSlice";
// import { layoutNaoMapeadoSlice } from "../app/modules/View/_redux/faturas/layoutNaoMapeados/layoutNaoMapeadosSlice";
// import { perfilScdeClientesSlice } from "../app/modules/View/_redux/perfilScdeClientes/perfilScdeClientesSlice";
// import { calculadorasSlice } from "../app/modules/View/_redux/calculadora/calculadorasSlice";
// import { medicoesSlice } from "../app/modules/View/_redux/medicoesSCDE/medicoesSlice";
// import { dashboardSlice } from "../app/modules/View/_redux/dashboardInicio/dashboardSlice";
// import { gestaoSCDESSlice } from "../app/modules/View/_redux/gestaoSCDE/gestaoSCDESSlice";
// import { faturaFinanceirosSlice } from "../app/modules/View/_redux/faturaFinanceiro/FaturaFinanceirosSlice";
// import { comparativoTelemetriaFaturasSlice } from "../app/modules/View/_redux/comparativoTelemetriaFatura/ComparativoTelemetriaFaturasSlice";
// import { gestaoSimuladorMLSlice } from "../app/modules/View/_redux/mercadolivre/gestaoSimuladores/gestaoSimuladoresMLSlice";
// import { telemetriasSlice } from "../app/modules/View/_redux/telemetria/telemetria/telemetriasSlice";
// import { telemetriaEnergiaSlice } from "../app/modules/View/_redux/telemetria/telemetriaEnergia/telemetriaEnergiaSlice";
// import { faturaDetalheProspectsSlice } from "../app/modules/View/_redux/faturaDetalheProspect/faturaDetalheProspectSlice";
// import { tiposUCSlice } from "../app/modules/View/_redux/tiposUC/tiposUCSlice";
// import { eficienciasSlice } from "../app/modules/View/_redux/eficiencias/eficienciasSlice";
// import { processoEmailsSlice } from "../app/modules/View/_redux/processoFaturaEmails/processoEmailsSlice";
// import { processoDashboardsSlice } from "../app/modules/View/_redux/processoFaturaDashboard/processoDashboardsSlice";
// import { processoFtpsSlice } from "../app/modules/View/_redux/processoFaturaFtps/processoFtpsSlice";
// import { processoRpasSlice } from "../app/modules/View/_redux/processoFaturaRpas/processoRpasSlice";
// import { fontesEnergiaSlice } from "../app/modules/View/_redux/fontesEnergia/fontesEnergiaSlice";
// import { tiposEnergiaSlice } from "../app/modules/View/_redux/tiposEnergia/tiposEnergiaSlice";
// import { perfisCCEESlice } from "../app/modules/View/_redux/perfisCCEE/perfisCCEESlice";
// import { subMercadosSlice } from "../app/modules/View/_redux/subMercados/subMercadosSlice";
// import { contratosMlSlice } from "../app/modules/View/_redux/mercadolivre/contratosML/contratosSlice";
// import { contraPartesSlice } from "../app/modules/View/_redux/contraPartes/contraPartesSlice";
// import { glossarioFaturasSlice } from "../app/modules/View/_redux/glossarioFaturas/glossarioFaturasSlice";
// import { templateFaturasSlice } from "../app/modules/View/_redux/templateFaturas/templateFaturasSlice";
// import { camposFaturasSlice } from "../app/modules/View/_redux/camposFaturas/camposFaturasSlice";
// import { tributoPisCofinsSlice } from "../app/modules/View/_redux/tributoPisCofins/tributoPisCofinsSlice";
// import { simulacoesFaturasSlice } from "../app/modules/View/_redux/simulacoesFaturas/simulacoesFaturasSlice";
// import { obrigacoesFinanceiraSlice } from "../app/modules/View/_redux/obrigacoesFinanceira/obrigacoesFinanceiraSlice";
// import { tributosICMSSlice } from "../app/modules/View/_redux/tributosICMS/tributosICMSSlice";
// import { proinfasSlice } from "../app/modules/View/_redux/proinfas/proinfasSlice";
// import { ucsPontoMedicaoSlice } from "../app/modules/View/_redux/ucsPontoMedicao/ucsPontoMedicaoSlice";
// import { contratoUcParticipantesSlice } from "../app/modules/View/_redux/mercadolivre/contratoUcParticipantes/contratoUcParticipantesSlice";
// import { processoMedicoesSlice } from "../app/modules/View/_redux/processoMedicao/processoMedicoesSlice";
// import { processoContratosSlice } from "../app/modules/View/_redux/processoContratos/processoContratosSlice";
// import { processoCalculoBalancosSlice } from "../app/modules/View/_redux/processoCalculoBalancos/processoCalculoBalancosSlice";
// import { processoCalculoCativosSlice } from "../app/modules/View/_redux/processoCalculoCativos/processoCalculoCativosSlice";
// import { processoMedicoesGDSlice } from "../app/modules/View/_redux/processoMedicoesGD/processoMedicoesGDSlice";
// import { processoReprocessarsGDSlice } from "../app/modules/View/_redux/processoReprocessarsGD/processoReprocessarsGDSlice";





// import { balancoEnergeticoSlice } from "../app/modules/View/_redux/mercadolivre/balancoEnergetico/balancoEnergeticoSlice";
// import { contratoPerdaRedeBasicasSlice } from "../app/modules/View/_redux/mercadolivre/contratoPerdaRedeBasicas/contratoPerdaRedeBasicasSlice";
// import { contratoValorEnergiaMwmsSlice } from "../app/modules/View/_redux/mercadolivre/contratoValorEnergiaMwms/contratoValorEnergiaMwmsSlice";
// import { ucRateiosConsorciosSlice } from "../app/modules/View/_redux/ucRateiosConsorcios/ucRateiosConsorciosSlice";
// import { compensacaoGCUCsSlice } from "../app/modules/View/_redux/compensacaoGCUCs/compensacaoGCUCsSlice";
// import { reprocessarCompensacaosSlice } from "../app/modules/View/_redux/reprocessarCompensacaos/reprocessarCompensacaosSlice";
// import { uCReprocessadasSlice } from "../app/modules/View/_redux/ucReprocessadas/ucReprocessadasSlice";
// import { usuarioSuperListasSlice } from "../app/modules/View/_red/ux/usuariosSuperLista/usuarioSuperListasSlice";
// import { superListasSlice } from "../app/modules/View/_redux/superListas/superListasSlice";




// import { historicoCobrancasSlice } from "../app/modules/View/_redux/historicoCobrancas/historicoCobrancasSlice";
// import { configuracaoPagamentosSlice } from "../app/modules/View/_redux/configuracaoPagamentos/configuracaoPagamentosSlice";
// import { arquivoRemesaRetornosSlice } from "../app/modules/View/_redux/arquivoRemesaRetornos/arquivoRemesaRetornosSlice";
// import { cobrancasSlice} from "../app/modules/View/_redux/cobrancas/cobrancasSlice";

  // fontesEnergia: fontesEnergiaSlice.reducer,
  // tiposEnergia: tiposEnergiaSlice.reducer,
  // perfisCCEE: perfisCCEESlice.reducer,
  // subMercados: subMercadosSlice.reducer,
  // contratosMl: contratosMlSlice.reducer,
  // contraPartes: contraPartesSlice.reducer,
  // glossarioFaturas: glossarioFaturasSlice.reducer,
  // camposFaturas: camposFaturasSlice.reducer,
  // templateFaturas: templateFaturasSlice.reducer,
  // tributoPisCofins: tributoPisCofinsSlice.reducer,
  // simulacoesFaturas: simulacoesFaturasSlice.reducer,
  // obrigacoesFinanceira: obrigacoesFinanceiraSlice.reducer,
  // tributosICMS: tributosICMSSlice.reducer,
  // proinfas: proinfasSlice.reducer,
  // ucsPontoMedicao: ucsPontoMedicaoSlice.reducer,
  // contratoUcParticipantes: contratoUcParticipantesSlice.reducer,
  // balancoEnergetico: balancoEnergeticoSlice.reducer,
  // processoMedicoes: processoMedicoesSlice.reducer,
  // processoContratos: processoContratosSlice.reducer,
  // processoCalculoBalancos: processoCalculoBalancosSlice.reducer,
  // processoCalculoCativos: processoCalculoCativosSlice.reducer,
  // processoMedicoesGD: processoMedicoesGDSlice.reducer,
  // processoReprocessarsGD: processoReprocessarsGDSlice.reducer,
  // layoutNaoMapeado: layoutNaoMapeadoSlice.reducer,
  // perfilScdeClientes: perfilScdeClientesSlice.reducer,
  // contratoPerdaRedeBasicas: contratoPerdaRedeBasicasSlice.reducer,
  // contratoValorEnergiaMwms: contratoValorEnergiaMwmsSlice.reducer,
  // historicoCobrancas: historicoCobrancasSlice.reducer,
  // configuracaoPagamentos: configuracaoPagamentosSlice.reducer,
  // arquivoRemesaRetornos: arquivoRemesaRetornosSlice.reducer,
  // cobrancas: cobrancasSlice.reducer,
  // compensacaoGCUCs: compensacaoGCUCsSlice.reducer,
  // reprocessarCompensacaos: reprocessarCompensacaosSlice.reducer,
  // uCReprocessadas:  uCReprocessadasSlice.reducer,
  // rankingEficiencia: rankingEficienciaSlice.reducer,
  // distribuidores: distribuidoresSlice.reducer,
  // simulacaoLeads: simulacaoLeadsSlice.reducer,
  // prospeccaoclientes: prospeccaoClientesSlice.reducer,
  // simulacaoParametros: simulacaoParametrosSlice.reducer,
  // roboParametros: roboParametrosSlice.reducer,
  // contratos: contratosSlice.reducer,
  // crons: cronsSlice.reducer,
  // usuarioAgentes: usuarioAgentesSlice.reducer,
  // gcUcParticipantes: gcUcParticipantesSlice.reducer,
  // gcCustos: gcCustosSlice.reducer,
  // faturaOportunidadeDetalhe: faturaOportunidadeDetalheSlice.reducer,
  // compensacaoManualEnergia: compensacaoManualEnergiaSlice.reducer,
  // calculadoras: calculadorasSlice.reducer,
  // device: deviceSlice.reducer,
  // deviceUc: deviceUcSlice.reducer,
  // deviceMedicaoBruta: deviceMedicaoBrutaSlice.reducer,
  // superListas: superListasSlice.reducer,
  // usuarioSuperListas: usuarioSuperListasSlice.reducer,
  // ucRateiosConsorcios: ucRateiosConsorciosSlice.reducer,
  // gestaoContas: gestaoContasSlice.reducer,
  // balancos: balancosSlice.reducer,
  // simuladores: simuladoresSlice.reducer,
  // mailBoxs: mailBoxsSlice.reducer,
  // extratoFaturas: extratoFaturasSlice.reducer,
  // monitoramentoOnlines: monitoramentoOnlineSlice.reducer,
  // monitoramentoSensores: monitoramentoSensorSlice.reducer,
  // ordens: ordensSlice.reducer,
  // faturaOportunidades: faturaOportunidadesSlice.reducer,
  // perfilConsumoSensores: perfilConsumoSensorSlice.reducer,
  // processoFaturas: processoFaturasSlice.reducer,
  // processoFaturasEmail: processoFaturasEmailSlice.reducer,
  // boletimFinanceiros: boletimFinanceirosSlice.reducer,
  // boletimMensal: boletimMensalSlice.reducer,
  // operacaoGD: operacaoGDSlice.reducer,
  // historicoDesvios: historicoDesviosSlice.reducer,
  // historicoRateios: historicoRateiosSlice.reducer,
  // fluxoCreditos: fluxoCreditosSlice.reducer,
  // historicoConsumo: historicoConsumoSlice.reducer,
  // ucParticipantes: ucParticipantesSlice.reducer,
  // ucRateios: ucRateiosSlice.reducer,
  // custos: custosSlice.reducer,
  // gds: gdsSlice.reducer,
  // gcs: gcsSlice.reducer,
  // tarifas: tarifasSlice.reducer,
  // compensacaoGDes: compensacaoGDSlice.reducer,
  // resultadoUCs: resultadoUCsSlice.reducer,
  // propostas: propostasSlice.reducer,
  // crons: cronsSlice.reducer,
  // gerenciadoArquivoFaturas: gerenciadoArquivoFaturaSlice.reducer,
  // simuladorML: simuladorMLSlice.reducer,
  // nfes: nfesSlice.reducer,
  // medicoes: medicoesSlice.reducer,
  // dashboard: dashboardSlice.reducer,
  // gestaoSCDES: gestaoSCDESSlice.reducer,
  // faturaFinanceiros: faturaFinanceirosSlice.reducer,
  // comparativoTelemetriaFaturas: comparativoTelemetriaFaturasSlice.reducer,
  // gestaoSimuladores: gestaoSimuladorMLSlice.reducer,
  // telemetrias: telemetriasSlice.reducer,
  // telemetriaEnergia: telemetriaEnergiaSlice.reducer,
  // faturaDetalheProspects: faturaDetalheProspectsSlice.reducer,
  // tiposUC: tiposUCSlice.reducer,
  // eficiencias: eficienciasSlice.reducer,

  // processoEmails: processoEmailsSlice.reducer,
  // processoDashboards: processoDashboardsSlice.reducer,
  // processoRpas: processoRpasSlice.reducer,
  // processoFtps: processoFtpsSlice.reducer,

export const rootReducer = combineReducers({
  auth: auth.reducer,
  anexosLead: anexosLeadSlice.reducer,
  anotacoesLead: anotacoesLeadSlice.reducer,
  leads: leadsSlice.reducer,
  leadsV2: leadsV2Slice.reducer,
  leadAnotacoes: leadAnotacoesSlice.reducer,
  leadAtividades: leadAtividadesSlice.reducer,
  leadAnexos: leadAnexosSlice.reducer,
  leadEmails: leadEmailsSlice.reducer,
  leadPropostas: leadPropostasSlice.reducer,
  leadContatos: leadContatosSlice.reducer,
  configuracaoLeadCadastros: configuracaoLeadCadastrosSlice.reducer,
  ucs: ucsSlice.reducer,

  clientes: clientesSlice.reducer,
  usinas: usinasSlice.reducer,
  crm: crmSlice.reducer,
  perfilAcessos: perfilAcessosSlice.reducer,
  usuarios: usuariosSlice.reducer,
  templates: templatesSlice.reducer,
  acessos: acessosSlice.reducer,
  importacaoArquivo: importacaoArquivoSlice.reducer,
  usuarioClientes: usuarioClientesSlice.reducer,
  precoProdutos: precoProdutosSlice.reducer,
  templates: templatesSlice.reducer,
  configuracoes: configuracoesSlice.reducer,
  dadosPessoal: dadosPessoalSlice.reducer,
  notificacoes: notificacoesSlice.reducer,
  parceiros: parceirosSlice.reducer,
  canais: canaisSlice.reducer,
  statuss: statussSlice.reducer,
  tiposOportunidade: tiposOportunidadeSlice.reducer,
  atividades: atividadesSlice.reducer,
  tiposProduto: tiposProdutoSlice.reducer,
  configurarSegmentos: configurarSegmentosSlice.reducer,
  segmentos: segmentosSlice.reducer,
  produtos: produtosSlice.reducer,
  administrativos: administrativosSlice.reducer,
  crms: crmsSlice.reducer,
  gestaoFaturas: gestaoFaturasSlice.reducer,
  mercadoLivres: mercadoLivresSlice.reducer,
  mailBoxs: mailBoxsSlice.reducer, 
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
