import { createSlice } from "@reduxjs/toolkit";
const initialLeadAnotacoesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  leadAnotacaoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const leadAnotacoesSlice = createSlice({
  name: "leadAnotacoes",
  initialState: initialLeadAnotacoesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getAnotacaoLeadById
    leadAnotacaoFetched: (state, action) => {
      state.listLoading = false;
      state.leadAnotacaoForEdit = action.payload.leadAnotacaoForEdit;
      state.error = null;
    },
    // findLeadAnotacoes
    leadAnotacoesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createAnotacaoLead
    leadAnotacaoCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      // state.entities.push(action.payload.leadAnotacao);
    },

  }
});
