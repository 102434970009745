import * as requestFromServer from "./gdsCrud";
import { gdsSlice, callTypes } from "./gdsSlice";

const { actions } = gdsSlice;

export const fetchGds = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findGds(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.gdsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find gds";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchExcelBoletim = queryParams => dispatch => {
  // dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .exportExcelBoletim(queryParams)
    .then(response => {
      const { entities } = response.data;
      // dispatch(actions.gdsFetched({ entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find gds";
      //     dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchGdCliente = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchGdCliente(id)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.gdsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find gds";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchGdClienteUsina = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchGdClienteUsina(id)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.gdsFetchedUsina({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find gds";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchGd = id => dispatch => {

  if (!id) {
    return dispatch(actions.gdFetched({ gdForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGdById(id)
    .then(response => {
      const gd = response.data.entities.resultado;
      dispatch(actions.gdFetched({ gdForEdit: gd }));
    })
    .catch(error => {
      error.clientMessage = "Can't find gd";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGd = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGd(id)
    .then(response => {
      dispatch(actions.gdDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete gd";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGd = gdForCreation => dispatch => {

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createGd(gdForCreation)
    .then(response => {
      const gd = response.data.entities;
      dispatch(actions.gdCreated({ gd: gd }));
    })
    .catch(error => {
      error.clientMessage = "Can't create gd";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGd = (values) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGd(values)
    .then(() => {
      dispatch(actions.gdUpdated({ values }));
    })
    .catch(error => {
      error.clientMessage = "Can't update gd";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGdsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForGds(ids, status)
    .then(() => {
      dispatch(actions.gdsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update gds status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGds = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGds(ids)
    .then(() => {
      dispatch(actions.gdsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete gds";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const ObterGDData = (id, data) => dispatch => {
  if (!id) {
    return dispatch(actions.gdDataFetched({ gdForEditFiltro: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .ObterGDData(id, data)
    .then(response => {
      const gd = response.data.entities.resultado;
      dispatch(actions.gdDataFetched({ gdForEditFiltro: gd }));
    })
    .catch(error => {
      error.clientMessage = "Can't find gds";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};