import { createSlice } from "@reduxjs/toolkit";

const initialNotificacoesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  notificacaoForEdit: undefined,
  lastError: null,
  notificacaoListaDuplaForEdit: undefined,
  notificacao: undefined
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const notificacoesSlice = createSlice({
  name: "notificacoes",
  initialState: initialNotificacoesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callTypes === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callTypes === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // Obter
    notificacaoFetched: (state, action) => {
      state.listLoading = false;
      state.notificacaoForEdit =
        action.payload.notificacaoForEdit;
      state.error = null;
    },
    // Paginar
    notificacoesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      // state.entities = entities;
      state.notificacao = entities;
      state.totalCount = totalCount;
    },
    // Criar
    notificacaoCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      // console.log(action);
      state.entities.push(action.payload.notificacao);
    },
    // Editar
    notificacaoUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (
          entity.idObrigatoriedadeAgente ===
          action.payload.notificacao.idObrigatoriedadeAgente
        ) {
          return action.payload.notificacao;
        }
        return entity;
      });
    },
    // Excluir
    notificacaoDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el.id === action.payload.id);
    },
    //Obter Lista Dupla
    notificacaoFetchedListaDupla: (state, action) => {
      state.listLoading = false;
      state.notificacaoListaDuplaForEdit = action.payload.notificacaoListaDuplaForEdit;
      // state.nomeFantasia = action.payload.nomeFantasiaAgente
      state.error = null;
    },
  }
});
