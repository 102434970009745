import axios from "axios";
import { config } from "./../../../../Config";
export const PRODUCTS_URL = config.url.apiEndPoint + "/dashboard";
// CREATE =>  POST: add a new dashboard to the server

export function ObterGestaoFaturaMensal(queryParams,MesFatura) {
  const request = {
    method: 'GET',
    url: PRODUCTS_URL + `/obterGestaoFaturaMensal/` + queryParams + `/` + MesFatura,
    headers: {
      'Content-Type': 'application/json'
    },
  };
  return axios(request);
}

export function ObterGestaoFaturaAnual(queryParams) {
  const request = {
    method: 'GET',
    url: PRODUCTS_URL + `/obterGestaoFaturaAnual/` + queryParams,
    headers: {
      'Content-Type': 'application/json'
    },
  };
  return axios(request);
}

export function ObterProcessamentoFatura(queryParams) {
  const request = {
    method: 'PUT',
    url: PRODUCTS_URL+`/statusFatura`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: queryParams,
  };

  return axios(request);
}
