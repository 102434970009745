import React from "react";
import { useField, useFormikContext } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  return classes.join(" ");
};

export function EnergiaKWh({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      <div>
        {label && (
          <label
            htmlFor={label}
            className="text-gray-500 text-sm font-semibold"
          >
            {" "}
            {label}{" "}
          </label>
        )}
        <div>
          <NumberFormat
            suffix={" KWh"}
            decimalSeparator=","
            thousandSeparator="."
            value={field.value}
            onValueChange={val => {
              form.setFieldValue(field.name, val.floatValue);
            }}
            // className={getFieldCSSClasses(form.touched[field.name], form.errors[field.name])}
            className={
              "w-full h-11 pl-3 pr-6 mt-2 text-sm font-regular text-gray-600 border rounded-md border-gray-300 hover:bg-gray-50 focus:outline-none focus:border-mainBlue placeholder:text-gray-400 "
            }
            {...props}
          />
          {withFeedbackLabel && form.errors[field.name] && (
            <FieldFeedbackLabel
              error={form.errors[field.name]}
              touched={form.touched[field.name]}
              label={label}
              type={type}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </div>
      </div>
    </>
  );
}
