import React, { useMemo } from "react";
import objectPath from "object-path";
// LayoutContext
import Header from "../../../app/modules/components/Header";
import Sidebar from "../../../app/modules/components/Sidebar";

export function Layout({ children }) {

  return (
    <>
      <div className="md:pl-64 flex flex-col">
        <Header />
        {children}
      </div>
      <Sidebar />
    </>
  );


}
