import React from "react";
import { PaginationLinks } from "./PaginationLinks";
import { PaginationToolbar } from "./PaginationToolbar";

export function Pagination(props) {
  const { children, isLoading, paginationProps } = props;
  return (
    <>
      {children}
      <div className="flex flex-wrap justify-between items-center mt-5">
        <PaginationToolbar
          isLoading={isLoading}
          paginationProps={paginationProps}
        />
        <PaginationLinks paginationProps={paginationProps} />
      </div>
    </>
  );
}
