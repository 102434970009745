import { createSlice } from "@reduxjs/toolkit";
const initialLeadAtividadesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  leadAtividadeForEdit: undefined,
  lastError: null,
  leadAtividadePendenteForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const leadAtividadesSlice = createSlice({
  name: "leadAtividades",
  initialState: initialLeadAtividadesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getAtividadeLeadById
    leadAtividadeFetched: (state, action) => {
      state.listLoading = false;
      state.leadAtividadeForEdit = action.payload.leadAtividadeForEdit;
      state.error = null;
    },
    // findLeadAtividades
    leadAtividadesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    leadAtividadesPendenteFetched: (state, action) => {
      state.listLoading = false;
      state.leadAtividadePendenteForEdit = action.payload.atividadePendenteLead;
      state.error = null;
    },

    // createAtividadeLead
    leadAtividadeCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      // state.entities.push(action.payload.leadAtividade);
    },

  }
});
