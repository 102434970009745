
import React from "react";


export function StatusFormatacao(
    cellContent,
    row,
    rowIndex
) {
    const tamanho = 60;
    
    return (
        <>
            {cellContent && cellContent == "P" &&
                (<>
                    <text >Pendente</text>
                </>)
            }
            {cellContent && cellContent == "E" &&
                (<>
                    <text >Erro</text>
                </>)
            }
            {cellContent && cellContent == "R" &&
                (<>
                    <text >Processado</text>
                </>)
            }


        </>
    );
}
