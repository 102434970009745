import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialUsinasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  usinaForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const usinasSlice = createSlice({
  name: "usinas",
  initialState: initialUsinasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getUsinaById
    usinaFetched: (state, action) => {
      state.listLoading = false;
      // APLICAR A MASCARA
      if (action.payload.usinaForEdit && action.payload.usinaForEdit.idUsina) {
        action.payload.usinaForEdit.endCEPUsina = aplicarMascaraCEP(action.payload.usinaForEdit.endCEPUsina);
      }
      state.usinaForEdit = action.payload.usinaForEdit;
      state.error = null;
    },
    // findUsinas
    usinasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createUsina
    usinaCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities.push(action.payload.usina);
    },
    // updateUsina
    usinaUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.usina.id) {
          return action.payload.usina;
        }
        return entity;
      });
    },
    // deleteUsina
    usinaDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteUsinas
    usinasDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // usinasUpdateState
    usinasStatusUpdated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
