import axios from "axios";
import { config } from "../Config";
import moment from "moment";
export const _URL = config.url.apiEndPoint;

export function getPermissaoAcessos(id) {
  var _id = 0;
  if (id != null) _id = id;
  return axios.get(_URL + `/permissaoAcesso/obter/` + _id);
}

export function getCEPEnderecoCompleto(cep) {
  return axios.get(_URL + `/cep/log/${cep}`);
}
export function getSelectTipoProduto1(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/TipoProduto`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': 0, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.idTipoProduto, 'label': item.nomeTipoProduto });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.idTipoProduto, 'label': item.nomeTipoProduto });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectClassificacaoAgente(incluirAplicarTodos, incluirOpcaoVazia) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': '1', valor: 'Agente 1' });
  situcaoValores.push({ 'id': '2', valor: 'Agente 2' });
  return situcaoValores;
};
export function getSelectDistribuidoraGC(idGC, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/distribuidoragc/` + idGC);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idDistribuidora': null, 'nomeDistribuidora': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idDistribuidora': null, 'nomeDistribuidora': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idDistribuidora': item.idDistribuidora, 'nomeDistribuidora': item.nomeDistribuidora });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idDistribuidora': item.idDistribuidora, 'nomeDistribuidora': item.nomeDistribuidora });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectDistribuidoraUsina(idGC, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/distribuidoraUsinaGC/` + idGC);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idUsina': null, 'nomeUsina': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idUsina': null, 'nomeUsina': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idUsina': item.idUsina, 'nomeUsina': item.nomeUsina, 'geracaoEstimadaUsina': item.geracaoEstimadaUsina });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idUsina': item.idUsina, 'nomeUsina': item.nomeUsina, 'geracaoEstimadaUsina': item.geracaoEstimadaUsina });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectTipoGestao1(incluirAplicarTodos, incluirOpcaoVazia) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': 0, valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  // situcaoValores.push({ 'id': 'A', valor: 'Auto Consumo' });
  situcaoValores.push({ 'id': 'C', valor: 'Consórcio' });
  // situcaoValores.push({ 'id': 'O', valor: 'Cooperativa' });
  return situcaoValores;
};
export function getSelectUsinaGC(idGD, idDistribuidora, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/usinagc/` + idGD + '/' + idDistribuidora);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idUsina': null, 'nomeUsina': 'Todos', 'nomeDistribuidora': '' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idUsina': null, 'nomeUsina': '', 'nomeDistribuidora': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idUsina': item.idUsina, 'nomeUsina': item.nomeUsina, 'nomeDistribuidora': item.nomeDistribuidora });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idUsina': item.idUsina, 'nomeUsina': item.nomeUsina, 'nomeDistribuidora': item.nomeDistribuidora });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};
export function getSelectUsina(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/usina`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idUsina': 0, 'nomeUsina': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idUsina': null, 'nomeUsina': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idUsina': parseInt(item.idUsina), 'nomeUsina': item.nomeUsina });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idUsina': parseInt(item.idUsina), 'nomeUsina': item.nomeUsina });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectUsinaContratoML(incluirAplicarTodos, incluirOpcaoVazia, incluirContratoML) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/usina`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idUsina': 0, 'nomeUsina': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idUsina': null, 'nomeUsina': '' });
    }
    if (incluirContratoML) {
      situcaoValores.push({ 'idUsina': null, 'nomeUsina': 'Usina' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idUsina': parseInt(item.idUsina), 'nomeUsina': item.nomeUsina });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idUsina': parseInt(item.idUsina), 'nomeUsina': item.nomeUsina });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectSubMercado(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/subMercado`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idSubmercado': 0, 'submercado': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idSubmercado': null, 'submercado': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idSubmercado': parseInt(item.idSubmercado), 'submercado': item.submercado });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idSubmercado': parseInt(item.idSubmercado), 'submercado': item.submercado });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectTipoEnergia(incluirAplicarTodos, incluirOpcaoVazia, incluirUsina) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/tipoEnergia`);
    const situcaoValores = [];
    if (incluirUsina) {
      situcaoValores.push({ 'idTipoEnergia': '', 'tipoEnergia': '' });
    }
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idTipoEnergia': 0, 'tipoEnergia': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idTipoEnergia': null, 'tipoEnergia': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idTipoEnergia': parseInt(item.idTipoEnergia), 'tipoEnergia': item.tipoEnergia });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idTipoEnergia': parseInt(item.idTipoEnergia), 'tipoEnergia': item.tipoEnergia });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectContraParte(incluirAplicarTodos, incluirOpcaoVazia, incluirContraParte) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/contraParte`);
    const situcaoValores = [];
    if (incluirContraParte) {
      situcaoValores.push({ 'idContraParte': null, 'nomeContraParte': 'Contraparte' });
    }
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idContraParte': 0, 'nomeContraParte': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idContraParte': null, 'nomeContraParte': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idContraParte': parseInt(item.idContraParte), 'nomeContraParte': item.nomeContraParte, 'informaPerdasMensal': item.informaPerdasMensal });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idContraParte': parseInt(item.idContraParte), 'nomeContraParte': item.nomeContraParte, 'informaPerdasMensal': item.informaPerdasMensal });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectDistribuidora(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/distribuidora`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idDistribuidora': ' ', 'nomeDistribuidora': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idDistribuidora': null, 'nomeDistribuidora': 'Selecione' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idDistribuidora': item.idDistribuidora, 'nomeDistribuidora': item.nomeDistribuidora });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idDistribuidora': item.idDistribuidora, 'nomeDistribuidora': item.nomeDistribuidora });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectCampoFaturas(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/camposFaturas`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idEstudioFaturaCampos': ' ', 'campoFatura': 'Todos', 'tipoCampo': '' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idEstudioFaturaCampos': null, 'campoFatura': 'Selecione', 'tipoCampo': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idEstudioFaturaCampos': item.idEstudioFaturaCampos, 'campoFatura': item.campoFatura, 'tipoCampo': item.tipoCampo });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idEstudioFaturaCampos': item.idEstudioFaturaCampos, 'campoFatura': item.campoFatura, 'tipoCampo': item.tipoCampo });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectDistribuidoraTarifa(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/distribuidora`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idDistribuidora': ' ', 'nomeDistribuidora': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idDistribuidora': null, 'nomeDistribuidora': 'Selecione uma distribuidora' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idDistribuidora': item.idDistribuidora, 'nomeDistribuidora': item.nomeDistribuidora });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idDistribuidora': item.idDistribuidora, 'nomeDistribuidora': item.nomeDistribuidora });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectBandeiraTarifaria(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/bandeiraTarifaria`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idBandeira': ' ', 'bandeira': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idBandeira': null, 'bandeira': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idBandeira': item.bandeira, 'bandeira': item.nomeBandeira });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idBandeira': item.bandeira, 'bandeira': item.nomeBandeira });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectClienteUsina(idCliente, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/clienteUsina/` + idCliente);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idUsina': null, 'nomeUsina': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idUsina': null, 'nomeUsina': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idUsina': item.idUsina, 'nomeUsina': item.nomeUsina, 'geracaoEstimadaUsina': item.geracaoEstimadaUsina });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idUsina': item.idUsina, 'nomeUsina': item.nomeUsina, 'geracaoEstimadaUsina': item.geracaoEstimadaUsina });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectPerfilAcesso(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/perfilacesso`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': 0, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.idPerfilAcesso, 'label': item.perfilAcesso });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.idPerfilAcesso, 'label': item.perfilAcesso });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectConsorcio(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/gc`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': null, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.idGestaoConsorcio, 'label': item.nomeGestaoConsorcio });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.idGestaoConsorcio, 'label': item.nomeGestaoConsorcio });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectGetway(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/gateway`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': null, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.idGCGateway, 'label': item.nomeGateway });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.idGCGateway, 'label': item.nomeGateway });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};



export function getSelectGetwayArquivo(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/gatewayArquivo`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': null, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.idGCGateway, 'label': item.nomeGateway });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.idGCGateway, 'label': item.nomeGateway });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectTipoPagamento(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/tipoPagamento`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': null, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.idGCTipoPagamento, 'label': item.nomeTipoPagamento });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.idGCTipoPagamento, 'label': item.nomeTipoPagamento });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectClienteComFaturas(incluirAplicarTodos, incluirOpcaoVazia, incluirOpcaoSelecione) {
  return new Promise((resolve, reject) => {
    var id = localStorage.getItem("@plataforma/user") == null ? 0 : JSON.parse(localStorage.getItem("@plataforma/user")).idCliente;
    const valores = axios.get(_URL + `/select/cliente/comFaturas/` + id);
    const situcaoValores = [];
    // if (incluirAplicarTodos) {
    //     situcaoValores.push({ 'idCliente': 0, 'nomeCliente': 'Todos' });
    // }
    if (incluirOpcaoSelecione) {
      situcaoValores.push({ 'idCliente': null, 'nomeCliente': 'Selecione' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idCliente': null, 'nomeCliente': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idCliente': item.idCliente, 'nomeCliente': item.nomeCliente });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idCliente': item.idCliente, 'nomeCliente': item.nomeCliente });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};
export function getSelectClienteUCComFaturas(idCliente, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/uCCliente/comFaturas/` + idCliente);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idUc': 0, 'nomeUc': 'Selecione' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idUc': null, 'nomeUc': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idUc': item.idUc, 'nomeUc': item.nomeUc });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idUc': item.idUc, 'nomeUc': item.nomeUc });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectEstudioFaturaCampos(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/camposFaturas`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idEstudioFaturaCampos': null, 'campoFatura': 'Todos', 'tipoCampo': null });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idEstudioFaturaCampos': null, 'campoFatura': 'Selecione', 'tipoCampo': null });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idEstudioFaturaCampos': item.idEstudioFaturaCampos, 'campoFatura': item.campoFatura, 'tipoCampo': item.tipoCampo });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idEstudioFaturaCampos': item.idEstudioFaturaCampos, 'campoFatura': item.campoFatura, 'tipoCampo': item.tipoCampo });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectProspeccaoCliente(incluirAplicarTodos, incluirOpcaoVazia, idSelecionado) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/prospeccaoCliente`);
    const situcaoValores = [];
    // if (incluirAplicarTodos) {
    //     situcaoValores.push({ 'idCliente': 0, 'nomeCliente': 'Todos' });
    // }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idProspeccaoCliente': null, 'nomeProspeccaoCliente': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idProspeccaoCliente': item.idProspeccaoCliente, 'nomeProspeccaoCliente': item.nomeProspeccaoCliente });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idProspeccaoCliente': item.idProspeccaoCliente, 'nomeProspeccaoCliente': item.nomeProspeccaoCliente });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};
export function getSelectCliente(incluirAplicarTodos, incluirOpcaoVazia) {
  var id = localStorage.getItem("@plataforma/user") == null ? 0 : JSON.parse(localStorage.getItem("@plataforma/user")).idCliente;
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/cliente/filtro/` + id);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idCliente': null, 'nomeCliente': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idCliente': null, 'nomeCliente': 'Selecione o cliente' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idCliente': item.idCliente, 'nomeCliente': item.nomeCliente });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idCliente': item.idCliente, 'nomeCliente': item.nomeCliente });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectGdCliente(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/gdcliente`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idCliente': null, 'nomeCliente': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idCliente': null, 'nomeCliente': 'Selecione o cliente' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idCliente': item.idCliente, 'nomeCliente': item.nomeCliente });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idCliente': item.idCliente, 'nomeCliente': item.nomeCliente });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};
export function getSelectClienteGD(idGD, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/clientegd/` + idGD);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idCliente': null, 'nomeCliente': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idCliente': null, 'nomeCliente': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idCliente': item.idCliente, 'nomeCliente': item.nomeCliente });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idCliente': item.idCliente, 'nomeCliente': item.nomeCliente });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectClienteUC(idCliente, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/uCCliente/` + idCliente);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idUc': null, 'nomeUc': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idUc': null, 'nomeUc': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idUc': item.idUc, 'nomeUc': item.nomeUc });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idUc': item.idUc, 'nomeUc': item.nomeUc });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectUCFaturaData(idUC, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/ucFaturaData/` + idUC);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idFatura': 0, 'dataFatura': 'Selecione' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idFatura': null, 'dataFatura': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idFatura': item.idFatura, 'dataFatura': item.dataFatura });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idFatura': item.idFatura, 'dataFatura': item.dataFatura });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectSituacaoGeral(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Ativo' });
  situcaoValores.push({ 'id': 'I', valor: 'Inativo' });
  return situcaoValores;
};

export function getSelectSituacaoPerfilSCDE(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: '' });
  }
  situcaoValores.push({ 'id': 'P', valor: 'Ativo' });
  situcaoValores.push({ 'id': 'I', valor: 'Inativo' });
  return situcaoValores;
};

export function getSelectSituacaoMedicao(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'F', valor: 'Horas completa' });
  situcaoValores.push({ 'id': 'P', valor: 'Horas faltantes' });
  return situcaoValores;
};
export function getSelectSituacaoProcessamentoBalanco(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'S', valor: 'Processado' });
  situcaoValores.push({ 'id': 'N', valor: 'Pendente' });
  situcaoValores.push({ 'id': 'E', valor: 'Erro - Não existe contrato' });
  return situcaoValores;
};
export function getSelectTipoMercado(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'C', valor: 'Cativo' });
  situcaoValores.push({ 'id': 'L', valor: 'Mercado Livre' });
  return situcaoValores;
};
export function getSelectTipoTarifario(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'V', valor: 'Verde' });
  situcaoValores.push({ 'id': 'A', valor: 'Azul' });
  return situcaoValores;
};

export function getSelectEficiencia(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  // if (incluirEmBranco) {
  //     situcaoValores.push({ 'id': null, valor: '' });
  // }
  situcaoValores.push({ 'id': 'A', valor: 'A' });
  situcaoValores.push({ 'id': 'B', valor: 'B' });
  situcaoValores.push({ 'id': 'C', valor: 'C' });
  situcaoValores.push({ 'id': 'D', valor: 'D' });
  situcaoValores.push({ 'id': 'E', valor: 'E' });
  situcaoValores.push({ 'id': 'F', valor: 'F' });
  situcaoValores.push({ 'id': 'G', valor: 'G' });
  situcaoValores.push({ 'id': 'H', valor: 'H' });
  situcaoValores.push({ 'id': 'I', valor: 'I' });
  situcaoValores.push({ 'id': 'J', valor: 'J' });
  situcaoValores.push({ 'id': 'K', valor: 'K' });
  situcaoValores.push({ 'id': 'L', valor: 'L' });
  situcaoValores.push({ 'id': 'M', valor: 'M' });
  situcaoValores.push({ 'id': 'N', valor: 'N' });
  situcaoValores.push({ 'id': 'O', valor: 'O' });
  situcaoValores.push({ 'id': 'P', valor: 'P' });
  situcaoValores.push({ 'id': 'Q', valor: 'Q' });
  situcaoValores.push({ 'id': 'R', valor: 'R' });
  situcaoValores.push({ 'id': 'S', valor: 'S' });
  situcaoValores.push({ 'id': 'T', valor: 'T' });
  situcaoValores.push({ 'id': 'U', valor: 'U' });
  situcaoValores.push({ 'id': 'V', valor: 'V' });
  situcaoValores.push({ 'id': 'W', valor: 'W' });
  situcaoValores.push({ 'id': 'X', valor: 'X' });
  situcaoValores.push({ 'id': 'Y', valor: 'Y' });
  situcaoValores.push({ 'id': 'Z', valor: 'Z' });
  return situcaoValores;
};

export function getSelectTipoFatura(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Cativo Grupo A' });
  situcaoValores.push({ 'id': 'B', valor: 'Cativo Grupo B' });
  situcaoValores.push({ 'id': 'M', valor: 'Mercado Livre' });
  return situcaoValores;
};

export function getSelectStatusImportacao(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'N', valor: 'Não PDF' });
  situcaoValores.push({ 'id': 'L', valor: 'Layout não mapeado' });
  situcaoValores.push({ 'id': 'F', valor: 'Na Fila' });
  situcaoValores.push({ 'id': 'I', valor: 'Inativo' });
  return situcaoValores;
};

export function getSelectOrigemArquivo(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'E', valor: 'E-mail' });
  situcaoValores.push({ 'id': 'P', valor: 'Pasta' });
  situcaoValores.push({ 'id': 'A', valor: 'API' });
  situcaoValores.push({ 'id': 'U', valor: 'Upload' });
  situcaoValores.push({ 'id': 'D', valor: 'Portal Distribuidora' });
  return situcaoValores;
};

export function getSelectProcessamento(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'G', valor: 'BOT' });
  situcaoValores.push({ 'id': 'E', valor: 'Email' });
  situcaoValores.push({ 'id': 'M', valor: 'Manual' });
  situcaoValores.push({ 'id': 'M', valor: 'Planilha' });
  situcaoValores.push({ 'id': 'D', valor: 'Diretório de arquivo' });
  situcaoValores.push({ 'id': 'A', valor: 'API' });
  situcaoValores.push({ 'id': 'B', valor: 'BOT' });
  return situcaoValores;
};

export function getSelectSubGrupo(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': ' ', nome: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, nome: ' ' });
  }
  situcaoValores.push({ 'id': 'A1', nome: 'A1' });
  situcaoValores.push({ 'id': 'A2', nome: 'A2' });
  situcaoValores.push({ 'id': 'A3', nome: 'A3' });
  situcaoValores.push({ 'id': 'A3a', nome: 'A3a' });
  situcaoValores.push({ 'id': 'A4', nome: 'A4' });
  situcaoValores.push({ 'id': 'AS', nome: 'AS' });
  situcaoValores.push({ 'id': 'B1', nome: 'B1' });
  situcaoValores.push({ 'id': 'B2', nome: 'B2' });
  situcaoValores.push({ 'id': 'B3', nome: 'B3' });
  situcaoValores.push({ 'id': 'B4', nome: 'B4' });
  return situcaoValores;
};
export function getSelectTipoArquivoImportacaoSuperLista(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'S', valor: 'Super Lista' });

  return situcaoValores;
};
export function getSelectTipoArquivoImportacao(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'C', valor: 'Planilha de Clientes e UCs' });
  situcaoValores.push({ 'id': 'R', valor: 'Planilha de Rateio GD' });
  situcaoValores.push({ 'id': 'D', valor: 'Planilha de Custos GD' });
  situcaoValores.push({ 'id': 'P', valor: 'Planilha de ProInfa SCDE' });
  situcaoValores.push({ 'id': 'F', valor: 'Planilha de Faturas' });
  situcaoValores.push({ 'id': 'G', valor: 'Planilha de Geração de Usinas' });
  situcaoValores.push({ 'id': 'M', valor: 'Planilha de Medições SCDE' });
  situcaoValores.push({ 'id': 'T', valor: 'Planilha de Medições Telemetria devices' });
  situcaoValores.push({ 'id': 'A', valor: 'Planilha de Usuários de acesso' });
  situcaoValores.push({ 'id': 'I', valor: 'Planilha de Pré-Setup e Configuração' });

  return situcaoValores;
};
export function getSelectSituacaoImportacao(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': ' ', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'P', valor: 'Pendente' });
  situcaoValores.push({ 'id': 'E', valor: 'Erro na importação' });
  situcaoValores.push({ 'id': 'R', valor: 'Importação concluída' });
  return situcaoValores;
};

export function getSelectMesAno(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 1, 'valor': '01/2020', 'valorInicial': '2020-01-01 00:00:00', 'valorFinal': '2020-02-01 00:00:00' });
  situcaoValores.push({ 'id': 2, 'valor': '02/2020', 'valorInicial': '2020-02-01 00:00:00', 'valorFinal': '2020-03-01 00:00:00' });
  situcaoValores.push({ 'id': 3, 'valor': '03/2020', 'valorInicial': '2020-03-01 00:00:00', 'valorFinal': '2020-04-01 00:00:00' });
  situcaoValores.push({ 'id': 4, 'valor': '04/2020', 'valorInicial': '2020-04-01 00:00:00', 'valorFinal': '2020-05-01 00:00:00' });
  situcaoValores.push({ 'id': 5, 'valor': '05/2020', 'valorInicial': '2020-05-01 00:00:00', 'valorFinal': '2020-06-01 00:00:00' });
  situcaoValores.push({ 'id': 6, 'valor': '06/2020', 'valorInicial': '2020-06-01 00:00:00', 'valorFinal': '2020-07-01 00:00:00' });
  situcaoValores.push({ 'id': 7, 'valor': '07/2020', 'valorInicial': '2020-07-01 00:00:00', 'valorFinal': '2020-08-01 00:00:00' });
  situcaoValores.push({ 'id': 8, 'valor': '08/2020', 'valorInicial': '2020-08-01 00:00:00', 'valorFinal': '2020-09-01 00:00:00' });
  situcaoValores.push({ 'id': 9, 'valor': '09/2020', 'valorInicial': '2020-09-01 00:00:00', 'valorFinal': '2020-10-01 00:00:00' });
  situcaoValores.push({ 'id': 10, 'valor': '10/2020', 'valorInicial': '2020-10-01 00:00:00', 'valorFinal': '2020-11-01 00:00:00' });
  situcaoValores.push({ 'id': 11, 'valor': '11/2020', 'valorInicial': '2020-11-01 00:00:00', 'valorFinal': '2020-12-01 00:00:00' });
  situcaoValores.push({ 'id': 12, 'valor': '12/2020', 'valorInicial': '2020-12-01 00:00:00', 'valorFinal': '2021-01-01 00:00:00' });
  situcaoValores.push({ 'id': 13, 'valor': '01/2021', 'valorInicial': '2021-01-01 00:00:00', 'valorFinal': '2021-02-01 00:00:00' });
  situcaoValores.push({ 'id': 14, 'valor': '02/2021', 'valorInicial': '2021-02-01 00:00:00', 'valorFinal': '2021-03-01 00:00:00' });
  situcaoValores.push({ 'id': 15, 'valor': '03/2021', 'valorInicial': '2021-03-01 00:00:00', 'valorFinal': '2021-04-01 00:00:00' });
  situcaoValores.push({ 'id': 16, 'valor': '04/2021', 'valorInicial': '2021-04-01 00:00:00', 'valorFinal': '2021-05-01 00:00:00' });
  situcaoValores.push({ 'id': 17, 'valor': '05/2021', 'valorInicial': '2021-05-01 00:00:00', 'valorFinal': '2021-06-01 00:00:00' });
  situcaoValores.push({ 'id': 18, 'valor': '06/2021', 'valorInicial': '2021-06-01 00:00:00', 'valorFinal': '2021-07-01 00:00:00' });
  situcaoValores.push({ 'id': 19, 'valor': '07/2021', 'valorInicial': '2021-07-01 00:00:00', 'valorFinal': '2021-08-01 00:00:00' });
  situcaoValores.push({ 'id': 20, 'valor': '08/2021', 'valorInicial': '2021-08-01 00:00:00', 'valorFinal': '2021-09-01 00:00:00' });
  situcaoValores.push({ 'id': 21, 'valor': '09/2021', 'valorInicial': '2021-09-01 00:00:00', 'valorFinal': '2021-10-01 00:00:00' });
  situcaoValores.push({ 'id': 22, 'valor': '10/2021', 'valorInicial': '2021-10-01 00:00:00', 'valorFinal': '2021-11-01 00:00:00' });
  situcaoValores.push({ 'id': 23, 'valor': '11/2021', 'valorInicial': '2021-11-01 00:00:00', 'valorFinal': '2021-12-01 00:00:00' });
  situcaoValores.push({ 'id': 24, 'valor': '12/2021', 'valorInicial': '2021-12-01 00:00:00', 'valorFinal': '2022-01-01 00:00:00' });
  situcaoValores.push({ 'id': 25, 'valor': '01/2022', 'valorInicial': '2022-01-01 00:00:00', 'valorFinal': '2022-02-01 00:00:00' });
  situcaoValores.push({ 'id': 26, 'valor': '02/2022', 'valorInicial': '2022-02-01 00:00:00', 'valorFinal': '2022-03-01 00:00:00' });
  situcaoValores.push({ 'id': 27, 'valor': '03/2022', 'valorInicial': '2022-03-01 00:00:00', 'valorFinal': '2022-04-01 00:00:00' });
  situcaoValores.push({ 'id': 28, 'valor': '04/2022', 'valorInicial': '2022-04-01 00:00:00', 'valorFinal': '2022-05-01 00:00:00' });
  situcaoValores.push({ 'id': 29, 'valor': '05/2022', 'valorInicial': '2022-05-01 00:00:00', 'valorFinal': '2022-06-01 00:00:00' });
  situcaoValores.push({ 'id': 30, 'valor': '06/2022', 'valorInicial': '2022-06-01 00:00:00', 'valorFinal': '2022-07-01 00:00:00' });
  situcaoValores.push({ 'id': 31, 'valor': '07/2022', 'valorInicial': '2022-07-01 00:00:00', 'valorFinal': '2022-08-01 00:00:00' });
  situcaoValores.push({ 'id': 32, 'valor': '08/2022', 'valorInicial': '2022-08-01 00:00:00', 'valorFinal': '2022-09-01 00:00:00' });
  situcaoValores.push({ 'id': 33, 'valor': '09/2022', 'valorInicial': '2022-09-01 00:00:00', 'valorFinal': '2022-10-01 00:00:00' });
  situcaoValores.push({ 'id': 34, 'valor': '10/2022', 'valorInicial': '2022-10-01 00:00:00', 'valorFinal': '2022-11-01 00:00:00' });
  situcaoValores.push({ 'id': 35, 'valor': '11/2022', 'valorInicial': '2022-11-01 00:00:00', 'valorFinal': '2022-12-01 00:00:00' });
  situcaoValores.push({ 'id': 36, 'valor': '12/2022', 'valorInicial': '2022-12-01 00:00:00', 'valorFinal': '2023-01-01 00:00:00' });

  return situcaoValores;
};

export function getSelectTipoCamposFatura(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'T', 'valor': 'Campo Simples' });
  situcaoValores.push({ 'id': 'F', 'valor': 'Campo Fixo' });
  situcaoValores.push({ 'id': 'M', 'valor': 'Tabela Mapeada' });
  situcaoValores.push({ 'id': 'S', 'valor': 'Item da Tabela' });

  return situcaoValores;
};
export function getSelectValorCamposFatura(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'S', 'valor': 'Texto' });
  situcaoValores.push({ 'id': 'N', 'valor': 'Número' });
  situcaoValores.push({ 'id': 'D', 'valor': 'Data' });

  return situcaoValores;
};

export function getSelectEstudioTipoCamposFatura(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'F', 'valor': 'Campo Fixo' });
  situcaoValores.push({ 'id': 'T', 'valor': 'Campo Simples' });
  situcaoValores.push({ 'id': 'I', 'valor': 'Intervalo' });
  situcaoValores.push({ 'id': 'M', 'valor': 'Tabela' });
  situcaoValores.push({ 'id': 'S', 'valor': 'Item da Tabela' });

  return situcaoValores;
};
export function getSelectTipoFaturamentoContrato(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'PF', 'valor': 'Aplicar Proinfa e Flexibidade' });
  situcaoValores.push({ 'id': 'F', 'valor': 'Aplicar Apenas Flexibidade' });



  return situcaoValores;
};
export function getSelectTipoStatusContratoValidar(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'V', 'valor': 'Validação CCEEPendente' });
  situcaoValores.push({ 'id': 'R', 'valor': 'Registrado no Cliq' });

  return situcaoValores;
};
export function getSelectSituacaoMercadoLivreContrato(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'V', 'valor': 'Vigente' });
  situcaoValores.push({ 'id': 'R', 'valor': 'Rascunho' });
  situcaoValores.push({ 'id': 'H', 'valor': 'Histórico' });

  return situcaoValores;
};
export function getSelectTipoDetalheUC(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'ME', 'valor': 'Mercado de Energia' });
  situcaoValores.push({ 'id': 'GT', 'valor': 'Grupo Tarifário' });
  situcaoValores.push({ 'id': 'MT', 'valor': 'Modalidade Tarifária' });
  situcaoValores.push({ 'id': 'TL', 'valor': 'Tipo de Ligação' });

  return situcaoValores;
};
export function getSelectMeses(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': '01', 'valor': 'janeiro' });
  situcaoValores.push({ 'id': '02', 'valor': 'fevereiro' });
  situcaoValores.push({ 'id': '03', 'valor': 'março' });
  situcaoValores.push({ 'id': '04', 'valor': 'abril' });
  situcaoValores.push({ 'id': '05', 'valor': 'maio' });
  situcaoValores.push({ 'id': '06', 'valor': 'junho' });
  situcaoValores.push({ 'id': '07', 'valor': 'julho' });
  situcaoValores.push({ 'id': '08', 'valor': 'agosto' });
  situcaoValores.push({ 'id': '09', 'valor': 'setembro' });
  situcaoValores.push({ 'id': '10', 'valor': 'outubro' });
  situcaoValores.push({ 'id': '11', 'valor': 'novembro' });
  situcaoValores.push({ 'id': '12', 'valor': 'dezembro' });

  return situcaoValores;
};

export function getSelectTipoMedicao(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'D', 'valor': 'Distribuidora' });
  return situcaoValores;
};
export function getSelectCamposLayoutFatura(incluirEmBranco) {
  const situcaoValores = [];
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'ValorFatura', valor: 'Valor Fatura' });
  situcaoValores.push({ 'id': 'CheckLayout', valor: 'Check Layout' });
  situcaoValores.push({ 'id': 'DataEmissaoFatura', valor: 'Data de Emissao da Fatura' });
  return situcaoValores;
};

export function getSelectCampoSituacaoCobranca(incluirEmBranco) {
  const situcaoValores = [];
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Á Pagar' });
  situcaoValores.push({ 'id': 'V', valor: 'Vencido' });
  return situcaoValores;
};

export function getSelectPeriodo(incluirEmBranco) {
    const situcaoValores = [];
    if (incluirEmBranco) {
        situcaoValores.push({ 'id': null, valor: 'Todos' });
    }
    situcaoValores.push({ 'id': 'H', valor: 'Apenas hoje' });
    situcaoValores.push({ 'id': 'O', valor: 'Ontem' });
    situcaoValores.push({ 'id': 'U', valor: 'Últimos 3 dias' });
    return situcaoValores;
};


export function getSelectCampoSituacaoHistoricoCobranca(incluirEmBranco) {
  const situcaoValores = [];
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'C', valor: 'Cancelado' });
  situcaoValores.push({ 'id': 'P', valor: 'Pago' });
  return situcaoValores;
};

export function getSelectCampoTipoPagamento(incluirEmBranco) {
  const situcaoValores = [];
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'D', valor: 'Dinheiro' });
  situcaoValores.push({ 'id': 'P', valor: 'PIX' });
  situcaoValores.push({ 'id': 'B', valor: 'Boleto' });
  situcaoValores.push({ 'id': 'T', valor: 'TED/DOC' });
  return situcaoValores;
};

export function getSelectCampoGateway(incluirEmBranco) {
  const situcaoValores = [];
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'M', valor: 'Mercado Pago' });
  situcaoValores.push({ 'id': 'B', valor: 'Bradesco' });
  situcaoValores.push({ 'id': 'P', valor: 'Pic Pay' });
  situcaoValores.push({ 'id': 'BB', valor: 'Banco do Brasil' });
  return situcaoValores;
};


export function getSelectCampoBanco(incluirEmBranco) {
  const situcaoValores = [];
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'M', valor: 'Mercado Pago' });
  situcaoValores.push({ 'id': 'B', valor: 'Bradesco' });
  situcaoValores.push({ 'id': 'P', valor: 'Pic Pay' });
  situcaoValores.push({ 'id': 'BB', valor: 'Banco do Brasil' });
  return situcaoValores;
};

export function getSelectCampoTipoArquivo(incluirEmBranco) {
  const situcaoValores = [];
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'E', valor: 'Remessa' });
  situcaoValores.push({ 'id': 'R', valor: 'Retorno' });
  return situcaoValores;
};


export function getSelectCampoArquivoRemessa(incluirEmBranco) {
  const situcaoValores = [];
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'R', valor: 'Remessa Gerada' });
  situcaoValores.push({ 'id': 'P', valor: 'Pendente Process' });
  return situcaoValores;
};


export function getSelectTipoComunicacao(incluirAplicarTodos, incluirOpcaoVazia) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': 0, valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'G', valor: '3G' });
  situcaoValores.push({ 'id': 'W', valor: 'Wifi' });
  situcaoValores.push({ 'id': 'B', valor: 'Bluetooth' });
  situcaoValores.push({ 'id': 'L', valor: 'Lora' });
  situcaoValores.push({ 'id': 'E', valor: 'Ethernet' });
  return situcaoValores;
};

export function getSelectTipoHistoricoFatura(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': 0, valor: 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'id': null, valor: '' });
    }
    situcaoValores.push({ 'id': 'C', valor: 'Consumo' });
    situcaoValores.push({ 'id': 'D', valor: 'Demanda' });
    resolve(situcaoValores);
  });
};

export function getSelectDevice(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/device`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': 0, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.idDevice, 'label': item.nomeDevice });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.idDevice, 'label': item.nomeDevice });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectUC(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/uc`);
    const UC = [];
    if (incluirAplicarTodos) {
      UC.push({ 'value': 0, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      UC.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            UC.push({ 'value': item.idUC, 'label': item.nomeUC });
            resolve(UC);
          } else {
            UC.push({ 'value': item.idUC, 'label': item.nomeUC });
          }
        });
        resolve(UC);
      }
      else {
        resolve(UC);
      }
    });
  });
};

export function getSelectTipoArquivo(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/TipoArquivo`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': 0, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.idTipoArquivo, 'label': item.nomeTipoArquivo });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.idTipoArquivo, 'label': item.nomeTipoArquivo });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectTipoCampo(incluirAplicarTodos, incluirOpcaoVazia, tipoCampo) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/templateFaturas/${tipoCampo}`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'id': null, valor: '' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'id': item.idEstudioFaturaCampos, 'valor': item.campoFatura });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'id': item.idEstudioFaturaCampos, 'valor': item.campoFatura });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};


export function getSelectAgente(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/agente`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idAgente': 0, 'nomeAgente': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idAgente': null, 'nomeAgente': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idAgente': parseInt(item.idAgente), 'nomeAgente': item.nomeAgente });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idAgente': parseInt(item.idAgente), 'nomeAgente': item.nomeAgente });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectContrato(tipoContrato, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/contrato`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idContrato': 0, 'nomeContrato': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idContrato': null, 'nomeContrato': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idContrato': parseInt(item.idContrato), 'nomeContrato': item.nomeContrato });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idContrato': parseInt(item.idContrato), 'nomeContrato': item.nomeContrato });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};


export function getSelectFormaDeCalculo(incluirAplicarTodos, incluirOpcaoVazia) {
  const valores = [];
  if (incluirAplicarTodos) {
    valores.push({ "id": "", "valor": "Todos" });
  }
  if (incluirOpcaoVazia) {
    valores.push({ 'id': null, 'valor': 'Selecione um opção' });
  }
  valores.push({ 'id': '1', valor: 'Forma 1' });
  valores.push({ 'id': '2', valor: 'Forma 2' });
  valores.push({ 'id': '3', valor: 'Forma 3' });
  valores.push({ 'id': '4', valor: 'Forma 4' });
  valores.push({ 'id': '5', valor: 'Forma 5' });

  return valores;
};
export function getSelectSimNao(incluirAplicarTodos, incluirOpcaoVazia) {
  const origemalores = [];
  if (incluirAplicarTodos) {
    origemalores.push({ 'id': null, valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    origemalores.push({ 'id': null, valor: 'Selecione um opção' });
  }
  origemalores.push({ 'id': 'S', valor: 'Sim' });
  origemalores.push({ 'id': 'N', valor: 'Não' });
  return origemalores;
};

export function getSelectReplicadas(incluirAplicarTodos, incluirOpcaoVazia) {
  const origemalores = [];
  if (incluirAplicarTodos) {
    origemalores.push({ 'id': null, valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    origemalores.push({ 'id': null, valor: 'Selecione um opção' });
  }
  origemalores.push({ 'id': 'S', valor: 'Replicadas' });
  origemalores.push({ 'id': 'N', valor: 'Não Replicadas' });
  return origemalores;
};

export function getSelectUsinaGD(idGD, idDistribuidora, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/usinagd/` + idGD + '/' + idDistribuidora);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idUsina': null, 'nomeUsina': 'Todos', 'nomeDistribuidora': '' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idUsina': null, 'nomeUsina': '', 'nomeDistribuidora': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idUsina': item.idUsina, 'nomeUsina': item.nomeUsina, 'nomeDistribuidora': item.nomeDistribuidora });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idUsina': item.idUsina, 'nomeUsina': item.nomeUsina, 'nomeDistribuidora': item.nomeDistribuidora });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectDistribuidoraGD(idGD, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/distribuidoragd/` + idGD);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idDistribuidora': null, 'nomeDistribuidora': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idDistribuidora': null, 'nomeDistribuidora': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idDistribuidora': item.idDistribuidora, 'nomeDistribuidora': item.nomeDistribuidora });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idDistribuidora': item.idDistribuidora, 'nomeDistribuidora': item.nomeDistribuidora });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectFormaPagto(incluirAplicarTodos, incluirOpcaoVazia) {
  const situcaoValores = [];
  // if (incluirAplicarTodos) {
  //     situcaoValores.push({ 'id': '', valor: 'Todos' });
  // }
  // if (incluirOpcaoVazia) {
  //     situcaoValores.push({ 'id': null, valor: '' });
  // }
  situcaoValores.push({ 'id': 'null', valor: 'Selecione uma opção' });
  situcaoValores.push({ 'id': 'D', valor: 'Débito Automático' });
  situcaoValores.push({ 'id': 'B', valor: 'Boleto bancário' });
  return situcaoValores;
};
export function getSelectTipoTemplate(incluirAplicarTodos, incluirOpcaoVazia) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'E', valor: 'E-mail' });
  situcaoValores.push({ 'id': 'S', valor: 'SMS' });
  situcaoValores.push({ 'id': 'W', valor: 'WhatsApp' });
  return situcaoValores;
};
export function getSelectTipoContrato(incluirAplicarTodos, incluirOpcaoVazia) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'T', valor: 'Aceite Online  - Inscrição Agente' });
  situcaoValores.push({ 'id': '1', valor: 'Proposta Comercial - Mercado Livre de Energia' });
  situcaoValores.push({ 'id': '2', valor: 'Proposta Comercial - Gestão Energia' });
  situcaoValores.push({ 'id': '3', valor: 'Proposta Comercial - Geração Distribuída' });
  situcaoValores.push({ 'id': '4', valor: 'Proposta Comercial - Geração Centralizada' });
  return situcaoValores;
};
export function getSelectSituacaoContrato(incluirAplicarTodos, incluirOpcaoVazia) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Ativo' });
  situcaoValores.push({ 'id': 'I', valor: 'Inativo' });
  return situcaoValores;
};
export function getSelectSituacaoLeads(incluirAplicarTodos, incluirOpcaoVazia) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 99, valor: 'Lead invalido' });
  situcaoValores.push({ 'id': 1, valor: 'Contato Comercial não realizado' });
  situcaoValores.push({ 'id': 2, valor: 'Realizado o 1.º Contato Comercial' });
  situcaoValores.push({ 'id': 3, valor: 'Proposta comercial enviada' });
  situcaoValores.push({ 'id': 4, valor: 'Venda' });
  return situcaoValores;
};
export function getSelectOrigemLeads(incluirAplicarTodos) {
  const origemalores = [];
  if (incluirAplicarTodos) {
    origemalores.push({ 'id': '', valor: 'Todos' });
  }
  origemalores.push({ 'id': 1, valor: 'Simulador Mercado Livre ' });
  origemalores.push({ 'id': 2, valor: 'Agente' });
  return origemalores;
};
export function getSelectTipoGestao(incluirAplicarTodos, incluirOpcaoVazia) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': 0, valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Auto Consumo' });
  situcaoValores.push({ 'id': 'C', valor: 'Consórcio' });
  situcaoValores.push({ 'id': 'O', valor: 'Cooperativa' });
  return situcaoValores;
};
export function getSelectSituacaoUsuario(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Ativo' });
  situcaoValores.push({ 'id': 'I', valor: 'Inativo' });
  situcaoValores.push({ 'id': 'B', valor: 'Bloqueado' });
  return situcaoValores;
};
export function getSelectSituacaoCron(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Ativo' });
  situcaoValores.push({ 'id': 'I', valor: 'Pausado' });
  return situcaoValores;
};
export function getSelectCNAE(incluirAplicarTodos, incluirOpcaoVazia) {
  const cnae = [];
  if (incluirAplicarTodos) {
    cnae.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    cnae.push({ 'id': null, valor: '' });
  }
  cnae.push({ 'id': '1', valor: 'Agricultura, Pecuária E Serviços Relacionados' });
  cnae.push({ 'id': '2', valor: 'Produção Florestal' });
  cnae.push({ 'id': '3', valor: 'Pesca E Aqüicultura' });
  cnae.push({ 'id': '5', valor: 'Extração De Carvão Mineral' });
  cnae.push({ 'id': '6', valor: 'Extração De Petróleo E Gás Natural' });
  cnae.push({ 'id': '7', valor: 'Extração De Minerais Metálicos' });
  cnae.push({ 'id': '8', valor: 'Extração De Minerais Não-Metálicos' });
  cnae.push({ 'id': '9', valor: 'Atividades De Apoio À Extração De Minerais' });
  cnae.push({ 'id': '10', valor: 'Fabricação De Produtos Alimentícios' });
  cnae.push({ 'id': '11', valor: 'Fabricação De Bebidas' });
  cnae.push({ 'id': '12', valor: 'Fabricação De Produtos Do Fumo' });
  cnae.push({ 'id': '13', valor: 'Fabricação De Produtos Têxteis' });
  cnae.push({ 'id': '14', valor: 'Confecção De Artigos Do Vestuário E Acessórios' });
  cnae.push({ 'id': '15', valor: 'Preparação De Couros E Fabricação De Artefatos De Couro, Artigos Para Viagem E Calçados' });
  cnae.push({ 'id': '16', valor: 'Fabricação De Produtos De Madeira' });
  cnae.push({ 'id': '17', valor: 'Fabricação De Celulose, Papel E Produtos De Papel' });
  cnae.push({ 'id': '18', valor: 'Impressão E Reprodução De Gravações' });
  cnae.push({ 'id': '19', valor: 'Fabricação De Coque, De Produtos Derivados Do Petróleo E De Biocombustíveis' });
  cnae.push({ 'id': '20', valor: 'Fabricação De Produtos Químicos' });
  cnae.push({ 'id': '21', valor: 'Fabricação De Produtos Farmoquímicos E Farmacêuticos' });
  cnae.push({ 'id': '22', valor: 'Fabricação De Produtos De Borracha E De Material Plástico' });
  cnae.push({ 'id': '23', valor: 'Fabricação De Produtos De Minerais Não-Metálicos' });
  cnae.push({ 'id': '24', valor: 'Metalurgia' });
  cnae.push({ 'id': '25', valor: 'Fabricação De Produtos De Metal, Exceto Máquinas E Equipamentos' });
  cnae.push({ 'id': '26', valor: 'Fabricação De Equipamentos De Informática, Produtos Eletrônicos E Ópticos' });
  cnae.push({ 'id': '27', valor: 'Fabricação De Máquinas, Aparelhos E Materiais Elétricos' });
  cnae.push({ 'id': '28', valor: 'Fabricação De Máquinas E Equipamentos' });
  cnae.push({ 'id': '29', valor: 'Fabricação De Veículos Automotores, Reboques E Carrocerias' });
  cnae.push({ 'id': '30', valor: 'Fabricação De Outros Equipamentos De Transporte, Exceto Veículos Automotores' });
  cnae.push({ 'id': '31', valor: 'Fabricação De Móveis' });
  cnae.push({ 'id': '32', valor: 'Fabricação De Produtos Diversos' });
  cnae.push({ 'id': '33', valor: 'Manutenção, Reparação E Instalação De Máquinas E Equipamentos' });
  cnae.push({ 'id': '35', valor: 'Eletricidade, Gás E Outras Utilidades' });
  cnae.push({ 'id': '36', valor: 'Captação, Tratamento E Distribuição De Água' });
  cnae.push({ 'id': '37', valor: 'Esgoto E Atividades Relacionadas' });
  cnae.push({ 'id': '38', valor: 'Coleta, Tratamento E Disposição De Resíduos; Recuperação De Materiais' });
  cnae.push({ 'id': '39', valor: 'Descontaminação E Outros Serviços De Gestão De Resíduos' });
  cnae.push({ 'id': '41', valor: 'Construção De Edifícios' });
  cnae.push({ 'id': '42', valor: 'Obras De Infra-Estrutura' });
  cnae.push({ 'id': '43', valor: 'Serviços Especializados Para Construção' });
  cnae.push({ 'id': '45', valor: 'Comércio E Reparação De Veículos Automotores E Motocicletas' });
  cnae.push({ 'id': '46', valor: 'Comércio Por Atacado, Exceto Veículos Automotores E Motocicletas' });
  cnae.push({ 'id': '47', valor: 'Comércio Varejista' });
  cnae.push({ 'id': '49', valor: 'Transporte Terrestre' });
  cnae.push({ 'id': '50', valor: 'Transporte Aquaviário' });
  cnae.push({ 'id': '51', valor: 'Transporte Aéreo' });
  cnae.push({ 'id': '52', valor: 'Armazenamento E Atividades Auxiliares Dos Transportes' });
  cnae.push({ 'id': '53', valor: 'Correio E Outras Atividades De Entrega' });
  cnae.push({ 'id': '55', valor: 'Alojamento' });
  cnae.push({ 'id': '56', valor: 'Alimentação' });
  cnae.push({ 'id': '58', valor: 'Edição E Edição Integrada À Impressão' });
  cnae.push({ 'id': '59', valor: 'Atividades Cinematográficas, Produção De Vídeos E De Programas De Televisão; Gravação De Som E Edição De Música' });
  cnae.push({ 'id': '60', valor: 'Atividades De Rádio E De Televisão' });
  cnae.push({ 'id': '61', valor: 'Telecomunicações' });
  cnae.push({ 'id': '62', valor: 'Atividades Dos Serviços De Tecnologia Da Informação' });
  cnae.push({ 'id': '63', valor: 'Atividades De Prestação De Serviços De Informação' });
  cnae.push({ 'id': '64', valor: 'Atividades De Serviços Financeiros' });
  cnae.push({ 'id': '65', valor: 'Seguros, Resseguros, Previdência Complementar E Planos De Saúde' });
  cnae.push({ 'id': '66', valor: 'Atividades Auxiliares Dos Serviços Financeiros, Seguros, Previdência Complementar E Planos De Saúde' });
  cnae.push({ 'id': '68', valor: 'Atividades Imobiliárias' });
  cnae.push({ 'id': '69', valor: 'Atividades Jurídicas, De Contabilidade E De Auditoria' });
  cnae.push({ 'id': '70', valor: 'Atividades De Sedes De Empresas E De Consultoria Em Gestão Empresarial' });
  cnae.push({ 'id': '71', valor: 'Serviços De Arquitetura E Engenharia; Testes E Análises Técnicas' });
  cnae.push({ 'id': '72', valor: 'Pesquisa E Desenvolvimento Científico' });
  cnae.push({ 'id': '73', valor: 'Publicidade E Pesquisa De Mercado' });
  cnae.push({ 'id': '74', valor: 'Outras Atividades Profissionais, Científicas E Técnicas' });
  cnae.push({ 'id': '75', valor: 'Atividades Veterinárias' });
  cnae.push({ 'id': '77', valor: 'Aluguéis Não-Imobiliários E Gestão De Ativos Intangíveis Não-Financeiros' });
  cnae.push({ 'id': '78', valor: 'Seleção, Agenciamento E Locação De Mão-De-Obra' });
  cnae.push({ 'id': '79', valor: 'Agências De Viagens, Operadores Turísticos E Serviços De Reservas' });
  cnae.push({ 'id': '80', valor: 'Atividades De Vigilância, Segurança E Investigação' });
  cnae.push({ 'id': '81', valor: 'Serviços Para Edifícios E Atividades Paisagísticas' });
  cnae.push({ 'id': '82', valor: 'Serviços De Escritório, De Apoio Administrativo E Outros Serviços Prestados Às Empresas' });
  cnae.push({ 'id': '84', valor: 'Administração Pública, Defesa E Seguridade Social' });
  cnae.push({ 'id': '85', valor: 'Educação' });
  cnae.push({ 'id': '86', valor: 'Atividades De Atenção À Saúde Humana' });
  cnae.push({ 'id': '87', valor: 'Atividades De Atenção À Saúde Humana Integradas Com Assistência Social, Prestadas Em Residências Coletivas E Particulares' });
  cnae.push({ 'id': '90', valor: 'Atividades Artísticas, Criativas E De Espetáculos' });
  cnae.push({ 'id': '91', valor: 'Atividades Ligadas Ao Patrimônio Cultural E Ambiental' });
  cnae.push({ 'id': '92', valor: 'Atividades De Exploração De Jogos De Azar E Apostas' });
  cnae.push({ 'id': '93', valor: 'Atividades Esportivas E De Recreação E Lazer' });
  cnae.push({ 'id': '94', valor: 'Atividades De Organizações Associativas' });
  cnae.push({ 'id': '95', valor: 'Reparação E Manutenção De Equipamentos De Informática E Comunicação E De Objetos Pessoais E Domésticos' });
  cnae.push({ 'id': '96', valor: 'Outras Atividades De Serviços Pessoais' });
  cnae.push({ 'id': '97', valor: 'Serviços Domésticos' });
  cnae.push({ 'id': '99', valor: 'Organismos Internacionais E Outras Instituições Extraterritoriais' });

  return cnae;
};
export function getSelectSituacaoTarifa(incluirAplicarTodos, incluirOpcaoVazia) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'P', valor: 'Publicado' });
  situcaoValores.push({ 'id': 'S', valor: 'Substituido' });
  situcaoValores.push({ 'id': 'E', valor: 'Em edição' });
  return situcaoValores;
};
export function getSelectSituacaoUCRateio(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'UCs do Rateio' });
  situcaoValores.push({ 'id': 'I', valor: 'UCs que NAO estao no Rateio' });
  return situcaoValores;
};
export function getSelectSituacaoUCContrato(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'A', valor: `UC's no contrato` });
  situcaoValores.push({ 'id': 'I', valor: `UC's que NÃO estão no contrato` });
  return situcaoValores;
};
export function getSelectFilaConsorcio(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Pertencente ao consórcio' });
  situcaoValores.push({ 'id': 'I', valor: 'Fila de espera' });
  return situcaoValores;
};
export function getSelectDiaMesInformado(dataI, dataF) {
  const dias = [];
  const dataInicial = moment(dataI, 'DD/mm/YYYY');
  const dataAux = moment(dataI, 'DD/mm/YYYY');
  const dataFinal = moment(dataF, 'DD/mm/YYYY');

  while (dataAux <= dataFinal) {
    dias.push({ 'id': dataAux.format('YYYY-mm-DD'), valor: dataAux.format('DD/mm/YYYY') });
    dataAux.add(1, 'days');
  }

  return dias;
};
export function getSelectData(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  situcaoValores.push({ 'id': '7D', valor: 'ultimos 7 dias' });
  situcaoValores.push({ 'id': '15D', valor: 'ultimos 15 dias' });
  situcaoValores.push({ 'id': '1M', valor: 'ultimos 1 mes' });
  situcaoValores.push({ 'id': '3M', valor: 'ultimos 3 mes' });
  return situcaoValores;
};
export function getSelectTipoUsina(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': 0, valor: 'Todos' });
  }
  situcaoValores.push({ 'id': '1', valor: 'Usina Hidrelétrica' });
  situcaoValores.push({ 'id': '2', valor: 'Usina Maremotriz' });
  situcaoValores.push({ 'id': '3', valor: 'Usina Marítima' });
  situcaoValores.push({ 'id': '4', valor: 'Usina Termoelétricas' });
  situcaoValores.push({ 'id': '5', valor: 'Usina Solar Fotovoltáica' });
  situcaoValores.push({ 'id': '6', valor: ' Usina Nuclear' });
  situcaoValores.push({ 'id': '7', valor: ' Usina Eólica' });
  situcaoValores.push({ 'id': '8', valor: ' Usina de Biogás' });
  situcaoValores.push({ 'id': '9', valor: ' Usina de Biomassa' });
  return situcaoValores;
};
export function getSelectTipoCliente(incluirAplicarTodos) {
  const valores = [];
  if (incluirAplicarTodos) {
    valores.push({ 'id': '', valor: 'Todos' });
  }
  valores.push({ 'id': 'F', valor: 'Pessoa Fisica' });
  valores.push({ 'id': 'J', valor: 'Pessoa Juridica' });
  return valores;
};
export function getSelectSituacaoFaturas(incluirAplicarTodos) {
  const valores = [];
  if (incluirAplicarTodos) {
    valores.push({ 'id': '', valor: 'Todos' });
  }
  valores.push({ 'id': 'P', valor: 'Pendente' });
  valores.push({ 'id': 'D', valor: 'Disponivel' });
  return valores;
};
export function getSelectSituacaoProcessamentos(incluirAplicarTodos) {
  const valores = [];
  if (incluirAplicarTodos) {
    valores.push({ 'id': '', valor: 'Todos' });
  }
  valores.push({ 'id': 'P', valor: 'Pendentes' });
  valores.push({ 'id': 'N', valor: 'Não PDF' });
  valores.push({ 'id': 'L', valor: 'Layout Não Mapeada' });
  valores.push({ 'id': 'F', valor: 'Processado' });

  return valores;
};
export function getSelectTipoAgente(incluirAplicarTodos) {
  const valores = [];
  if (incluirAplicarTodos) {
    valores.push({ 'id': '', valor: 'Todos' });
  }
  valores.push({ 'id': 'F', valor: 'Pessoa Fisica' });
  valores.push({ 'id': 'J', valor: 'Pessoa Juridica' });
  return valores;
};
export function getSelectTipoTelefone(incluirAplicarTodos) {
  const valores = [];

  valores.push({ 'id': '', valor: '' });
  valores.push({ 'id': '1', valor: 'Telefone Celular' });
  valores.push({ 'id': '2', valor: 'Telefone Comercial' });
  valores.push({ 'id': '2', valor: 'Telefone Residencial' });

  return valores;
};
export function getSelectAtividadePeriodo(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': ' ', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'D', valor: 'Dia' });
  situcaoValores.push({ 'id': 'S', valor: 'Semana' });
  situcaoValores.push({ 'id': 'M', valor: 'Mês' });
  return situcaoValores;
};
export function getSelectRegraPor(incluirAplicarTodos) {
  const valores = [];

  valores.push({ 'id': '', valor: '' });
  valores.push({ 'id': 'U', valor: 'UF' });
  valores.push({ 'id': 'D', valor: 'Distribuidora' });

  return valores;
};
export function getSelectUF(incluirAplicarTodos, incluirAplicarEmBranco) {
  const uf = [];
  if (incluirAplicarTodos) {
    uf.push({ "id": "", "nome": "Todos" });
  }
  if (incluirAplicarEmBranco) {
    uf.push({ "id": "", "nome": "Selecione UF" });
  }
  uf.push({ "nome": "Acre", "id": "AC" });
  uf.push({ "nome": "Alagoas", "id": "AL" });
  uf.push({ "nome": "Amapá", "id": "AP" });
  uf.push({ "nome": "Amazonas", "id": "AM" });
  uf.push({ "nome": "Bahia", "id": "BA" });
  uf.push({ "nome": "Ceará", "id": "CE" });
  uf.push({ "nome": "Distrito Federal", "id": "DF" });
  uf.push({ "nome": "Espírito Santo", "id": "ES" });
  uf.push({ "nome": "Goiás", "id": "GO" });
  uf.push({ "nome": "Maranhão", "id": "MA" });
  uf.push({ "nome": "Mato Grosso", "id": "MT" });
  uf.push({ "nome": "Mato Grosso do Sul", "id": "MS" });
  uf.push({ "nome": "Minas Gerais", "id": "MG" });
  uf.push({ "nome": "Pará", "id": "PA" });
  uf.push({ "nome": "Paraíba", "id": "PB" });
  uf.push({ "nome": "Paraná", "id": "PR" });
  uf.push({ "nome": "Pernambuco", "id": "PE" });
  uf.push({ "nome": "Piauí", "id": "PI" });
  uf.push({ "nome": "Rio de Janeiro", "id": "RJ" });
  uf.push({ "nome": "Rio Grande do Norte", "id": "RN" });
  uf.push({ "nome": "Rio Grande do Sul", "id": "RS" });
  uf.push({ "nome": "Rondônia", "id": "RO" });
  uf.push({ "nome": "Roraima", "id": "RR" });
  uf.push({ "nome": "Santa Catarina", "id": "SC" });
  uf.push({ "nome": "São Paulo", "id": "SP" });
  uf.push({ "nome": "Sergipe", "id": "SE" });
  uf.push({ "nome": "Tocantins", "id": "TO" });

  return uf;
};
export function getTipoMercado(incluirAplicarTodos, incluirOpcaoVazia) {
  const mercado = [];
  if (incluirAplicarTodos) {
    mercado.push({ "id": "", "nome": "Todos" });
  }
  if (incluirOpcaoVazia) {
    mercado.push({ 'id': null, valor: '' });
  }
  mercado.push({ "nome": "ACR", "id": "ACR" });
  mercado.push({ "nome": "ACL", "id": "ACL" });


  return mercado;
};
export function getSelectGrupo(incluirAplicarTodos, incluirOpcaoVazia) {
  const grupo = [];
  if (incluirAplicarTodos) {
    grupo.push({ "id": "", "nome": "Todos" });
  }
  if (incluirOpcaoVazia) {
    grupo.push({ 'id': null, valor: '' });
  }

  grupo.push({ "nome": "Grupo A", "id": "A" });
  grupo.push({ "nome": "Grupo B", "id": "B" });

  return grupo;
};
export function getModalidadeTarifa(incluirAplicarTodos, incluirOpcaoVazia) {
  const modalidade = [];
  if (incluirAplicarTodos) {
    modalidade.push({ "id": "", "nome": "Todos" });
  }
  if (incluirOpcaoVazia) {
    modalidade.push({ 'id': null, valor: '' });
  }
  modalidade.push({ "nome": "Azul", "id": "A" });
  modalidade.push({ "nome": "Verde", "id": "V" });
  modalidade.push({ "nome": "Branco", "id": "B" });
  modalidade.push({ "nome": "Baixa Tensão", "id": "C" });
  modalidade.push({ "nome": "GD Auto Consumo", "id": "GA" });
  modalidade.push({ "nome": "GD Consorcio", "id": "GC" });

  return modalidade;
};
export function getTipoSimulacaoFatura(incluirAplicarTodos, incluirOpcaoVazia) {
  const modalidade = [];
  if (incluirAplicarTodos) {
    modalidade.push({ "id": "", "nome": "Todos" });
  }
  if (incluirOpcaoVazia) {
    modalidade.push({ 'id': null, valor: '' });
  }
  modalidade.push({ "nome": "Simular de Cativo para Livre", "id": "CL" });
  modalidade.push({ "nome": "Simular de Livre para Cativo", "id": "LC" });

  return modalidade;
};

export function getTipoLigacao(incluirAplicarTodos, incluirOpcaoVazia) {
  const modalidade = [];
  if (incluirAplicarTodos) {
    modalidade.push({ "id": "", "nome": "Todos" });
  }
  if (incluirOpcaoVazia) {
    modalidade.push({ 'id': null, valor: '' });
  }
  modalidade.push({ "nome": "Monofásico", "id": "M" });
  modalidade.push({ "nome": "Bifásico", "id": "B" });
  modalidade.push({ "nome": "Trifásica", "id": "T" });

  return modalidade;
};
export function getSelectAplicarRegraICMS(incluirAplicarTodos, incluirOpcaoVazia) {
  const regra = [];
  if (incluirAplicarTodos) {
    regra.push({ "value": "", "label": "Todos" });
  }
  if (incluirOpcaoVazia) {
    regra.push({ 'value': "", "label": 'Selecione a regra' });
  }
  regra.push({ "label": "Por UF", "value": "U" });
  regra.push({ "label": "Por Distribuidora", "value": "D" });

  return regra;
};

export function getSelectDataVencimentoPadrao(incluirAplicarTodos, incluirOpcaoVazia) {
  const valores = [];
  if (incluirAplicarTodos) {
    valores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    valores.push({ 'value': "", "label": 'Selecione um dia' });
  }
  valores.push({ 'id': '1', valor: 1 });
  valores.push({ 'id': '2', valor: 2 });
  valores.push({ 'id': '3', valor: 3 });
  valores.push({ 'id': '4', valor: 4 });
  valores.push({ 'id': '5', valor: 5 });
  valores.push({ 'id': '6', valor: 6 });
  valores.push({ 'id': '7', valor: 7 });
  valores.push({ 'id': '8', valor: 8 });
  valores.push({ 'id': '9', valor: 9 });
  valores.push({ 'id': '10', valor: 10 });
  valores.push({ 'id': '11', valor: 11 });
  valores.push({ 'id': '12', valor: 12 });
  valores.push({ 'id': '13', valor: 13 });
  valores.push({ 'id': '14', valor: 14 });
  valores.push({ 'id': '15', valor: 15 });
  valores.push({ 'id': '16', valor: 16 });
  valores.push({ 'id': '17', valor: 17 });
  valores.push({ 'id': '18', valor: 18 });
  valores.push({ 'id': '19', valor: 19 });
  valores.push({ 'id': '20', valor: 20 });
  valores.push({ 'id': '21', valor: 21 });
  valores.push({ 'id': '22', valor: 22 });
  valores.push({ 'id': '23', valor: 23 });
  valores.push({ 'id': '24', valor: 24 });
  valores.push({ 'id': '25', valor: 25 });
  valores.push({ 'id': '26', valor: 26 });
  valores.push({ 'id': '27', valor: 27 });
  valores.push({ 'id': '28', valor: 28 });
  valores.push({ 'id': '29', valor: 29 });
  valores.push({ 'id': '30', valor: 30 });
  valores.push({ 'id': '31', valor: 31 });


  return valores;
};



export function getSelectUFListaDupla(incluirAplicarTodos) {
  const uf = [];
  if (incluirAplicarTodos) {
    uf.push({ "value": "", "label": "Todos" });
  }

  uf.push({ "label": "Acre", "value": "AC" });
  uf.push({ "label": "Alagoas", "value": "AL" });
  uf.push({ "label": "Amapá", "value": "AP" });
  uf.push({ "label": "Amazonas", "value": "AM" });
  uf.push({ "label": "Bahia", "value": "BA" });
  uf.push({ "label": "Ceará", "value": "CE" });
  uf.push({ "label": "Distrito Federal", "value": "DF" });
  uf.push({ "label": "Espírito Santo", "value": "ES" });
  uf.push({ "label": "Goiás", "value": "GO" });
  uf.push({ "label": "Maranhão", "value": "MA" });
  uf.push({ "label": "Mato Grosso", "value": "MT" });
  uf.push({ "label": "Mato Grosso do Sul", "value": "MS" });
  uf.push({ "label": "Minas Gerais", "value": "MG" });
  uf.push({ "label": "Pará", "value": "PA" });
  uf.push({ "label": "Paraíba", "value": "PB" });
  uf.push({ "label": "Paraná", "value": "PR" });
  uf.push({ "label": "Pernambuco", "value": "PE" });
  uf.push({ "label": "Piauí", "value": "PI" });
  uf.push({ "label": "Rio de Janeiro", "value": "RJ" });
  uf.push({ "label": "Rio Grande do Norte", "value": "RN" });
  uf.push({ "label": "Rio Grande do Sul", "value": "RS" });
  uf.push({ "label": "Rondônia", "value": "RO" });
  uf.push({ "label": "Roraima", "value": "RR" });
  uf.push({ "label": "Santa Catarina", "value": "SC" });
  uf.push({ "label": "São Paulo", "value": "SP" });
  uf.push({ "label": "Sergipe", "value": "SE" });
  uf.push({ "label": "Tocantins", "value": "TO" });

  return uf;
};

export function getSelectSegmentacao(incluirAplicarTodos) {
  const valores = [];
  if (incluirAplicarTodos) {
    valores.push({ 'id': 'todos', valor: 'Todos' });
  }
  valores.push({ 'id': 'UF', valor: 'Segmentação UF' });
  valores.push({ 'id': 'DF', valor: 'Segmentação Distribuidora' });
  return valores;
};

// export function getSelectData(incluirAplicarTodos) {
// };
export function getSelectFaixaValores(incluirAplicarTodos) {
  const valores = [];
  if (incluirAplicarTodos) {
    valores.push({ "id": "", valor: "Todos" });
  }

  valores.push({ 'id': '1', valor: 'até R$ 5.000,00' });
  valores.push({ 'id': '2', valor: 'R$ 5.001,00 até R$ 15.000,00' });
  valores.push({ 'id': '3', valor: 'R$ 15.001,00 até R$ 50.000,00' });
  valores.push({ 'id': '4', valor: 'R$ 50.001,00 até R$ 100.000,00' });
  valores.push({ 'id': '5', valor: 'R$ 100.001,00 até R$ 250.000,00' });
  valores.push({ 'id': '6', valor: 'R$ 250.001,00 até R$ 500.000,00' });
  valores.push({ 'id': '7', valor: 'R$ 500.001,00 até R$ 1.000.000,00' });
  valores.push({ 'id': '8', valor: 'acima de R$ 1.000.001,00' });

  return valores;
};
export function getSelectFaixaEnergia(incluirAplicarTodos) {
  const valores = [];
  if (incluirAplicarTodos) {
    valores.push({ "id": "", valor: "Todos" });
  }

  valores.push({ 'id': '1', valor: 'até 0,2 MWm' });
  valores.push({ 'id': '2', valor: 'de 0,201 a 0,5 MWm' });
  valores.push({ 'id': '3', valor: 'de 0,501 a 1 MWm' });
  valores.push({ 'id': '4', valor: 'de 1,01 a 2 MWm' });
  valores.push({ 'id': '5', valor: 'de 2,01 a 5 MWm' });
  valores.push({ 'id': '6', valor: 'de 5,01 a 10 MWm' });
  valores.push({ 'id': '7', valor: 'acima de 10,01 MWm' });
  return valores;
};
export function getSelectFiltroDashboard() {
  const valores = [];

  valores.push({ 'id': '0M', valor: 'Mês Atual' });
  valores.push({ 'id': '-1M', valor: 'Mês Anterior' });
  valores.push({ 'id': '-6I', valor: 'Últimos 6 meses' });
  valores.push({ 'id': '-12I', valor: 'Últimos 12 meses' });
  moment.locale('pt'); // setar o locale para "pt" (Português)

  // por padrão, o nome do mês é com letra minúscula
  valores.push({ 'id': '-2M', valor: moment().add(-2, 'M').format('MMMM/YYYY') });
  valores.push({ 'id': '-3M', valor: moment().add(-3, 'M').format('MMMM/YYYY') });
  valores.push({ 'id': '-4M', valor: moment().add(-4, 'M').format('MMMM/YYYY') });
  valores.push({ 'id': '-5M', valor: moment().add(-5, 'M').format('MMMM/YYYY') });
  valores.push({ 'id': '-6M', valor: moment().add(-6, 'M').format('MMMM/YYYY') });
  valores.push({ 'id': '-7M', valor: moment().add(-7, 'M').format('MMMM/YYYY') });
  return valores;
};

export function getSelectTipoProduto(incluirAplicarTodos) {
  const valores = [];
  if (incluirAplicarTodos) {
    valores.push({ "id": "", valor: "Todos" });
  }

  valores.push({ 'id': '1', valor: 'Mercado Livre de Energia' });
  valores.push({ 'id': '2', valor: 'Gestão energia (consumo ou gasto)' });
  valores.push({ 'id': '3', valor: 'Geração Distribuída (GD)' });
  valores.push({ 'id': '4', valor: 'Geração Centralizada' });

  return valores;
};
export function getSelectSituacaoProposta(incluirAplicarTodos) {
  const valores = [];
  if (incluirAplicarTodos) {
    valores.push({ "id": "", valor: "Todos" });
  }

  valores.push({ 'id': 'P', valor: 'Pendente' });
  valores.push({ 'id': 'E', valor: 'Em Edição' });
  valores.push({ 'id': 'U', valor: 'Proposta publicada' });
  valores.push({ 'id': 'S', valor: 'Substituida' });
  valores.push({ 'id': 'D', valor: 'Proposta perdida' });
  valores.push({ 'id': 'A', valor: 'Proposta aceita' });

  return valores;
};
export function getSelectSituacaoPagamento(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'E', valor: 'Em Aberto' });
  situcaoValores.push({ 'id': 'V', valor: 'Em Atraso' });
  situcaoValores.push({ 'id': 'P', valor: 'Pagamento Realizado' });

  return situcaoValores;
};
export function getSelectSituacaoAgente(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Ativo' });
  situcaoValores.push({ 'id': 'I', valor: 'Bloqueado' });
  situcaoValores.push({ 'id': 'R', valor: 'Reprovado' });
  situcaoValores.push({ 'id': 'P', valor: 'Pendente' });

  return situcaoValores;
};

// export function getSelectStatus(incluirAplicarTodos, incluirOpcaoVazia) {
//     const cnae = [];
//     if (incluirAplicarTodos) {
//         cnae.push({ 'id': '', valor: 'Todos' });
//     }
//     if (incluirOpcaoVazia) {
//         cnae.push({ 'id': null, valor: '' });
//     }
//     cnae.push({ 'id': 'P', valor: 'Documentação pendente ' });
//     cnae.push({ 'id': 'A', valor: 'Ativo' });
//     cnae.push({ 'id': 'E', valor: 'Em Análise ' });
//     cnae.push({ 'id': 'C', valor: 'concluído' });

//     return cnae;
// };

export function getSelectStatus(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/status`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'id': null, valor: '' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'id': item.idStatus, 'valor': item.nomeStatus, 'tipoStatus': item.tipoStatus, 'ordem': item.ordem });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'id': item.idStatus, 'valor': item.nomeStatus, 'tipoStatus': item.tipoStatus, 'ordem': item.ordem });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectTipoUC(incluirAplicarTodos, incluirAlterarEficiencia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/tipoUC`);
    const situcaoValores = [];
    if (incluirAlterarEficiencia) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Selecione um tipo UC' });
    }
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }

    valores.then(function (retorno) {

      if (retorno.status === 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'id': item.idTipoUC, 'tipo': item.nomeTipoUC });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'id': item.idTipoUC, 'tipo': item.nomeTipoUC });
          }
        });

        resolve(situcaoValores);
      }
      else {

        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectTelas(incluirAplicarTodos,id) {
    return new Promise((resolve, reject) => {
        const valores = axios.get(_URL + `/select/telas`);
        const situcaoValores = [];
        // if (incluirAplicarTodos) {
        //     situcaoValores.push({ 'id': null, 'tipo': 'Todos' });
        // }
        situcaoValores.push({ 'id': null, 'tipo': 'Todos' });

        valores.then(function (retorno) {

            if (retorno.status === 200 && retorno.data.entities != null) {
                retorno.data.entities.map((item, i, arr) => {
                    if (arr.length - 1 === i) {
                        situcaoValores.push({ 'id': item.idLinkAcesso, 'linkAcesso': item.linkAcesso, 'idMenuAcesso': item.idMenuAcesso });
                        resolve(situcaoValores);
                    } else {
                        situcaoValores.push({ 'id': item.idLinkAcesso, 'linkAcesso': item.linkAcesso, 'idMenuAcesso': item.idMenuAcesso });
                    }
                });

                resolve(situcaoValores);
            }
            else {

                resolve(situcaoValores);
            }


        });
    });
};




export function getSelectTipoUCCliente(incluirAplicarTodos,id) {
    return new Promise((resolve, reject) => {
        const valores = axios.get(_URL + `/select/tipoUCCliente/${id}`);
        const situcaoValores = [];
        // if (incluirAplicarTodos) {
        //     situcaoValores.push({ 'id': null, 'tipo': 'Todos' });
        // }
        situcaoValores.push({ 'id': null, 'tipo': 'Todos' });

        valores.then(function (retorno) {

      if (retorno.status === 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'id': item.idTipoUC, 'tipo': item.tipoUC });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'id': item.idTipoUC, 'tipo': item.tipoUC });
          }
        });

        resolve(situcaoValores);
      }
      else {

        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectUCCliente(incluirAplicarTodos, id) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/UCCliente/${id}`);
    const situcaoValores = [];
    // if (incluirAplicarTodos) {
    //     situcaoValores.push({ 'id': null, 'tipo': 'Todos' });
    // }
    situcaoValores.push({ 'id': null, 'uc': 'Todos' });

    valores.then(function (retorno) {

      if (retorno.status === 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idUC': item.idUC, 'uc': item.uc });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idUC': item.idUC, 'uc': item.uc });
          }
        });

        resolve(situcaoValores);
      }
      else {

        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectTipo(incluirAplicarTodos) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/simulacao/tipo/index`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }

    valores.then(function (retorno) {
      if (retorno.status === 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'id': item.id, 'tipo': item.tipo });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'id': item.id, 'tipo': item.tipo });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
// para fazer a pagina funcionar
export function getSelectSituacaoSimulador(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Ativo' });
  situcaoValores.push({ 'id': 'I', valor: 'Bloqueado' });
  situcaoValores.push({ 'id': 'R', valor: 'Reprovado' });
  situcaoValores.push({ 'id': 'P', valor: 'Pendente' });

  return situcaoValores;
};

export function getSelectTipoNotificacao(incluirAplicarTodos) {
    const situcaoValores = [];
    if (incluirAplicarTodos) {
        situcaoValores.push({ 'id': '', valor: 'Todos' });
    }
    situcaoValores.push({ 'id': 'C', valor: 'Concluído' });
    situcaoValores.push({ 'id': 'E', valor: 'Erro' });

    return situcaoValores;
};


export function getSelectIntervaloMedicao(incluirAplicarTodos, incluirOpcaoVazia) {
  const valores = [];
  // if (incluirAplicarTodos) {
  //     valores.push({ "id": "", valor: "Selecione o intervalo" });
  // }

  // if (incluirOpcaoVazia) {
  //     valores.push({ 'id': null, 'valor': 'Selecione o intervalo' });
  // }

  valores.push({ 'id': '1', valor: 'Ciclo Fechado' });
  valores.push({ 'id': '2', valor: 'Intervalo Personalizado Dia' });
  // valores.push({ 'id': '3', valor: 'Intervalo Personalizado Horas' });
  return valores;
};

export function getSelectTipoTaxaME(incluirAplicarTodos, incluirOpcaoVazia, incluirTipoTaxaME) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/tipoTaxaME`);
    const situcaoValores = [];
    if (incluirTipoTaxaME) {
      situcaoValores.push({ 'idTipoTaxaMercadoLivre': null, 'tipoTaxaMercadoLivre': 'Tipo de Taxa ME' });
    }
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idTipoTaxaMercadoLivre': 0, 'tipoTaxaMercadoLivre': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idTipoTaxaMercadoLivre': null, 'tipoTaxaMercadoLivre': 'Selecione a Taxa' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idTipoTaxaMercadoLivre': parseInt(item.idTipoTaxaMercadoLivre), 'tipoTaxaMercadoLivre': item.tipoTaxaMercadoLivre });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idTipoTaxaMercadoLivre': parseInt(item.idTipoTaxaMercadoLivre), 'tipoTaxaMercadoLivre': item.tipoTaxaMercadoLivre });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectPontoMedicao(incluirAplicarTodos, incluirOpcaoVazia, incluirPontoMedicao) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/pontoMedicao`);
    const situcaoValores = [];
    if (incluirPontoMedicao) {
      situcaoValores.push({ 'idUCPontoMedicao': null, 'pontoMedicao': 'Ponto Medição' });
    }
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idUCPontoMedicao': 0, 'pontoMedicao': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idUCPontoMedicao': null, 'pontoMedicao': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idUCPontoMedicao': parseInt(item.idUCPontoMedicao), 'pontoMedicao': item.pontoMedicao });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idUCPontoMedicao': parseInt(item.idUCPontoMedicao), 'pontoMedicao': item.pontoMedicao });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectUnidadeConsumo(incluirAplicarTodos, incluirOpcaoVazia, incluirUnidadeConsumo) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/unidadeConsumo`);
    const situcaoValores = [];
    if (incluirUnidadeConsumo) {
      situcaoValores.push({ 'idUC': null, 'uc': 'UC' });
    }
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idUC': 0, 'uc': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idUC': null, 'uc': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idUC': parseInt(item.idUC), 'uc': item.uc });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idUC': parseInt(item.idUC), 'uc': item.uc });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectClienteIncluir(incluirAplicarTodos, incluirOpcaoVazia) {
  var id = localStorage.getItem("@plataforma/user") == null ? 0 : JSON.parse(localStorage.getItem("@plataforma/user")).idCliente;
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/cliente/filtro/` + id);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idCliente': 0, 'nomeCliente': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idCliente': null, 'nomeCliente': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idCliente': item.idCliente, 'nomeCliente': item.nomeCliente });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idCliente': item.idCliente, 'nomeCliente': item.nomeCliente });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectCamposLeadSegmento(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/campoLeadSegmento`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idCampoLead': 0, 'nomeTela': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idCampoLead': null, 'nomeTela': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idCampoLead': item.idCampoLead, 'nomeTela': item.nomeTela });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idCampoLead': item.idCampoLead, 'nomeTela': item.nomeTela });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectSegmento(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/segmento`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idSegmento': 0, 'nomeSegmento': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idSegmento': null, 'nomeSegmento': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idSegmento': item.idSegmento, 'nomeSegmento': item.nomeSegmento });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idSegmento': item.idSegmento, 'nomeSegmento': item.nomeSegmento });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectSegmentoNaoUsado(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/segmentoNaoUsado`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'id': null, valor: '' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idSegmento': item.idSegmento, 'nomeSegmento': item.nomeSegmento });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idSegmento': item.idSegmento, 'nomeSegmento': item.nomeSegmento });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectStatusNaoUsadoOrdem(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/statusNaoUsadoOrdem`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'id': null, valor: '' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'valor': item.ordem });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'valor': item.ordem });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectStatusNaoUsado(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/statusNaoUsado`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'id': null, valor: '' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'valor': item.tipoStatus });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'valor': item.tipoStatus });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectTipoProduto2(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/TipoProduto`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idTipoProduto': 0, 'nomeTipoProduto': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idTipoProduto': null, 'nomeTipoProduto': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idTipoProduto': item.idTipoProduto, 'nomeTipoProduto': item.nomeTipoProduto });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idTipoProduto': item.idTipoProduto, 'nomeTipoProduto': item.nomeTipoProduto });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectAtividade(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/atividade`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'id': null, valor: '' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'id': item.idAtividade, 'tituloAtividade': item.tituloAtividade, 'prazoAtividade': item.prazoAtividade, 'periodo': item.periodo });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'id': item.idAtividade, 'tituloAtividade': item.tituloAtividade, 'prazoAtividade': item.prazoAtividade, 'periodo': item.periodo });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};


export function getSelectCamposLead(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/campoLead`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': 0, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.key, 'label': item.value, 'segmento': item.segmento, 'cor': item.cor, 'id': item.id });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.key, 'label': item.value, 'segmento': item.segmento, 'cor': item.cor, 'id': item.id });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectContratoAtividade(incluirAplicarTodos) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: '' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Criar atividade' });
  situcaoValores.push({ 'id': 'T', valor: 'Enviar email' });
  situcaoValores.push({ 'id': 'C', valor: 'Anexar comprovante' });
  situcaoValores.push({ 'id': 'V', valor: 'Validar Atividades' });
  return situcaoValores;
};

export function getSelectObrigatoriedade(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': ' ', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'N', valor: 'Não' });
  situcaoValores.push({ 'id': 'S', valor: 'Sim' });
  return situcaoValores;
};

export function getSelectParceiro(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/parceiro`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': 0, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.idparceiro, 'label': item.parceiro });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.idparceiro, 'label': item.parceiro });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectSituacaoConfiguracaoLead(incluirAplicarTodos, incluirEmBranco) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': ' ', valor: 'Todos' });
  }
  if (incluirEmBranco) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Ativo' });
  situcaoValores.push({ 'id': 'I', valor: 'Inativo' });
  return situcaoValores;
};

export function getSelectTemplateConfig(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/template`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'id': null, valor: '' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'id': item.idTemplate, 'tituloTemplate': item.tituloTemplate, 'siglaTemplate': item.siglaTemplate, 'tipoTemplate': item.tipoTemplate });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'id': item.idTemplate, 'tituloTemplate': item.tituloTemplate, 'siglaTemplate': item.siglaTemplate, 'tipoTemplate': item.tipoTemplate });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectCanal(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/canal`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idCanalLead': 0, 'nomeCanalLead': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idCanalLead': null, 'nomeCanalLead': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idCanalLead': parseInt(item.idCanalLead), 'nomeCanalLead': item.nomeCanalLead });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idCanalLead': parseInt(item.idCanalLead), 'nomeCanalLead': item.nomeCanalLead });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function fetchObterCardSuperior(id, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/cardSuperior/` + id);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'id': null, valor: '' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'Descricao': item.descricao, 'status': item.status });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'Descricao': item.descricao, 'status': item.status });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectAnexoObrigatorioLead(id, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/anexoObrigatorioLead/` + id);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'id': null, valor: '' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'id': item.idTipoArquivo, 'valor': item.nomeTipoArquivo });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'id': item.idTipoArquivo, 'valor': item.nomeTipoArquivo });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectCanais(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/canais`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': 0, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.idcanal, 'label': item.canal });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.idcanal, 'label': item.canal });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};


export function getSelectGroupSegmento(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/grouptSegmento`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'id': null, valor: '' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'tipoStatus': item.tipoStatus, 'requerido': item.requerido, 'nomeSegmento': item.nomeSegmento, 'key': item.key, 'segmento': item.segmento });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'tipoStatus': item.tipoStatus, 'requerido': item.requerido, 'nomeSegmento': item.nomeSegmento, 'key': item.key, 'segmento': item.segmento });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};
export function getParametroContrato(idLead, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/gerarContrato/parametrosContrato/` + idLead);
    const situcaoValores = [];
    valores.then(function (retorno) {
      if (retorno.status == 200) {
        if (retorno.data.entities != null) {
          resolve(retorno.data.entities.parametro);
        }
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectProduto(idLead, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/produto/` + idLead);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': 0, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.idProduto, 'label': item.nomeProduto });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.idProduto, 'label': item.nomeProduto });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectRevalidacaoDados(incluirAplicarTodos, incluirOpcaoVazia, id) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/revalidacaoDados/${id}`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idUsina': 0, 'nomeUsina': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idUsina': null, 'nomeUsina': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'nomeTipoAcoes': item.nomeTipoAcoes, 'nomeAcao': item.nomeAcao, 'idValidarLead': item.idValidarLead });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'nomeTipoAcoes': item.nomeTipoAcoes, 'nomeAcao': item.nomeAcao, 'idValidarLead': item.idValidarLead });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectStatusFaseOportunidade(id, incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/statusFaseOportunidade/` + id);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'tipo': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'id': null, valor: '' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'id': item.tipoStatus, 'valor': item.nomeStatus });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'id': item.tipoStatus, 'valor': item.nomeStatus });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectTipoOportunidade(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/tipoOportunidade`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'value': 0, 'label': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'value': null, 'label': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {

        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'value': item.idTipoOportunidade, 'label': item.nomeTipoOportunidade });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'value': item.idTipoOportunidade, 'label': item.nomeTipoOportunidade });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectClienteComCNPJCPF(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/clientecnpjcpf`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idCliente': 0, 'nomeCliente': 'Todos', 'cnpjCliente': null, 'cpfCliente': null });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idCliente': null, 'nomeCliente': 'Selecione um cliente', 'cnpjCliente': null, 'cpfCliente': null });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idCliente': item.idCliente, 'nomeCliente': item.nomeCliente, 'cnpjCliente': item.cnpjCliente, 'cpfCliente': item.cpfCliente });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idCliente': item.idCliente, 'nomeCliente': item.nomeCliente, 'cnpjCliente': item.cnpjCliente, 'cpfCliente': item.cpfCliente });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectConsorcioRateioVersao(idGC, incluirAplicarTodos, incluirOpcaoVazia, mesReferencia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/timeline/` + idGC + `/` + mesReferencia);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idGestaoConsorcioRateioVersao': null, 'rateioTag': '', 'dataInclusao': '' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idGestaoConsorcioRateioVersao': null, 'rateioTag': '', 'dataInclusao': '' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idGestaoConsorcioRateioVersao': item.idGestaoConsorcioRateioVersao, 'rateioTag': item.rateioTag, 'dataInclusao': item.dataInclusao, 'idArquivoImportacaoUltimo': item.idArquivoImportacaoUltimo });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idGestaoConsorcioRateioVersao': item.idGestaoConsorcioRateioVersao, 'rateioTag': item.rateioTag, 'dataInclusao': item.dataInclusao, 'idArquivoImportacaoUltimo': item.idArquivoImportacaoUltimo });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};
export function getSelectFiltradoGestaoConsorcioUC(incluirAplicarTodos, idGestaoConsorcio) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/gestaoConsorcioUC/${idGestaoConsorcio}`);
    const situcaoValores = [];
    // if (incluirAplicarTodos) {
    //     situcaoValores.push({ 'id': null, 'tipo': 'Todos' });
    // }
    situcaoValores.push({ 'id': null, 'tipo': 'Todos' });

    valores.then(function (retorno) {

      if (retorno.status === 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'id': item.idUC, 'codigoInstalacaoUC': item.codigoInstalacaoUC });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'id': item.idUC, 'codigoInstalacaoUC': item.codigoInstalacaoUC });
          }
        });

        resolve(situcaoValores);
      }
      else {

        resolve(situcaoValores);
      }


    });
  });
};
export function getSelectGCConsorcio(incluirAplicarTodos, incluirOpcaoVazia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/gc`);
    const situcaoValores = [];
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'idGestaoConsorcio': '', 'nomeGestaoConsorcio': 'Todos' });
    }
    if (incluirOpcaoVazia) {
      situcaoValores.push({ 'idGestaoConsorcio': null, 'nomeGestaoConsorcio': 'Selecione um consórcio' });
    }
    valores.then(function (retorno) {
      if (retorno.status == 200 && retorno.data.entities) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'idGestaoConsorcio': item.idGestaoConsorcio, 'nomeGestaoConsorcio': item.nomeGestaoConsorcio });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'idGestaoConsorcio': item.idGestaoConsorcio, 'nomeGestaoConsorcio': item.nomeGestaoConsorcio });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }


    });
  });
};

export function getSelectSituacaoCompensacaoGCUC(incluirAplicarTodos, incluirOpcaoVazia) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'A', valor: 'Aprovado' });
  situcaoValores.push({ 'id': 'P', valor: 'Pendente' });
  return situcaoValores;
};

export function getSelectSituacaoCompensacaoGCUCUmPorcento(incluirAplicarTodos, incluirOpcaoVazia) {
  const situcaoValores = [];
  if (incluirAplicarTodos) {
    situcaoValores.push({ 'id': '', valor: 'Todos' });
  }
  if (incluirOpcaoVazia) {
    situcaoValores.push({ 'id': null, valor: '' });
  }
  situcaoValores.push({ 'id': 'S', valor: 'Acima 1%' });
  situcaoValores.push({ 'id': 'N', valor: 'Abaixo 1%' });
  return situcaoValores;
};

export function getSelectContratoClienteGD(id, incluirAplicarTodos, incluirAlterarEficiencia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/contratoClienteGD/${id}`);
    const situcaoValores = [];
    if (incluirAlterarEficiencia) {
      situcaoValores.push({ 'id': "0", 'contrato': 'Selecione um Contrato' });
    }
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'contrato': 'Todos' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'id': item.idGestaoEnergia, 'contrato': item.contratoGestao });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'id': item.idGestaoEnergia, 'contrato': item.contratoGestao });
          }
        });

        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};

export function getSelectGDUsina(id, incluirAplicarTodos, incluirAlterarEficiencia) {
  return new Promise((resolve, reject) => {
    const valores = axios.get(_URL + `/select/GDUsina/${id}`);
    const situcaoValores = [];
    if (incluirAlterarEficiencia) {
      situcaoValores.push({ 'id': "0", 'usina': 'Selecione um Usina' });
    }
    if (incluirAplicarTodos) {
      situcaoValores.push({ 'id': ' ', 'usina': 'Todos' });
    }
    valores.then(function (retorno) {
      if (retorno.status === 200 && retorno.data.entities != null) {
        retorno.data.entities.map((item, i, arr) => {
          if (arr.length - 1 === i) {
            situcaoValores.push({ 'id': item.idUsina, 'usina': item.nomeUsina });
            resolve(situcaoValores);
          } else {
            situcaoValores.push({ 'id': item.idUsina, 'usina': item.nomeUsina });
          }
        });
        resolve(situcaoValores);
      }
      else {
        resolve(situcaoValores);
      }
    });
  });
};


