import { createSlice } from "@reduxjs/toolkit";
const initialLeadContatosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  contatoLeadForEdit: undefined,
  lastError: null,
  titulo: false
};
export const callTypes = {
  list: "list",
  action: "action"
};
export const leadContatosSlice = createSlice({
  name: "leadContatos",
  initialState: initialLeadContatosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getContatoLeadById
    contatoLeadFetched: (state, action) => {
      state.listLoading = false;
      state.contatoLeadForEdit = action.payload.contatoLeadForEdit;
      state.error = null;
      state.titulo= true;
    },
    // findLeadContatos
    leadContatosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.titulo = false;
      // state.titulo = state.titulo == null ? null : 1;
    },
    // createContatoLead
    contatoLeadCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.titulo = false;
      // state.entities.push(action.payload.contatoLead);
    }

  }
});
