import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialSegmentosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  segmentoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const segmentosSlice = createSlice({
  name: "segmentos",
  initialState: initialSegmentosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getSegmentoById
    segmentoFetched: (state, action) => {
      state.listLoading = false;
      // APLICAR A MASCARA
      if (action.payload.segmentoForEdit && action.payload.segmentoForEdit.idSegmento) {
        action.payload.segmentoForEdit.endCEPSegmento = aplicarMascaraCEP(action.payload.segmentoForEdit.endCEPSegmento);
      }
      state.segmentoForEdit = action.payload.segmentoForEdit;
      state.error = null;
    },
    // findSegmentos
    segmentosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createSegmento
    segmentoCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      // state.entities.push(action.payload.segmento);
    },
    // updateSegmento
    segmentoUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.segmento.idSegmento) {
          return action.payload.segmento;
        }
        return entity;
      });
    },
    // deleteSegmento
    segmentoDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteSegmentos
    segmentosDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      // state.entities = state.entities.filter(
      //   el => !action.payload.ids.includes(el.id)
      // );
    },
    // segmentosUpdateState
    segmentosSegmentoUpdated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      const { ids, segmento } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.segmento = segmento;
        }
        return entity;
      });
    }
  }
});
