const prod = {
  url: {
    apiEndPoint: "https://energetica.acessoenergia.com.br/api",
    urlLogout: "https://energetica.acessoenergia.com.br/logout"
  }
};
const prod_ml = {
  url: {
    apiEndPoint: "https://homologacao01.acessoenergia.com.br/api",
    urlLogout: "https://homologacao01.acessoenergia.com.br/logout"
  }
};
const demo = {
  url: {
    apiEndPoint: "https://app2.acessoenergia.com.br/api",
    urlLogout: "https://app2.acessoenergia.com.br/logout"
  }
};
const dev = {
  url: {
    apiEndPoint: "http://localhost:8181/api",
    urlLogout: "http://localhost:3000/logout"
  }
};
const potentiProd ={
  url: {
    apiEndPoint: "https://potenti.acessoenergia.com.br/api",
    urlLogout:   "https://potenti.acessoenergia.com.br/logout"
  }
};
const libraProd ={
  url: {
    apiEndPoint: "https://libra.acessoenergia.com.br/api",
    urlLogout:   "https://libra.acessoenergia.com.br/logout"
  }
}
const energeticaProd ={
  url: {
    apiEndPoint: "https://energetica.acessoenergia.com.br/api",
    urlLogout:   "https://energetica.acessoenergia.com.br/logout"
  }
}
const AcessoCRMProd ={
  url: {
    apiEndPoint: "https://crm.contaenergia.com.br/api",
    urlLogout:   "https://crm.contaenergia.com.br/logout"
  }
}


export const config = AcessoCRMProd;
