import { createSlice } from "@reduxjs/toolkit";

const initialConfiguracoesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  configuracaoForEdit: undefined,
  lastError: null
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const configuracoesSlice = createSlice({
  name: 'configuracoes',
  initialState: initialConfiguracoesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callTypes === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callTypes === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    configuracaoFetched: (state, action) => {
      state.listLoading = false;
      state.configuracaoForEdit = action.payload.configuracaoForEdit;
      state.error = null;
    },
    configuracoesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    configuracaoCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      // console.log(action);
      state.entities.push(action.payload.configuracao);
    },
    configuracaoUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.idConfiguracao === action.payload.configuracao.idConfiguracao) {
          return action.payload.configuracao;
        }
        return entity;
      });
    },
    configuracaoDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el.id === action.payload.id);
    },
    configuracoesStatusUpdated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
