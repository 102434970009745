import axios from "axios";
import {config} from "../../../../Config";
export const SPECIFICATIONS_URL = config.url.apiEndPoint+"/notificacao";


// CREATE =>  POST: add a new administrativos to the server

export function createAdministrativo(administrativo) {
  administrativo.idDistribuidora = parseInt(administrativo.idDistribuidora);
  administrativo.idCliente = parseInt(administrativo.clienteId);
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/incluir`,
    headers: {
      'Content-Type': 'application/json'
    },
    data:administrativo,
  };

  return axios(request);

}
// READ
// Server should return filtered administrativos by productId
export function getAllProductAdministrativosByProductId(productId) {
  return axios.get(`${SPECIFICATIONS_URL}?productId=${productId}`);
}

export function getAdministrativoById(administrativoId) {
  return axios.get(`${SPECIFICATIONS_URL}/${administrativoId}`);
}

// Server should return sorted/filtered administrativos and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/administrativosfind/{productId}'. Should be 'api/administrativos/find/{productId}'!!!
export function findAdministrativos(queryParams,todas) {
  const getUsuario = JSON.parse(localStorage.getItem('@plataforma/user')).idUsuario;
  queryParams.filter.idUsuario = parseInt(getUsuario);
  queryParams.filter.tipoNotificacaoMenu = "3";
  queryParams.filter.todasNotificacaoApenasMinha = todas;
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/list`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: queryParams,
  };

  return axios(request);
}

// UPDATE => PUT: update the administrativo
export function updateAdministrativo(id,administrativo) {
  administrativo.IdAdministrativo =parseInt(id);
  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/alterar`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: administrativo,
  };
  return axios(request);
}

// DELETE => delete the administrativo
export function deleteAdministrativo(administrativoId) {
  return axios.delete(`${SPECIFICATIONS_URL}/${administrativoId}`);
}

export function deleteAdministrativos(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'I' },
  };

  return axios(request);
}

export function ativaAdministrativos(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'A' },
  };

  return axios(request);
}

export function marcarComoLidaAdministrativo(tipoNotificacaoMenu) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { tipoNotificacaoMenu: tipoNotificacaoMenu ,Situacao:'L', todas: ''  },
  };

  return axios(request);
}
