import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_layout/layout";
import { Dashboard } from "./modules/ModuloPage/Dashboard";
import { DashboardSCDE } from "./modules/ModuloPage/DashboardSCDE";
import { GdsPage } from "./modules/View/pages/gestaodistribuida/GdsPage";

const GDPage = lazy(() =>
  import("./modules/View/pages/routerPage")
);
const GDPage1 = lazy(() =>
  import("./modules/View/pages/gdPage")
);
export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={Dashboard} />
        <ContentRoute path="/dashboardSCDE" component={DashboardSCDE} />
        <Route path="/page" component={GDPage} />
        <Route path="/gd" component={GDPage1} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
