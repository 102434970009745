import {createSlice} from "@reduxjs/toolkit";
const initialAnexosLeadState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  anexoLeadForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const anexosLeadSlice = createSlice({
  name: "anexosLead",
  initialState: initialAnexosLeadState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getAnexoLeadById
    anexoLeadFetched: (state, action) => {
      state.listLoading = false;
      state.anexoLeadForEdit = action.payload.anexoLeadForEdit;
      state.error = null;
    },
    // findAnexosLead
    anexosLeadFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createAnexoLead
    anexoLeadCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities.push(action.payload.anexoLead);
    }

  }
});
