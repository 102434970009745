import React from "react";
import { useField, useFormikContext } from "formik";
import NumberFormat from "react-number-format";

export function PrecoFormatacao(cellContent, row, rowIndex) {
  return (
    <>
      <NumberFormat
        thousandSeparator={true}
        prefix={"R$"}
        displayType={"text"}
        decimalScale={4}
        decimalSeparator=","
        fixedDecimalScale
        thousandSeparator="."
        className={
          "w-full h-11 pl-3 pr-6 mt-2  text-gray-600 border rounded-md border-gray-200 hover:bg-gray-50 focus:outline-none focus:border-gray-300"
        }
        value={cellContent}
      />
    </>
  );
}

export function PrecoHtml({
  ...props
}) {

  return (
    <>
      <NumberFormat
        thousandSeparator={true}
        prefix={"R$"}
        displayType={"text"}
        decimalScale={2}
        decimalSeparator=","
        fixedDecimalScale
        thousandSeparator="."
        {...props}
      />

    </>
  );
}


export function PrecoFormatacao2(cellContent, row, rowIndex) {
  return (
    <>
      <NumberFormat
        thousandSeparator={true}
        prefix={"R$"}
        displayType={"text"}
        decimalScale={2}
        decimalSeparator=","
        fixedDecimalScale
        thousandSeparator="."
        className={
          "w-full h-11 pl-3 pr-6 mt-2  text-gray-600 border rounded-md border-gray-200 hover:bg-gray-50 focus:outline-none focus:border-gray-300"
        }
        value={cellContent}
      />
    </>
  );
}

