import React, { useEffect, useMemo, useState, Fragment } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_layout/_helpers";
import { Link } from "react-router-dom";
import { ResponsiveFunnel } from "@nivo/funnel";
import GaugeChart from 'react-gauge-chart';
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import { ResponsiveBar } from '@nivo/bar'
import * as actions from "../View/_redux/dashboardInicio/dashboardActions";
import { line, area } from "d3-shape";
import { MoedaHtml } from "../../../_layout/_partials/controls";

import { Field, Formik } from "formik";
import moment from 'moment';
import { getSelectCliente, getSelectMeses } from "../../lib/selectAPI";

import { Bar } from "@nivo/bar";

const barColor = "#0095ff";
const lineColor = "rgba(200, 30, 15, 1)";
const areaColor = "#0095ff";
export function DashboardSCDE() {

    const { currentState, entitiesMes, entitiesAnual } = useSelector((state) => ({
        currentState: state.dashboard,
        entitiesMes: state.dashboard.entitiesMes,
        entitiesAnual: state.dashboard.entitiesAnual,
    }),
        shallowEqual
    );


    const Line = ({ bars, xScale, yScale }) => {
        const lineGenerator = line()
            .x((bar, indice) => { if (indice < bars.length / 2) { return xScale(bar.data.data.Consumo) + bar.width / 2; } })
            .y((bar, indice) => { if (indice < bars.length / 2) { return yScale(bar.data.data.Total); } })
        return (
            <Fragment>
                <path
                    d={lineGenerator(bars)}
                    fill="none"
                    stroke={lineColor}
                    style={{ pointerEvents: "none" }}
                />

                {/* <path
                    d={lineGenerator1(bars)}
                    fill="none"
                    stroke={lineColor}
                    style={{ pointerEvents: "none" }}
                /> */}
                {bars.map(bar => (
                    <circle
                        key={bar.key}
                        cx={xScale(bar.data.data.Consumo) + bar.width / 2}
                        cy={yScale(bar.data.data.Total)}
                        r={4}
                        fill="white"
                        stroke={lineColor}
                        style={{ pointerEvents: "none" }}
                    />
                ))}
            </Fragment>
        );
    };

    const Line1 = ({ bars, xScale, yScale }) => {
        const lineGenerator = line()
            .x(bar => xScale(bar.data.index) + bar.width / 2)
            .y(bar => yScale(bar.data.data.Consumo));

        return (
            <Fragment>
                <path
                    d={lineGenerator(bars)}
                    fill="none"
                    stroke={lineColor}
                    style={{ pointerEvents: "none" }}
                />
                {bars.map(bar => (
                    <circle
                        key={bar.key}
                        cx={xScale(bar.data.index) + bar.width / 2}
                        cy={yScale(bar.data.data.Consumo)}
                        r={4}
                        fill="white"
                        stroke={lineColor}
                        style={{ pointerEvents: "none" }}
                    />
                ))}
            </Fragment>
        );
    };
    const [clienteCombo, setClienteCombo] = useState([]);
    const [mesList, setMesList] = useState([]);
    const [mes, setMes] = useState("");
    useEffect(() => {
        getSelectCliente(false, false).then(function (retorno) {
            setClienteCombo(retorno);
        });
        setMesList(getSelectMeses(false, false));
    }, []);


    const applyFilter = (values) => {
        dispatch(actions.ObterGestaoFaturaMensal(values.Cliente));
        dispatch(actions.ObterGestaoFaturaAnual(values.Cliente));
    };
    useEffect(() => {
        if (entitiesAnual != undefined) {
            var listaGrafico = [];
            entitiesAnual.forEach(element => {
                listaGrafico.push({
                    "Consumo": element.dataRelatorio,
                    "ForaPonta": element.consumo_ponta * 2,
                    "ForaPontaColor": "hsl(337, 70%, 50%)",
                    "Ponta": element.consumo_ponta,
                    "Total": (element.consumo_ponta * 2) + element.consumo_ponta,
                    "PontaColor": "hsl(344, 70%, 50%)",
                })
            });

            setData(listaGrafico)
        } else if (entitiesAnual == null) {
            setData([])
        }
    }, [entitiesAnual, dispatch]);

    useEffect(() => {
        if (entitiesMes != undefined) {
            // setMes("em " + mesList.find(x => x.id == entitiesMes.mes).valor + ' ' + moment().format('YYYY'))
            setMes("Ao mês")
        }
    }, [entitiesMes, dispatch]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.ObterGestaoFaturaMensal(19));
        dispatch(actions.ObterGestaoFaturaAnual(19));
    }, [1, dispatch]);
    const lineColor = "rgba(200, 30, 15, 1)";

    const [data, setData] = useState([]);
    return (
        <Formik
            initialValues={{
                Cliente: '19',
            }}
            onSubmit={(values) => {
                applyFilter(values);
            }}
        >
            {({
                values,
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit} className="form form-label-right">
                    <div className="container-fluid">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title">Gestão de faturas</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-1">
                                <div className="card card-h-100">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <div className="col-lg-3">
                                                <small className="form-text text-muted">
                                                    Agente
                                                </small>
                                                <select
                                                    className="form-control" name="idDistribuidora" onChange={(e) => { setFieldValue("idDistribuidora", e.target.value); handleSubmit(); }}
                                                    onBlur={handleBlur}
                                                    value={values.idDistribuidora}
                                                >
                                                    {/* {distribuidoraCombo.map((item) => (
                                            <option value={item.idDistribuidora}>
                                                {item.nomeDistribuidora}
                                            </option>
                                        ))} */}
                                                </select>
                                            </div>
                                            <div className="col-lg-3">
                                                <small className="form-text text-muted">
                                                    Referência
                                                </small>
                                                <select
                                                    className="form-control" name="idCliente" onChange={(e) => { setFieldValue("idCliente", e.target.value); handleSubmit(); }}
                                                    onBlur={handleBlur}
                                                    value={values.idCliente}
                                                >
                                                    {/* {clienteCombo.map((item) => (
                                                        <option value={item.idCliente}>
                                                            {item.nomeCliente}
                                                        </option>
                                                    ))} */}
                                                </select>
                                            </div>
                                            <div className="col-lg-3">
                                                <small className="form-text text-muted">
                                                    Mês
                                                </small>
                                                <select className="form-control" onChange={(e) => { setFieldValue("DataDe", e.target.value); handleSubmit(); }}
                                                    onBlur={handleBlur} value={values.DataDe} >
                                                    {/* {mesAnoCombo.map((item) => (
                                            <option value={item.id}>
                                                {item.valor}
                                            </option>
                                        ))} */}
                                                </select>
                                            </div>
                                            <div className="col-xl-3">
                                                <div className="card card bg-blue">
                                                    <div className="card-body d-flex align-items-center flex-sm-row">
                                                        {/* <i className="mdi mdi-36px mdi-file-upload-outline mdi-custom-1"></i> */}
                                                        <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="49" height="49" rx="8" fill="white" />
                                                            <svg width="50" height="40" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21.2498 11.3333L12.7498 11.3333L12.7498 19.8333L7.08317 19.8333L16.9998 29.75L26.9165 19.8333L21.2498 19.8333L21.2498 11.3333ZM26.9165 8.5L7.08317 8.5L7.08317 5.66667L26.9165 5.66667L26.9165 8.5Z" fill="#4169E1" />
                                                            </svg>
                                                        </svg>

                                                        <div className="margin-left-15px">
                                                            <h4 className="header-title">Download</h4>
                                                            <span className="float-center">Relatorio</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-xl-3 col-lg-1">
                                <div className="card bg-success">
                                    <div className="card-body d-sm-flex align-items-sm-center justify-content-sm-center flex-sm-column">
                                        <h4 className="text-nowrap" style={{ fontWeight: "500 !important" }}>Medição SCDE</h4>
                                        <h4>400.000,00 </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-1">
                                <div className="card">
                                    <div className="card-body d-sm-flex align-items-sm-center justify-content-sm-center flex-sm-column">
                                        <h4 className="text-nowrap" style={{ fontWeight: "500 !important" }}>Perdas</h4>
                                        <h4>R$ 40.000,00</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-1">
                                <div className="card">
                                    <div className="card-body d-sm-flex align-items-sm-center justify-content-sm-center flex-sm-column">
                                        <h4 className="text-nowrap" style={{ fontWeight: "500 !important" }} >Medição total </h4>
                                        <h4>R$ 2.334,00</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-1">
                                <div className="card">
                                    <div className="card-body d-sm-flex align-items-sm-center justify-content-sm-center flex-sm-column">
                                        <h4 className="text-nowrap" style={{ fontWeight: "500 !important" }} >Proinfa</h4>
                                        <h4>R$ 1.000,00</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-1">
                                <div className="card">
                                    <div className="card-body d-sm-flex align-items-sm-center justify-content-sm-center flex-sm-column">
                                        <h4 className="text-nowrap" style={{ fontWeight: "500 !important" }}>Consumo ajustado</h4>
                                        <h4>R$ 2.450,00</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-9 col-lg-1">
                                <div className="card card-h-100">
                                    <div className="card-body">
                                        <h4 className="header-title">Gestão de faturas</h4>
                                        <div dir="ltr">
                                            {/* <div id="basic-column" className="apex-charts" data-colors="#727cf5,#0acf97,#fa5c7c"></div> */}
                                            <div style={{ height: "300px" }}>
                                                <ResponsiveBar
                                                    data={data}
                                                    keys={['Ponta', 'ForaPonta']}
                                                    indexBy="Consumo"
                                                    margin={{ top: 30, right: 130, bottom: 70, left: 60 }}
                                                    padding={0.85}

                                                    colors={["#1DB954", "#E2E3E7", "#000000"]}
                                                    // rangeColors={
                                                    //     ["#3393FF", "#000000", "#FD6800", "#FFFF33", "#6BFF33"]
                                                    //     // ["#3393FF", "#FFFF33", "#6BFF33", "#FF3333"]
                                                    // }
                                                    defs={[
                                                        {
                                                            id: 'dots',
                                                            type: 'patternDots',
                                                            background: 'inherit',
                                                            color: '#38bcb2',
                                                            size: 4,
                                                            padding: 1,
                                                            stagger: true
                                                        },
                                                        {
                                                            id: 'lines',
                                                            type: 'patternLines',
                                                            background: 'inherit',
                                                            color: '#eed312',
                                                            rotation: -45,
                                                            lineWidth: 6,
                                                            spacing: 10
                                                        }
                                                    ]}
                                                    fill={[
                                                        {
                                                            match: {
                                                                id: 'fries'
                                                            },
                                                            id: 'dots'
                                                        },
                                                        {
                                                            match: {
                                                                id: 'sandwich'
                                                            },
                                                            id: 'lines'
                                                        }
                                                    ]}
                                                    borderColor={{ from: 'color', modifiers: [['darker', '0']] }}
                                                    axisTop={null}
                                                    axisBottom={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: 'Consumo',
                                                        legendPosition: 'middle',
                                                        legendOffset: 32
                                                    }}
                                                    axisLeft={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: 'Custo R$',
                                                        legendPosition: 'middle',
                                                        legendOffset: -55
                                                    }}
                                                    axisRight={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: 'Energia',
                                                        legendPosition: 'middle',
                                                        legendOffset: 55
                                                    }}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={14}
                                                    labelTextColor={{ from: 'color', modifiers: [['darker', '1.8']] }}
                                                    legends={[
                                                        {
                                                            dataFrom: 'keys',
                                                            anchor: 'bottom-right',
                                                            direction: 'row',
                                                            justify: false,
                                                            translateX: 94,
                                                            translateY: 65,
                                                            itemsSpacing: 14,
                                                            itemWidth: 89,
                                                            itemHeight: 20,
                                                            itemDirection: 'left-to-right',
                                                            itemOpacity: 0.85,
                                                            symbolSize: 9,
                                                            effects: [
                                                                {
                                                                    on: 'hover',
                                                                    style: {
                                                                        itemOpacity: 1
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]}
                                                    layers={["grid", "axes", "bars", Line, "markers", "legends"]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* fim card */}
                            </div>
                            {/* fim coluna */}
                            <div className="col-xl-3 col-lg-1">
                                <div className="card card-h-100">
                                    <div className="col-xl-12">
                                        <div className="card card bg-blue">
                                            <div className="card-body d-flex align-items-center flex-sm-row">
                                                <div className="margin-left-15px">
                                                    <h4 className="header-title">Horas faltantes</h4>
                                                    <span className="float-center">10/720</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="card card bg-blue">
                                            <div className="card-body d-flex align-items-center flex-sm-row">
                                                <div className="margin-left-15px">
                                                    <h4 className="header-title">Ciclo Fechado</h4>
                                                    <span className="float-center">01/04/2021 - </span>
                                                    <span className="float-center">01/05/2021</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* fim card */}
                            </div>
                            {/* fim coluna */}
                        </div>
                        {/* fim linha */}
                    </div>
                </form>
            )}
        </Formik>
    );
}
