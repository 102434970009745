import React from "react";
import { useField, useFormikContext } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import NumberFormat from "react-number-format";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["w-full h-11 pl-3 pr-8 mt-2 text-sm font-regular text-gray-600 border rounded-md border-gray-300 hover:bg-gray-50 focus:outline-none focus:border-mainBlue placeholder:text-gray-400 disabled:bg-gray-100 disabled:text-opacity disabled:text-gray-600 disabled:text-opacity-60 disabled:border-opacity-70 disabled:bg-opacity-70"];
  if (touched && errors) {
    classes.push("border-2 border-red-500");
  }

  if (touched === true && errors === undefined) {
    classes.push("border-2 border-green-500");
  }

  return classes.join(" ");
};

export function Percentual({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      <div>
        {label && (
          <label
            htmlFor={label}
            className="text-gray-500 text-sm font-semibold"
          >
            {" "}
            {label}{" "}
          </label>
        )}
        <div>
          <NumberFormat
            suffix={"%"}
            decimalScale={2}
            decimalSeparator=","
            fixedDecimalScale
            thousandSeparator="."
            value={field.value}
            onValueChange={val => {
              form.setFieldValue(field.name, val.floatValue);
            }}
            className={
              getFieldCSSClasses(touched[field.name], errors[field.name])
            }
            {...props}
          />
          {withFeedbackLabel && form.errors[field.name] && (
            <FieldFeedbackLabel
              error={form.errors[field.name]}
              touched={form.touched[field.name]}
              label={label}
              type={type}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </div>
      </div>
    </>
  );
}
