import React from "react";
import { useField, useFormikContext } from "formik";

export function Checkbox({ ...props }) {
  const { setFieldValue, values, errors, touched } = useFormikContext();
  const [field] = useField(props);
  const removeItem = function(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };
  const addItem = function(arr, value) {
    if (Array.isArray(arr)) {
      arr.push(value);
      return arr;
    } else {
      var arrTemp = arr.split(",");
      arrTemp.push(value);
      return arrTemp;
    }
  };

  return (
    <>
      <input type="checkbox" style={{ display: "none" }} />
      <label className="checkbox checkbox-lg checkbox-single">
        <input
          type="checkbox"
          {...field}
          {...props}
          onChange={val => {
            var valores = values[props.group];
            if (valores == null) valores = [];
            else {
              valores = valores.join(",").split(",");
            }
            if (val.target.checked) {
              setFieldValue(props.group, addItem(valores, props.value));
              if (props.onChange != null) {
                setTimeout(function() {
                  props.onChange(val);
                }, 100);
              }
            } else {
              setFieldValue(props.group, removeItem(valores, props.value));
              if (props.onChange != null) {
                setTimeout(function() {
                  props.onChange(val);
                }, 100);
              }
            }
          }}
        />
        <span />
        {props.label && <label>&nbsp;&nbsp;{props.label}</label>}
      </label>
    </>
  );
}
