import { createSlice } from "@reduxjs/toolkit";

const initialCrmsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: { custo: { consumo: 0, diasMedidos: 0, energiaCompensada: null, totalGD: null, totalSGD: null, economia: null, economia: null, energiaGerada: 0, numeroUCs: 0, locacaoTerra: 0, locacaoEquipamento: 0, om: 0, faturamentoUCs: 0, demandaGeracao: 0 }, consumo: [] },
  distribuidoraXUsinas: null,
  graficoFunil: null,
  graficoOportunidade: null,
  graficoConsorcio: null,
  crmForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const crmSlice = createSlice({
  name: "crm",
  initialState: initialCrmsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // findCrms
    crmFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    distribuidoraXUsinasFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.distribuidoraXUsinas = entities;
    },
    graficoFunilFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.graficoFunil = entities;
    },
    graficoOportunidadeFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.graficoOportunidade = entities;
    },
    graficoConsorcioFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.graficoConsorcio = entities;
    },
  }
});
