import React from "react";
import { useField } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["w-full h-11 pl-3 pr-8 mt-2 text-sm font-regular text-gray-600 border rounded-md border-gray-300 hover:bg-gray-50 focus:outline-none focus:border-mainBlue placeholder:text-gray-400 disabled:bg-gray-100 disabled:text-gray-600"];
  if (touched && errors) {
    classes.push("border-2 border-red-500");
  }

  if (touched === true && errors === undefined) {
    classes.push("border-2 border-green-500");
  }

  return classes.join(" ");
};

export function Select({
  label,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  ...props
}) {
  const [field, meta] = useField(props);
  const { touched, error } = meta;

  // console.log("select touched", touched);
  // console.log("select error", error);
  return (
    <>
      <div>
        {label && (
          <label
            htmlFor={label}
            className="text-gray-500 text-sm font-semibold"
          >
            {" "}
            {label}{" "}
          </label>
        )}
        <div className="">
          <select
            className={
              getFieldCSSClasses(touched, error)
            }
            {...field}
            {...props}
          >
            {children}
          </select>
          {withFeedbackLabel && error && (
            <>
              <FieldFeedbackLabel
                error={error}
                touched={touched}
                label={label}
                type={type}
                customFeedbackLabel={customFeedbackLabel}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
