import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { InputError } from "../styles/LoginStyles";
import FooterSecondary from "../../components/FooterSecondary";
import { Mail, Lock } from "react-feather";

const initialValues = {
  email: "",
  password: ""
};

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("AUTH.VALIDATION.REQUIRED_FIELD"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("AUTH.VALIDATION.REQUIRED_FIELD")
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(({ data: { entities } }) => {
            disableLoading();

            //props.login(entities.accessToken);
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus("AUTH.VALIDATION.INVALID_LOGIN");
          });
      }, 2000);
    }
  });

  return (
    <>
      <div className="h-screen">
        <main className="flex pt-4 justify-center items-center h-screen">
          <div className="lg:w-4/12 w-auto px-16 py-11 shadow rounded-xl bg-white">
            <div className="mb-4">
              <img
                // src="/assets/images/logo-energetica-black.svg"
                src="/assets/logo/logo-acesso.svg"
                className="mb-3 w-56"
              />

              <h2 className="mt-7 text-left text-2xl font-extrabold text-gray-900">
                Esqueceu a senha?
              </h2>
              <h5 className="mt-0 text-left text-sm  text-gray-400">
                Digite o seu endereço de e-mail que iremos enviar uma senha
                provisória.
              </h5>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="mt-6 mb-7 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  {/* <MailIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  /> */}
                  <Mail className="text-gray-400 text-24" />
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-3"
                  placeholder="E-mail"
                  {...formik.getFieldProps("email")}
                />
                {/* {formik.touched.email && formik.errors.email ? (
                  <InputError>{formik.errors.email}</InputError>
                ) : null} */}
              </div>
              <div>
                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="w-full flex justify-center py-3 px-3 rounded-md shadow-sm text-sm font-medium text-white bg-mainBlue hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="text-base">Acessar</span>
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
              <div className=" flex items-center justify-between">
                <div className="text-sm mt-5">
                  <a
                    href="/auth/login"
                    className="font-extrabold text-mainBlue hover:text-blue-500"
                  >
                    Retornar para o login
                  </a>
                </div>
              </div>
            </form>
          </div>
        </main>
        <FooterSecondary relativePosition=" absolute" />
      </div>
    </>
  );
}
