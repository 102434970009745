import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import signInBackgroundImg from '../../../../assets/background-login.png';

export const Container = styled.div`
  height: 100vh;
  box-sizing: border-box;

  display: flex;
  align-items: stretch;

  a {
    color: #5C5C5C;
    text-decoration: none;
    font-weight: 500;
    fontSize: 14px;

    &:hover {
      color: ${shade(0.2, '#5C5C5C')};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #F0F0F0;
  width: 100%;
  max-width: 900px;

  button {
    width: 160px;
    height: 40px;
    border: 1px solid;
    border-radius: 8px;
  }


`;

export const Footer = styled.div`
  margin: auto;

  span {
    margin-right: 28px;
  }

  a + a {
    margin-left: 25px;
  }
`;

export const ContentLogo = styled.div`
  display: flex;
  align-items: center;

  margin-top: 70px;

  width: 95%;

  img {
    margin-left: 15%;
    width: 201px;
    height: 47px;
  }

  button {
    margin-left: auto;
  }
`;


const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  animation: ${appearFromLeft} 1s;

  h1 {
    color: #5C5C5C;
    fontSize: 24px;
    font-weight: 700;
    margin-bottom: 65px;
  }

  form {
    margin-top: 100px;
    width: 390px;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      margin-top: 35px;
      align-items: center;
    }

    button {
      background-color: #1ED760;
      border: none;
      color: #fff;
      margin-left: auto;

      &:hover {
        background: ${shade(0.2, '#1ED760')};
      }
    }
  }

  input {
    width: 100%;
    height: 45px;
    border-radius: 4px;
    border: 0px solid;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    outline: none;
    padding-left: 12px;

    ::placeholder {
      color: #C2C2C3;
    }
  }

  input + input {
    margin-top: 20px;
  }
`;

export const InputError = styled.span`
  color: red;

  + input {
    margin-top: 10px;
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackgroundImg}) no-repeat center;
  background-size: cover;
`;
