/* eslint-disable no-unused-vars */
import React from "react";
import {PaginationTotalStandalone} from "react-bootstrap-table2-paginator";

export function PaginationToolbar(props) {
  const { isLoading, paginationProps } = props;
  const {
    sizePerPageList,
    sizePerPage,
    totalSize,
    page,
    paginationSize,
    onSizePerPageChange = [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ]
  } = paginationProps;
  const style = {
    width: "75px"
  };

  const onSizeChange = event => {
    const newSize = +event.target.value;
    onSizePerPageChange(newSize);
  };

  return (
    <>
        {(totalSize > 0 &&
          <div className="flex">
            <div>
              <span className="text-sm text-gray-700">
                Exibindo
                <span className="mr-1 ml-1 font-medium">{((page-1)*sizePerPage)+1}</span>
                até
                <span className="mr-1 ml-1 font-medium">{((page)*sizePerPage)>totalSize?totalSize:((page)*sizePerPage)}</span>
                do total de
                <span className="mr-1 ml-1 font-medium">{totalSize}</span>
                registro(s) | Páginas
              <select
                disabled={totalSize === 0}
                className="h-9 ml-3 text-sm font-regular text-gray-600 border rounded-md border-gray-300 hover:bg-gray-50 focus:outline-none focus:border-mainBlue"
                onChange={onSizeChange}
                value={sizePerPage}
                style={style}
              >
                {sizePerPageList.map(option => {
                  const isSelect = sizePerPage === `${option.page}`;
                  return (
                    <option
                      key={option.text}
                      value={option.page}
                      className={`btn ${isSelect ? "active" : ""}`}
                    >
                      {option.text}
                    </option>
                  );
                })}
              </select> registros
                </span>
            </div>

          </div>
        )}
    </>
  );
}
