import * as requestFromServer from "./dashboardCrud";
import { dashboardSlice, callTypes } from "./dashboardSlice";

const { actions } = dashboardSlice;

export const ObterGestaoFaturaMensal = (queryParams,MesFatura) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .ObterGestaoFaturaMensal(queryParams,MesFatura)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.FaturaMensalFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find dashboard";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const ObterGestaoFaturaAnual = (queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .ObterGestaoFaturaAnual(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.FaturaAnualFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find dashboard";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const ObterProcessamentoFatura = (queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .ObterProcessamentoFatura(queryParams)
    .then(response => {
      const { entities } = response.data;
      dispatch(actions.FaturaProcessamentoFaturaFetched({ entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find dashboard";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};