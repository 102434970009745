import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { CalendarIcon } from "@heroicons/react/outline";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["w-full h-11 pl-3 pr-8 mt-2 text-sm font-regular text-gray-600 border rounded-md border-gray-300 hover:bg-gray-50 focus:outline-none focus:border-mainBlue placeholder:text-gray-400 disabled:bg-gray-100 disabled:text-gray-600"];
  if (touched && errors) {
    classes.push("border-2 border-red-500");
  }

  if (touched === true && errors === undefined) {
    classes.push("border-2 border-green-500");
  }

  return classes.join(" ");
};

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro"
];

const locale = {
  localize: {
    month: n => months[n]
  },
  formatLong: {
    date: () => "MMMM/YYYY"
  }
};

export function formatacaoPortugues() {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];
  const days = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

  const locale = {
    localize: {
      day: n => days[n],
      month: n => months[n]
    },
    formatLong: {
      date: () => "mm/dd/yyyy"
    }
  };

  return locale;
}

export function DateMesAno({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && (
        <label className="text-gray-500 text-sm font-semibold">
          {props.label}
        </label>
      )}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        dateFormat="MMMM/yyyy"
        disabledKeyboardNavigation
        showMonthYearPicker
        showFullMonthYearPicker
        showTwoColumnMonthYearPicker
        autoComplete="off"
        selected={(field.value && new Date(field.value)) || null}
        locale="ptBR"
        onChange={val => {
          setFieldValue(field.name, val);
          if (props.onChange != null) {
            setTimeout(function() {
              props.onChange(val);
            }, 100);
          }
        }}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}


export function DateMesAnoPortugues({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        // className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        dateFormat="MMMM/yyyy"
        disabledKeyboardNavigation
        showMonthYearPicker
        locale={locale}
        autoComplete="off"
        showFullMonthYearPicker
        showTwoColumnMonthYearPicker
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          setFieldValue(field.name, val);
          if (props.onChange != null) {
            setTimeout(function () {
              props.onChange(val);
            }, 100);
          }
        }}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
