import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialUcsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  ucForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const ucsSlice = createSlice({
  name: "ucs",
  initialState: initialUcsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {

      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getUcById
    ucFetched: (state, action) => {
      state.listLoading = false;
      // APLICAR A MASCARA
      if (action.payload.ucForEdit && action.payload.ucForEdit.idUC) {
        action.payload.ucForEdit.endCEPUC = aplicarMascaraCEP(action.payload.ucForEdit.endCEPUC);
      }
      state.ucForEdit = action.payload.ucForEdit;
      state.error = null;
    },
    // findUcs
    ucsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    consultasUCFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;

    },
    // createUc
    ucCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities.push(action.payload.uc);
    },
    // updateUc
    ucUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.uc.id) {
          return action.payload.uc;
        }
        return entity;
      });
    },
    // update unidade consumo
    unidadeConsumoUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.uc.id) {
          return action.payload.uc;
        }
        return entity;
      });
    },
    // deleteUc
    ucDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteUcs
    ucsDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => !action.payload.ids.includes(el.id)
      // );
    }
  }
});
